import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '../../../models/user.model'
import { LocaleObserver } from '../../../observers/locale.observer'
import { UserObserver } from '../../../observers/user.observer'
import { LoginService } from '../../../services/login.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnChanges {

  currentUser: User | undefined
  loginForm: FormGroup

  constructor(
    private localeObserver: LocaleObserver,
    private userObserver: UserObserver,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    // @Inject(MAT_BOTTOM_SHEET_DATA) public data: {  }
  ) {

    const config = {
      username: '',
      password: ''
    }

    this.loginForm = this.formBuilder.group(config)

    this.userObserver.userChangedObserver$.subscribe(
      (user) => {
        this.currentUser = user
      }
    )

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
  }

  dismiss() {
  }

  resetSearch() {
    this.loginForm = this.formBuilder.group({
      username: '',
      password: '',
    })
  }

  login() {
    this.currentUser = new User()
    this.currentUser.username = this.loginForm.get('username')?.value
    this.currentUser.password = this.loginForm.get('password')?.value

    this.loginService.doLogin(this.currentUser, this.localeObserver.currentLocale).subscribe(
      (user) => {
        if (user) {
          this.currentUser = user
          this.userObserver.setUser(user)
        } else {
          this.userObserver.setUser(undefined)
        }
      }
    )
  }

}

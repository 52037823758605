<div>

  <h2>{{ 'login' | translate }}</h2>

  <form [formGroup]="loginForm" *ngIf="!currentUser?.loggedIn()">
    <div>
      <mat-form-field appearance="standard">
        <mat-label>{{ 'checkout.username' | translate }}</mat-label>
        <input type="text" formControlName="username" matInput required>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="standard">
        <mat-label>{{ 'checkout.password' | translate }}</mat-label>
        <input type="password" formControlName="password" matInput required>
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary" (click)="login()">
      Login
    </button>

  </form>

</div>

import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('discounts', ['discount'])
export class Discount extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  title: string | undefined;
  @Column()
  percentage: number | undefined;
}

import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Order } from '../../../models/order.model'
import { Pagination } from '../../../models/pagination.model'
import { LocaleObserver } from '../../../observers/locale.observer'
import { OrderService } from '../../../services/order.service'

@Component({
  selector: 'app-my-orders',
  templateUrl: './my_orders.component.html',
  styleUrls: ['./my_orders.component.scss']
})
export class MyOrdersComponent implements OnInit, OnChanges, OnDestroy {

  public page: number

  public displayedColumns: string[]

  public orders: Order[] = []
  public ordersInitialized: boolean = false
  public pagination: Pagination<Order> | undefined

  constructor(
    private orderService: OrderService,
    public localeObserver: LocaleObserver
  ) {
    this.displayedColumns = [
      'id',
      'status',
      'createdAt',
      'totalprice',
      'actions'
    ]
  }

  ngOnInit() {
    this.loadMyOrders()
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
  }

  set CurrentPage(page: number) {
    this.page = page
    this.loadMyOrders()
  }

  get CurrentPage(): number {
    if (this.pagination && this.pagination.currentPage) {
      if (this.page) {
        this.pagination.currentPage = +this.page
      }
      return this.pagination.currentPage
    }
    return 1
  }

  loadMyOrders() {
    this.orderService.fetchMyOrders(this.CurrentPage).subscribe(
      (orders: Order[]) => {
        this.pagination = this.orderService.lastPagination
        this.orders = orders
        this.ordersInitialized = true
      }
    )
  }

  cancelOrder(order: Order) {
    if (order && order.builderhash) {
      this.orderService.cancelOrder(order.builderhash, this.localeObserver.currentLocale).subscribe(
        (response) => {
          if (order.builderhash === response.builderhash) {
            order.status = response.status
          }
        }
      )
    }
  }

}

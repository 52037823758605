import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { CurrencyObserver } from "../../../observers/currency.observer";
import { BookLaunchOffer } from "../../../models/book_launch_offer.model";
import { BookPriceOffer } from "../../../models/vouchers/book_price_offer.model";

@Component({
  selector:    'app-book-launch-offer-v2',
  templateUrl: './book-launch-offer-v2.component.html',
  styleUrls:   ['./book-launch-offer-v2.component.scss']
})
export class BookLaunchOfferV2Component implements OnInit {

  @Input() bookLaunchOffer: BookLaunchOffer | undefined;
  bookLanguages: { [key: string]: string } = {};

  countdownExpired = false

  constructor(
    private translateService: TranslateService,
    public currencyObserver: CurrencyObserver
  ) {
    this.initBookLanguages()
    translateService.onLangChange.subscribe(
      (t) => {
        this.initBookLanguages()
      })
  }

  ngOnInit(): void {
  }

  onCountdownDone(expired: boolean) {
    this.countdownExpired = expired
  }

  private initBookLanguages() {
    this.translateService.get(`product.book_languages`).subscribe(
      (values: {}) => {
        this.bookLanguages = values
      }
    )
  }

  bookLanguage(lang: string | undefined): string | undefined {
    if (lang) {
      const key = Object.keys(this.bookLanguages).find((key) => key === lang)
      if (key) {
        return this.bookLanguages[key]
      }
    }
    return
  }

  selectTier(activeTier: BookPriceOffer) {
    this.bookLaunchOffer?.tiers.forEach(
      (tier) => {
        if (activeTier === tier) {
          tier.selected = true
        } else {
          tier.selected = false
        }
      }
    )
  }

}

import { environment } from '../../environments/environment'
import { ExtendedSearch } from '../models/extended_search.model'
import { ProductBook } from '../models/product_book.model'
import { ProductBookAuthor } from '../models/product_book_author.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Product } from '../models/product.model'
import { Observable } from 'rxjs'
import { Pagination } from '../models/pagination.model'
import { TranslateService } from '@ngx-translate/core'
import { CurrencyPrice } from '../models/currency_price.model'
import { Language } from '../models/language.model'

import productQuery from 'src/app/graphql/queries/product.query.graphql'
import latestProductsQuery from 'src/app/graphql/queries/latest_products.query.graphql'
import productsByCategoryQuery from 'src/app/graphql/queries/products_by_category.query.graphql'
import productSearchQuery from 'src/app/graphql/queries/product_search.query.graphql'
import extendedProductSearchQuery from 'src/app/graphql/queries/extended_product_search.query.graphql'

@Injectable()
export class ProductService extends GraphqlService<Product> {

  lastAggregations: Language[] | undefined

  constructor(http: HttpClient, private translate: TranslateService) {
    super(http)
    this.baseUrl = environment.graphQL
    /* DO NOT DELETE */
    new Product()
    new ProductBook()
    new ProductBookAuthor()
    new CurrencyPrice()
    new Language()
    /* DO NOT DELETE END */
  }

  byEAN(ean: string) {
    return this.find(productQuery, { isbn: ean })
  }

  byCategory(categoryId: string, page = 1, languageStr: string | undefined): Observable<Product[]> {
    return this.findAll(productsByCategoryQuery, { categoryId, page, languageStr })
  }

  bySearchTerm(term: string, page = 1, languageStr: string | undefined): Observable<Product[]> {
    return this.findAll(productSearchQuery, { term, page, languageStr })
  }

  byExtendedSearch(extendedSearch: ExtendedSearch, page = 1): Observable<Product[]> {
    return this.findAll(extendedProductSearchQuery, {
      ...extendedSearch,
      categoryIds:   extendedSearch.categoryIds?.join(','),
      publisherIds:  extendedSearch.publisherIds?.join(','),
      languageCodes: extendedSearch.languageCodes?.join(','),
    })
  }

  pagination(): Pagination<Product> | undefined {
    return this.lastPagination
  }

  aggregations(): Language[] | undefined {
    return this.lastAggregations
  }

  latestProducts(iso = this.translate.currentLang): Observable<Product[]> {
    return this.findAll(latestProductsQuery, { iso })
  }

}

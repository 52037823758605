import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { Currency } from '../models/currency.model'
import { CurrencyService } from '../services/currency.service'

@Injectable()
export class CurrencyObserver {

  currency: Currency
  currencies: Currency[]
  currentCurrency = 'EUR'
  currencyObserver$: Subject<Currency>

  constructor(currencyService: CurrencyService) {
    const previousCurrency = localStorage.getItem('currency')
    currencyService.getAvailableCurrencies().subscribe((currencies) => {
      this.currencies = currencies
      if (previousCurrency) {
        const currency = this.currencies.find((currency) => currency.currency === previousCurrency)
        if (currency) {
          this.set(currency)
        }
      }
    })
    this.currencyObserver$ = new Subject<Currency>()
  }

  set(currency: Currency) {
    if (currency.currency && currency.currency !== this.currentCurrency) {
      this.currencies.forEach((_cur) => {
        if (currency.currency === _cur.currency) {
          _cur.isActive = true
        } else {
          _cur.isActive = false
        }
      })
      localStorage.setItem('currency', currency.currency)
      this.currency = currency
      this.currentCurrency = currency.currency
      this.currencyObserver$.next(this.currency)
    }
  }

}

import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class LayoutObserver {

  darkMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  showNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  showPromotionSheet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  showCartObserver$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  showCurrencySwitchObserver$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  showLogoObserver$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  showLanguageSwitchObserver$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  showSearchObserver$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

}

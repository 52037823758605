import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('categoryTokens')
export class CategoryToken extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  value: string | undefined;
  @Column()
  iso: string | undefined;
}

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { CurrencyPrice } from "./currency_price.model";

@Model('deliveryOption', ['deliveryOptions'])
export class DeliveryOption extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  fromKg: number | undefined;
  @Column()
  toKg: number | undefined;
  @Column()
  deliveryMethod: string | undefined;
  @Column()
  showLocalCustomsNote: boolean | undefined;
  @Column()
  dutyFeeMessages: string[] | undefined;
  @Column()
  mobilePhoneNumberRequired: boolean | undefined;
  @Column()
  currencyPrices: CurrencyPrice[] = [];

  currencyPrice(currency: string): number {
    if (this.currencyPrices) {
      const price = this.currencyPrices.find(
        (price) => price.currency === currency
      )?.netPrice
      if (price) {
        return price
      }
    }
    return 0
  }

}

import {Category} from "../models/category.model";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class CategoryObserver {

  activeCategory: Category = new Category();
  activeCategorySubject$: Subject<Category>;

  constructor() {
    this.activeCategorySubject$ ||= new Subject()
  }

  resetActiveCategory() {
    this.setActiveCategory(new Category())
  }

  setActiveCategory(cat: Category) {
    this.activeCategory = cat
    this.activeCategorySubject$.next(this.activeCategory)
  }

}

<mat-card *ngIf="tier" class="tier">

  <mat-card-title>
    {{ 'book_price_offer.tier_headline' | translate:{quantity: tier.quantity} }}
  </mat-card-title>

  <mat-card-content>

    <ul>
      <li *ngFor="let tierInfo of tierInfos">
        <div *ngIf="tier && tierInfo && (renderNotIncludedTierInfo || tierInfo.renderIncluded)"
             [class.not-included]="tierInfo.renderNotIncluded" class="tier-info">
          <mat-icon *ngIf="tierInfo.renderIncluded" class="included">task_alt</mat-icon>
          <mat-icon *ngIf="tierInfo.renderNotIncluded" class="not-included">block</mat-icon>
          <span>{{ tierInfo.i18n | translate:tierInfo }}</span>
        </div>
      </li>
    </ul>

    <button mat-raised-button color="primary" (click)="addToCart()" *ngIf="renderCartItem" [disabled]="disabled">
      <mat-icon>add_shopping_cart</mat-icon>&nbsp;&nbsp;{{ 'cart.add' | translate }}
    </button>

  </mat-card-content>
</mat-card>

import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { Country } from '../models/country.model'
import { DeliveryOption } from '../models/delivery_option.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'

import deliveryOptionsQuery from 'src/app/graphql/queries/delivery_options.query.graphql'

@Injectable()
export class DeliveryOptionService extends GraphqlService<DeliveryOption> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  availableDeliveryOptions(country: Country): Observable<DeliveryOption[]> {
    return this.findAll(deliveryOptionsQuery, { iso: country.iso })
  }

}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Order } from "../../../models/order.model";
import { OrderService } from "../../../services/order.service";

@Component({
  selector: 'app-order',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public status: string | null
  public order: Order

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
  ) {

    route.params.subscribe(
      (param) => {
        const builderhash = this.route.snapshot.paramMap.get('builderhash')

        this.status = this.route.snapshot.paramMap.get('status')

        if (builderhash) {
          this.orderService.fetchOrder(builderhash).subscribe(
            (order) => {
              this.order = order
            }
          )
        }

      }
    )
  }

  ngOnInit() {
  }

  routeToRoot() {
    this.router.navigateByUrl(`/`)
  }

  routeToPaymentPage() {
    this.router.navigateByUrl(`/shop/order/${ this.order.builderhash }/${ this.order.paymentProvider }/${ this.order.paymentModule }`)
  }
}

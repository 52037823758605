import { Component, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { CartItem } from "../../../models/cart_item.model";
import { Discount } from "../../../models/discount.model";
import { TranslationBundle } from "../../../models/vouchers/translation_bundle.model";
import { DOCUMENT } from '@angular/common';
import { CurrencyObserver } from "../../../observers/currency.observer";
import { CartService } from "../../../services/cart.service";
import { CartObserver } from "../../../observers/cart.observer";

@Component({
  selector: 'app-translation-bundle',
  templateUrl: './translation-bundle.component.html',
  styleUrls: ['./translation-bundle.component.scss']
})
export class TranslationBundleComponent implements OnInit, OnChanges {

  @Input('voucher') voucher: TranslationBundle | undefined

  cartItems: CartItem[] = []
  countdownExpired: boolean = false
  totalAmount: number
  totalAmountDiscounted: number

  constructor(
    public cartService: CartService,
    public cartObserver: CartObserver,
    public currencyObserver: CurrencyObserver,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.currencyObserver.currencyObserver$.subscribe(
      (currency) => {
        this.calculateOffer()
      }
    )
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.cartItems.length === 0) {
      this.initCartItems()
    }
  }

  onCountdownExpired(expired: boolean) {
    this.countdownExpired = expired
  }

  addToCart() {
    if (this.countdownExpired) {
      return
    }
    if (this.voucher) {
      console.log(this.cartItems)
      this.cartService.addTranslationBundleToCart(this.voucher, this.cartItems).subscribe(
        (cart) => {
          this.cartObserver.updateCart(cart)
        }
      )
    }
  }

  calculateOffer(updatedItem?: CartItem) {
    this.totalAmount = 0
    this.totalAmountDiscounted = 0
    this.cartItems.forEach(
      (item) => {
        if (item.id === updatedItem?.id) {
          item.amount = updatedItem?.amount
        }
        const discount = item.discount?.percentage || 0
        const quantity = item?.amount || 0
        const currencyPrice = item?.product?.currencyPrice(this.currencyObserver.currentCurrency)
        const priceNetto = typeof currencyPrice === 'string' ? 0 : currencyPrice || 0

        this.totalAmount += quantity * priceNetto
        this.totalAmountDiscounted += quantity * (priceNetto - (priceNetto / 100 * discount))
      }
    )
  }

  private initCartItems() {
    let index = 0
    this.cartItems = []
    this.voucher?.translations?.map(
      (product) => {
        const cartItem = new CartItem()
        cartItem.id = index += 1
        cartItem.product = product
        cartItem.amount = 1
        cartItem.discount = new Discount()
        cartItem.discount.percentage = 30
        cartItem.currencyPrices = product.currencyPrices
        this.cartItems.push(cartItem)
      }
    )
    this.calculateOffer()
  }

}

<h2 class="container display3 display4-lg">
  <span>{{ 'user_area.my_books' | translate }}</span>
</h2>

<mat-accordion>

  <mat-expansion-panel hideToggle *ngFor="let name of availablePublishers">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{ name }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-products [products]="availableBooks[name]" [hideFilter]="true" [hideActions]="true"
                  (onProductSelected)="openDialog($event)"></app-products>

  </mat-expansion-panel>

  <div *ngIf="missingISBNs.length > 0 || blacklistedISBNs.length > 0" class="not-in-shop">
    <strong>{{ 'user_area.product.not_in_shop' | translate }}</strong>

    <ul>
      <li *ngFor="let isbn of missingISBNs">{{ isbn }}</li>
      <li *ngFor="let isbn of blacklistedISBNs">{{ isbn }}</li>
    </ul>
  </div>

</mat-accordion>

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { AccountVdmoServer } from './account_vdmo_server.model'
import { Product } from './product.model'

@Model('accountBook', ['accountBooks', 'myBooks'])
export class AccountBook extends BaseModel {
  @Column()
  isbn: string | undefined;
  @Column()
  product: Product | undefined;
  @Column()
  accountVdmoServer: AccountVdmoServer | undefined;
}

<h2 class="container display3 display4-lg">
  <span>{{ 'user_area.my_orders' | translate }}</span>
</h2>

<strong *ngIf="ordersInitialized && orders.length === 0">{{ 'user_area.order.no_orders' | translate }}</strong>

<div class="my-orders">

  <table mat-table [dataSource]="orders" class="mat-elevation-z3" *ngIf="orders.length">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{ 'user_area.order.headlines.id' | translate }}</th>
      <td mat-cell *matCellDef="let order">B{{order.id}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'user_area.order.headlines.state' | translate }}</th>
      <td mat-cell *matCellDef="let order">
        <mat-icon *ngIf="order.status === 'canceled'">cancel</mat-icon>
        <mat-icon *ngIf="order.status === 'paid'">paid</mat-icon>
        <mat-icon *ngIf="order.status === 'ordered'">shopping_bag</mat-icon>
        <mat-icon *ngIf="order.status === 'payment_terms'">gavel</mat-icon>
        <mat-icon *ngIf="order.status === 'unknown'">question_mark</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>{{ 'user_area.order.headlines.created_at' | translate }}</th>
      <td mat-cell *matCellDef="let order">{{order.createdAt | date }}</td>
    </ng-container>

    <ng-container matColumnDef="totalprice">
      <th mat-header-cell *matHeaderCellDef>{{ 'user_area.order.headlines.total' | translate }}</th>
      <td mat-cell *matCellDef="let order">{{order.totalprice | currency:order.currency?.currency}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'user_area.order.headlines.actions' | translate }}</th>
      <td mat-cell *matCellDef="let order">

        <button mat-icon-button [title]="'user_area.order.cancel' | translate"
                *ngIf="order.status !== 'paid' && order.status !== 'canceled'"
                (click)="cancelOrder(order)">
          <mat-icon>cancel</mat-icon>
        </button>

        <button mat-icon-button [title]="'user_area.order.pay_now' | translate"
                *ngIf="order.status === 'ordered' || order.status === 'payment_terms'"
                [routerLink]="['/shop/order/', order.builderhash, order.paymentProvider, order.paymentModule]">
          <mat-icon>point_of_sale</mat-icon>
        </button>

        <a mat-icon-button *ngIf="order.status === 'paid'" [href]="order.invoiceUrl(localeObserver.currentLocale)" target="_blank">
          <mat-icon>download</mat-icon>
        </a>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <div class="col-12 centered" *ngIf="pagination && (pagination.totalEntries||0)>(pagination.perPage||16)">
    <pagination [boundaryLinks]="true" [totalItems]="pagination.totalEntries||0"
                [itemsPerPage]="pagination.perPage||0"
                [(ngModel)]="CurrentPage" [maxSize]=5
                previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">

    </pagination>
  </div>

</div>

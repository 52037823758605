import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Category} from "../../../models/category.model";
import {CategoryService} from "../../../services/category.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoryToken} from "../../../models/category_token.model";
import {TranslateService} from "@ngx-translate/core";
import {CategoryObserver} from "../../../observers/category.observer";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnChanges {

  category: Category | undefined;

  @Input() categories: Category[] = [];
  @Input() renderChildren: boolean = false;

  @Output() onCategoryChange: EventEmitter<Category> = new EventEmitter<Category>()

  constructor(
    private categoryObserver: CategoryObserver,
    private categoryService: CategoryService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router) {
    /* URL CHANGE */
    this.route.params.subscribe(_ => {
        const categoryId = Number(this.route.snapshot.paramMap.get('categoryId'));
        this.loadCategories(categoryId);
      }
    );

    /* EXTERNALLY SET CATEGORY */
    categoryObserver.activeCategorySubject$.subscribe(
      (category) => {
        if (category.id) {
          this.loadCategories(category.id)
        }
      }
    )
    /* SWITCHED LANGUAGE */
    translate.onLangChange.subscribe(
      (t) => {
        if (this.category) {
          this.loadCategories(this.category.id)
        } else {
          this.loadCategories(undefined)
        }
      }
    )
  }

  ngOnInit(): void {
    new CategoryToken() // is required to successfully deserialize
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  selectCategory(cat: Category) {
    this.category = cat
    this.onCategoryChange.emit(cat)
    this.router.navigateByUrl(`/shop/category/${cat.id}`)
  }

  goBackToRoot() {
    this.router.navigateByUrl('/shop')
    this.onCategoryChange.emit()
  }

  private loadCategories(categoryId: number | undefined) {
    this.categoryService.fetchCategories(categoryId, this.translate.currentLang).subscribe(
      (result) => {
        this.categories = result

        if (result.length === 1) {
          this.category = result[0]
          this.renderChildren = true
        }
      }
    )
  }

}

import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { Cart } from "../../../models/cart.model";
import { CartObserver } from "../../../observers/cart.observer";
import { OAuthService } from "../../../services/oauth.service";

@Component({
  selector: 'oauth-login-form',
  templateUrl: 'oauth_login_form.component.html',
  styleUrls: ['oauth_login_form.component.scss']
})
export class OAuthLoginFormComponent {

  imprint: any
  oAuthLoginForm: FormGroup
  oAuthPermissionForm: FormGroup

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<OAuthLoginFormComponent>,
    private formBuilder: FormBuilder,
    private oAuthService: OAuthService,
    private cartObserver: CartObserver,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { imprints: any }
  ) {
    this.setup()
  }

  onSubmit(event: Event): void {
    event.preventDefault()
    this.submit()
  }

  onCancel() {
    this._bottomSheetRef.dismiss();
  }

  skipOrCancel() {
    if (this.data?.imprints?.length > 0) {
      this.setup()
    } else {
      this.onCancel()
    }
  }

  submit() {

    if (this.imprint.code) {
      this.oAuthService.persistCode(this.imprint).subscribe(
        (response) => {
          this.handleVDMOResponse(response.body)
        }, (error) => console.error(error)
      )
    } else if (this.imprint && this.imprint?.login?.required) {
      this.oAuthService.login(this.imprint, this.oAuthLoginForm.get('login')?.value, this.oAuthLoginForm.get('password')?.value).subscribe(
        (response: any) => {
          this.handleVDMOResponse(response.body)
        }, (error) => console.error(error)
      )
    } else if (this.imprint && this.imprint?.login?.required === false) {
      this.oAuthService.authorizeWithData(this.imprint.login.post_to, this.imprint.login.post_data).subscribe(
        (response) => this.handleVDMOResponse(response.body), (error) => console.error(error)
      )
    } else {
      this.skipOrCancel()
    }
  }

  handleVDMOResponse(data: any) {

    if (data.code) {
      this.imprint.code = data.code
      this.submit()
    } else if (data?.author) {
      // TODO: add next step, for now just close
      this.cartObserver.fetchCart()
      this.skipOrCancel()
    } else if (data.login?.required) {
      this.imprint.logged_in = false
      this.imprint.login = data?.login
    } else if (data.login?.required === false) {
      this.imprint.logged_in = true
      this.imprint.login = data?.login
    } else if (data.logged_in) {
      this.imprint.logged_in = true
      this.oAuthService.authorize(this.imprint.authorize_url).subscribe(
        (response) => {
          this.handleVDMOResponse(response.body)
        }, (error) => console.error(error)
      )
    } else if (!data.logged_in) {
    } else {
      this.imprint.login.logged_in = false
    }
  }

  setup() {
    this.imprint = this.data.imprints.shift()

    if (this.imprint) {

      if (this.imprint.code) {
        this.handleVDMOResponse(this.imprint)
        return
      }

      this.oAuthLoginForm = this.formBuilder.group({
        login: null,
        password: null
      });

      this.oAuthPermissionForm = this.formBuilder.group({
        permission: null
      });
    }
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('paymentPayload')
export class PaymentPayload extends BaseModel {
  @Column()
  payload: string | undefined;

  unpack(): any {
    if (this.payload) {
      return JSON.parse(window.atob(this.payload))
    } else {
      return undefined
    }
  }
}

<div class="row">

  <div class="col-12" *ngIf="!hideFilter">
    <app-products-filter #filter [aggregations]="aggregations"
                         *ngIf="aggregations && aggregations.length>0"></app-products-filter>
  </div>

  <div fxFlex fxLayout="row" class="products-wrapper col-12">
    <mat-card *ngIf="title" class="title-card mat-elevation-z8">
      <mat-card-title class="text-centered">{{ title }}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
      <mat-card-content>{{ products.length }}</mat-card-content>
    </mat-card>
    <mat-card *ngFor="let product of products" class="product-card" (click)="selectProduct(product)">
      <mat-card-title class="text-truncate">{{ product.title }}</mat-card-title>
      <mat-card-subtitle>{{ product.ean }}</mat-card-subtitle>
      <mat-card-content>
        <app-book-cover [imgSrc]="product.imageURL('cover')"></app-book-cover>
      </mat-card-content>
      <mat-card-actions *ngIf="!hideActions">
        <button mat-button>{{ 'buy_now' | translate }}</button>
        <button mat-button
                class="product-price">{{ product.currencyPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="col-12" *ngIf="pagination && (pagination.totalEntries||0)>(pagination.perPage||16)">
    <pagination [boundaryLinks]="true" [totalItems]="pagination.totalEntries||0"
                [itemsPerPage]="pagination.perPage||0"
                [(ngModel)]="CurrentPage" [maxSize]=5
                previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">

    </pagination>
  </div>
</div>

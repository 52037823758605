export class ExtendedSearch {
  isbn: string

  title: string
  subtitle: string
  blurb: string

  author: string
  categoryIds: number[]

  publisherIds: number[]

  languageCodes: string[]

}

<div class="cart-items" *ngIf="item?.voucher">
  <mat-card>
    <mat-card-title class="text-truncate">
      <span *ngIf="item?.voucher?.promotion">{{ 'voucher.promotion' | translate }}</span>
      <span *ngIf="!item?.voucher?.promotion">{{ 'voucher.voucher' | translate }}</span>
    </mat-card-title>
    <mat-card-subtitle>{{ item?.voucher?.code }}</mat-card-subtitle>
    <mat-card-content>
      <app-price [totalAmount]="item?.voucher?.availableAmount(currencyObserver.currentCurrency)"
                 [totalAmountDiscounted]="item?.voucher?.availableAmount(currencyObserver.currentCurrency)"
                 [position]="'top-right'"></app-price>
      <ul>
        <li *ngIf="!item?.voucher?.promotion && item?.voucher?.quantity">
          <strong>{{ 'bookprice_offer.link_text' | translate:{
            cnt:   item?.voucher?.quantity || 0,
            price: item?.voucher?.reducedBookPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency
          } }}</strong></li>
        <li *ngIf="item?.voucher?.promotion && !item?.voucher?.quantity"><strong>{{ 'promotion.link_text' | translate }}
          :</strong> {{ item?.voucher?.discount?.percentage }} %</li>
        <li><strong>{{ 'voucher.valid_until' | translate }}:</strong> {{ item?.voucher?.validUntil | date }}</li>
      </ul>
    </mat-card-content>
    <mat-card-actions class="text-pull-right">
      <button mat-button (click)="update()" *ngIf="amount.dirty && (+amount.value !== item?.amount)">
        <mat-icon class="save-icon">save</mat-icon>
      </button>
      <button mat-button (click)="delete()">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
<div class="cart-items" *ngIf="item?.product">
  <mat-card>
    <mat-card-title class="text-truncate">{{ item?.product?.productBook?.title }}</mat-card-title>
    <mat-card-subtitle>{{ item?.product?.productBook?.isbn }}</mat-card-subtitle>
    <mat-card-content>
      <app-price [totalAmount]="item?.totalDefaultPrice(currencyObserver.currentCurrency)"
                 [totalAmountDiscounted]="item?.totalDiscountedCurrencyPrice(currencyObserver.currentCurrency)"
                 [position]="'top-right'"></app-price>
      <app-book-cover [imgSrc]="item?.product?.imageURL('cover')"></app-book-cover>
    </mat-card-content>
    <mat-card-actions class="text-pull-right">

      <div class="amount-setter">
        <button mat-button (click)="this.update(-1)">
          <mat-icon>remove</mat-icon>
        </button>
        <input #amountElement matInput [placeholder]="'0-1000'" type="number" [formControl]="amount"
               (click)="selectText()" (focus)="selectText()"
               (keyup)="updateWithEnter($event)" min="0"/>
        <button mat-button (click)="this.update(1)">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <button mat-button (click)="update()" *ngIf="amount.dirty && (+amount.value !== item?.amount)">
        <mat-icon class="save-icon">save</mat-icon>
      </button>
      <button mat-button (click)="delete()">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<mat-card (click)="chooseTier()" [class.selected]="tier?.selected">
  <mat-card-title class="text-truncate">
    <span class="quantity">{{ tier?.quantity }}</span>
    <span class="copies">{{ 'book_price_offer.copies' | translate }}</span>
  </mat-card-title>
  <mat-card-content>
    <span class="your-price">
      {{ tier?.currencyDiscountedAuthorPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}
    </span>
    <span class="savings-text">
      {{ 'book_price_offer.save' | translate }}
    </span>
    <span class="savings">
      {{ tier?.currencyAuthorSaving(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}
    </span>

    <mat-icon>shopping_cart</mat-icon>

    <span class="new-price">
      {{ tier?.currencyBookPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}
    </span>
    <span class="new-price-text">
      {{ 'book_price_offer.newprice' | translate }}
    </span>

  </mat-card-content>

  <mat-card-actions>

    <button mat-button color="primary" (click)="addToCart()" *ngIf="tier?.selected" [disabled]="disabled">
      <mat-icon>add_shopping_cart</mat-icon>&nbsp;&nbsp;{{ 'cart.add' | translate }}
    </button>

  </mat-card-actions>
</mat-card>

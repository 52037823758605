import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Order } from "../../models/order.model";
import { CurrencyObserver } from "../../observers/currency.observer";
import { PaymentPayloadService } from "../../services/payment_payload.service";

@Component({
  selector: 'app-wire-transfer',
  templateUrl: './wire_transfer.component.html',
  styleUrls: ['./wire_transfer.component.scss']
})
export class WireTransferComponent implements OnInit, OnChanges {
  @Input('order') order: Order

  payload: any

  constructor(
    private paymentPayloadService: PaymentPayloadService,
    public currencyObserver: CurrencyObserver
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.order && this.order.builderhash) {
      this.paymentPayloadService.fetchPayload('WireTransfer', this.order.builderhash).subscribe(
        async (payload) => {
          this.payload = payload.unpack()
        }
      )
    }
  }
}

import { Component, Input, OnInit } from '@angular/core'
import { CurrencyObserver } from '../../../observers/currency.observer'
import { BookLaunchOffer } from '../../../models/book_launch_offer.model'
import { BookPriceOffer } from '../../../models/vouchers/book_price_offer.model'

@Component({
  selector:    'app-book-launch-offer-v3',
  templateUrl: './book-launch-offer-v3.component.html',
  styleUrls:   ['./book-launch-offer-v3.component.scss'],
})
export class BookLaunchOfferV3Component implements OnInit {

  @Input() bookLaunchOffer: BookLaunchOffer | undefined

  hideTier = true
  activeTier: BookPriceOffer | undefined

  countdownExpired = false

  constructor(public currencyObserver: CurrencyObserver) {
  }

  ngOnInit(): void {
  }

  onCountdownDone(expired: boolean) {
    this.countdownExpired = expired
  }

  selectTier(activeTier: BookPriceOffer) {
    const delay = this.hideTier ? 0 : 500

    this.hideTier = true
    this.bookLaunchOffer?.tiers.forEach((tier) => tier.selected = activeTier === tier)

    setTimeout(() => {
      this.activeTier = activeTier
      this.hideTier = false
    }, delay)

  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { Product } from './product.model';
import { BookPriceOffer } from './vouchers/book_price_offer.model';

@Model('bookLaunchOffer')
export class BookLaunchOffer extends BaseModel {
  @Column()
  countdown: number | undefined;
  @Column()
  version: string | undefined;
  @Column()
  product: Product | undefined;
  @Column()
  tiers: BookPriceOffer[] = [];

  get defaultTier(): BookPriceOffer | undefined {
    return this.tiers.find((tier) => tier.quantity === 10) || this.tiers[1]
  }

  get tiersWithoutDefault(): BookPriceOffer[] {
    return this.tiers.filter((tier) => tier.quantity && (tier.quantity !== this.defaultTier?.quantity && tier.quantity <= 200))
  }

}

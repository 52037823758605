<nav class="navbar bg-light sticky-top" *ngIf="aggregations">
  <div class="container-fluid">
    <div class="collapse navbar-collapse justify-content-end show">
      <ul class="navbar-nav">
        <li class="nav-item dropdown" dropdown #dropdown="bs-dropdown">
          <button #dropdownBtn dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                  aria-controls="dropdown-basic">
            <strong class="d-none d-sm-inline-block">
              {{ 'select_book_languages_desc' | translate }}
            </strong>
            <strong class="d-none d-sm-visible">
              {{ 'select_book_languages' | translate }}
            </strong>
            <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem" *ngFor="let filter of Aggregations">
              <a class="dropdown-item"
                 [class.inactive]="!bookLanguageObserver.isInterestedBookLanguageActive(filter.code3)"
                 (click)="bookLanguageObserver.toggleInterestedBookLanguage(filter.code3, $event)">
                <img [src]="getFlag(filter.code2)" [alt]="filter.code2"
                     *ngIf="filter.code2 != 'unknown'"/>
                <mat-icon *ngIf="filter.code2 == 'unknown'">translate</mat-icon>
                <span>{{ filter.getTranslationKey() | translate }} ({{ filter.counter }})</span>
              </a>
            </li>
            <li class="buttons row">
              <button type="button" class="btn btn-default col-4"
                      (click)="bookLanguageObserver.selectAll(Aggregations)">
                <strong>{{ 'select_all' | translate }}</strong>
              </button>
              <button type="button" class="btn btn-default col-push-2 col-4" (click)="bookLanguageObserver.refresh()">
                <strong>{{ 'search.done' | translate }}</strong>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

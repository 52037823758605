import { CurrencyPipe } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { add } from 'ngx-bootstrap/chronos'
import { BookLaunchOffer } from '../../../../../models/book_launch_offer.model'
import { CartObserver } from '../../../../../observers/cart.observer';
import { CurrencyObserver } from '../../../../../observers/currency.observer';
import { BookPriceOffer } from '../../../../../models/vouchers/book_price_offer.model';
import { TierInfo } from '../../../../../presenters/tier_info.presenter'
import { CartService } from '../../../../../services/cart.service';

@Component({
  selector:    'app-book-launch-offer-v3-item-details',
  templateUrl: './book-launch-offer-item-details.component.html',
  styleUrls:   ['./book-launch-offer-item-details.component.scss']
})
export class BookLaunchOfferItemDetailsComponent implements OnInit, OnChanges {

  @Input() bookLaunchOffer: BookLaunchOffer | undefined
  @Input() tier: BookPriceOffer | undefined
  @Input() disabled: boolean = false
  @Input() renderCartItem: boolean = true
  @Input() renderNotIncludedTierInfo: boolean = true
  @Output() selectTier: EventEmitter<BookPriceOffer> = new EventEmitter<BookPriceOffer>()

  tierInfos: TierInfo[] = []

  constructor(
    private currencyPipe: CurrencyPipe,
    private cartObserver: CartObserver,
    public currencyObserver: CurrencyObserver,
    private cartService: CartService
  ) {
  }

  ngOnInit(): void {
    this.currencyObserver.currencyObserver$.subscribe(
      (currency) => {
        this.initTierInfo()
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tier'] && changes['tier'].currentValue) {
      this.initTierInfo()
    }
  }

  initTierInfo() {
    this.tierInfos = []
    this.tierInfos.push(new TierInfo(this.currencyPipe, 'book_price_offer.tier_info.discount_info', this.currencyObserver.currentCurrency, this.tier, this.bookLaunchOffer))
    this.tierInfos.push(new TierInfo(this.currencyPipe, 'book_price_offer.tier_info.price_reduction_info', this.currencyObserver.currentCurrency, this.tier, this.bookLaunchOffer))
    this.tierInfos.push(new TierInfo(this.currencyPipe, 'book_price_offer.tier_info.free_flip_book', this.currencyObserver.currentCurrency, this.tier, this.bookLaunchOffer))
    this.tierInfos.push(new TierInfo(this.currencyPipe, 'book_price_offer.tier_info.free_additional_copies', this.currencyObserver.currentCurrency, this.tier, this.bookLaunchOffer))
    this.tierInfos.push(new TierInfo(this.currencyPipe, 'book_price_offer.tier_info.free_social_media_post', this.currencyObserver.currentCurrency, this.tier, this.bookLaunchOffer))
    this.tierInfos.push(new TierInfo(this.currencyPipe, 'book_price_offer.tier_info.free_biography_page', this.currencyObserver.currentCurrency, this.tier, this.bookLaunchOffer))
    this.tierInfos.push(new TierInfo(this.currencyPipe, 'book_price_offer.tier_info.free_promotional_video', this.currencyObserver.currentCurrency, this.tier, this.bookLaunchOffer))
  }

  addToCart() {
    if (this.disabled) {
      return
    }

    if (this.tier) {
      this.cartService.addBundleOfferToCart(this.tier).subscribe(
        (cart) => this.cartObserver.updateCart(cart)
      )
    }
  }

  protected readonly add = add
}

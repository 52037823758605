import { Injectable } from '@angular/core'
import { Voucher } from '../models/voucher.model'
import { BehaviorSubject } from 'rxjs'
import { VoucherService } from '../services/voucher.service'
import { SessionStorage } from '../storage/session.storage'

@Injectable()
export class PromotionObserver {

  vouchers: Voucher[] = []
  promotionVoucherObserver$: BehaviorSubject<Voucher[] | []> = new BehaviorSubject<Voucher[] | []>([])

  constructor(private sessionStorage: SessionStorage, private voucherService: VoucherService) {
    voucherService.promotionalOffers().subscribe((res) => this.setPromotionVouchers(res))
  }

  setPromotionVouchers(vouchers: Voucher[]) {
    const ignoredPromotions = this.ignoredPromotionCodes()
    this.vouchers = vouchers.filter((voucher) => voucher.promotion && !ignoredPromotions.find((code) => code === voucher.code))
    this.promotionVoucherObserver$.next(this.vouchers)
  }

  ignoredPromotionCodes(): string[] {
    return this.sessionStorage.getIgnoredPromotionCodes()
  }

  ignorePromotion(code: string) {
    this.sessionStorage.ignorePromotion(code)
  }

}

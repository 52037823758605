<div class="extended-search-filter">

  <mat-stepper orientation="vertical" [linear]="false">

    <mat-step [stepControl]="extendedSearchForm">
      <ng-template matStepLabel>{{ 'extended_search.self' | translate }}</ng-template>
      <form [formGroup]="extendedSearchForm">
        <div>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'extended_search.isbn' | translate }}</mat-label>
            <input matInput type="text" formControlName="isbn" [max]="13" pattern="[0-9]+-[0-9]+-[0-9]+-[0-9]+-[0-9]+"/>
            <button *ngIf="extendedSearchForm.get('isbn')?.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearField('isbn')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <mat-label>{{ 'extended_search.title' | translate }}</mat-label>
            <input matInput type="text" formControlName="title"/>
            <button *ngIf="extendedSearchForm.get('title')?.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearField('title')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <mat-label>{{ 'extended_search.subtitle' | translate }}</mat-label>
            <input matInput type="text" formControlName="subtitle"/>
            <button *ngIf="extendedSearchForm.get('subtitle')?.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearField('subtitle')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <mat-label>{{ 'extended_search.blurb' | translate }}</mat-label>
            <input matInput type="text" formControlName="blurb"/>
            <button *ngIf="extendedSearchForm.get('blurb')?.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearField('blurb')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <mat-label>{{ 'extended_search.author' | translate }}</mat-label>
            <input matInput type="text" formControlName="author"/>
            <button *ngIf="extendedSearchForm.get('author')?.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearField('author')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>


      </form>
    </mat-step>
    <mat-step [stepControl]="extendedSearchForm">
      <ng-template matStepLabel>{{ 'extended_search.self' | translate }}</ng-template>
      <form [formGroup]="extendedSearchForm">
        <div>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'extended_search.categories' | translate }}</mat-label>
            <mat-select formControlName="categoryIds" multiple>
              <mat-select-trigger>
                <span
                  *ngIf="extendedSearchForm.get('categoryIds')?.value?.length === 0">{{ 'extended_search.select.categories.zero' | translate }}</span>
                <span
                  *ngIf="extendedSearchForm.get('categoryIds')?.value?.length === 1">{{ 'extended_search.select.categories.one' | translate }}</span>
                <span
                  *ngIf="extendedSearchForm.get('categoryIds')?.value?.length > 1">{{ 'extended_search.select.categories.other' | translate:{count: extendedSearchForm.get('categoryIds')?.value?.length} }}</span>
              </mat-select-trigger>
              <mat-option *ngFor="let category of categories"
                          [value]="category.id">{{ category.getName() }}</mat-option>
            </mat-select>
            <button *ngIf="extendedSearchForm.get('categoryIds')?.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearField('categoryIds')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <mat-label>{{ 'extended_search.publisher' | translate }}</mat-label>
            <mat-select formControlName="publisherIds" multiple>
              <mat-select-trigger>
                <span
                  *ngIf="extendedSearchForm.get('publisherIds')?.value?.length === 0">{{ 'extended_search.select.publishers.zero' | translate }}</span>
                <span
                  *ngIf="extendedSearchForm.get('publisherIds')?.value?.length === 1">{{ 'extended_search.select.publishers.one' | translate }}</span>
                <span
                  *ngIf="extendedSearchForm.get('publisherIds')?.value?.length > 1">{{ 'extended_search.select.publishers.other' | translate:{count: extendedSearchForm.get('publisherIds')?.value?.length} }}</span>
              </mat-select-trigger>
              <mat-option *ngFor="let publisher of publishers" [value]="publisher.id">{{ publisher.name }}</mat-option>
            </mat-select>
            <button *ngIf="extendedSearchForm.get('publisherIds')?.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearField('publisherIds')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <mat-label>{{ 'extended_search.book_language' | translate }}</mat-label>
            <mat-select formControlName="languageCodes" multiple>
              <mat-select-trigger>
                <span
                  *ngIf="extendedSearchForm.get('languageCodes')?.value?.length === 0">{{ 'extended_search.select.languages.zero' | translate }}</span>
                <span
                  *ngIf="extendedSearchForm.get('languageCodes')?.value?.length === 1">{{ 'extended_search.select.languages.one' | translate }}</span>
                <span
                  *ngIf="extendedSearchForm.get('languageCodes')?.value?.length > 1">{{ 'extended_search.select.languages.other' | translate:{count: extendedSearchForm.get('languageCodes')?.value?.length} }}</span>
              </mat-select-trigger>
              <mat-option *ngFor="let language of languages"
                          [value]="language.code3">{{ language.getTranslationKey() | translate }}</mat-option>
            </mat-select>
            <button *ngIf="extendedSearchForm.get('languageCodes')?.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearField('languageCodes')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

      </form>
    </mat-step>

  </mat-stepper>

  <div class="btn-container">
    <button mat-raised-button (click)="dismiss()"
            type="button">{{ 'cancel' | translate }}</button>
    <button mat-raised-button (click)="resetSearch()"
            type="button">{{ 'search.new' | translate }}</button>

    <button mat-raised-button color="primary"
            [disabled]="      !(this.extendedSearchForm.get('isbn')?.value ||
      this.extendedSearchForm.get('title')?.value ||
      this.extendedSearchForm.get('subtitle')?.value ||
      this.extendedSearchForm.get('blurb')?.value ||
      this.extendedSearchForm.get('author')?.value ||
      (this.extendedSearchForm.get('categoryIds')?.value && this.extendedSearchForm.get('categoryIds')?.value?.length > 0) ||
      (this.extendedSearchForm.get('publisherIds')?.value && this.extendedSearchForm.get('publisherIds')?.value?.length > 0) ||
      (this.extendedSearchForm.get('languageCodes')?.value && this.extendedSearchForm.get('languageCodes')?.value?.length > 0))"
            (click)="triggerExtendedSearch()"
            type="button"
    >{{ 'search.search' | translate }}</button>
  </div>

</div>

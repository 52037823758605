import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { GraphqlService } from 'scf-graphql-model'
import { environment } from '../../../environments/environment'
import { TranslationBundle } from '../../models/vouchers/translation_bundle.model'

@Injectable()
export class TranslationBundleService extends GraphqlService<TranslationBundle> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  byCode(code: string) {
    const productDetail = 'id internalId priceNetto currencyPrices { currency exchangeRate netPrice } productBook { title language subtitle blurb isbn hardcover numpages publishedAt publisher { name url } productBookAuthors { id name vita role } }'
    return this.find(`{ translationBundle(code: "${ code }") { code validUntil original { ${ productDetail } } translations { ${ productDetail } } discount { percentage } } }`)
  }

}

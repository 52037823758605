import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('language', ['aggregations', 'availableLanguages'])
export class Language extends BaseModel {
  @Column()
  i18n: string | undefined;
  @Column()
  default: string | undefined;
  @Column()
  code2: string | undefined;
  @Column()
  code3: string | undefined;
  @Column()
  counter: number | undefined;

  getTranslationKey(): string {
    return `product.book_languages.${this.i18n}`
  }

}

import { GraphqlService } from 'scf-graphql-model'
import { environment } from '../../environments/environment'
import { Category } from '../models/category.model'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import categoryQuery from 'src/app/graphql/queries/category.query.graphql'
import categoriesByIdAndIsoQuery from 'src/app/graphql/queries/categories_by_id_and_iso.query.graphql'
import categoriesByIsoQuery from 'src/app/graphql/queries/categories_by_iso.query.graphql'

@Injectable()
export class CategoryService extends GraphqlService<Category> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  fetchCategory(id?: number, iso?: string): Observable<Category> {
    return this.find(categoryQuery, { id, iso })
  }

  fetchCategories(id?: number, iso?: string): Observable<Category[]> {
    if (id && id !== 0) {
      return this.findAll(categoriesByIdAndIsoQuery, { id, iso })
    } else {
      return this.findAll(categoriesByIsoQuery, { iso })
    }
  }

}

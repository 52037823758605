import { environment } from '../../environments/environment'
import { User } from '../models/user.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import checkLoginMutation from 'src/app/graphql/mutations/check_login.mutation.graphql'
import loginMutation from 'src/app/graphql/mutations/do_login.mutation.graphql'
import logoutMutation from 'src/app/graphql/mutations/do_logout.mutation.graphql'

@Injectable()
export class LoginService extends GraphqlService<User> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  checkEmail(user: User): Observable<User> {
    return this.push(`checkEmail(username: "${ user.username }") { requireLogin }`)
  }

  doLogin(user: User, iso: string): Observable<User> {
    return this.push(loginMutation, { username: user.username, password: user.password, iso })
  }

  doLogout(persistenceToken: string): Observable<User> {
    return this.push(logoutMutation, { persistenceToken })
  }

  checkLogin(persistenceToken: string): Observable<User> {
    return this.push(checkLoginMutation, { persistenceToken })
  }

  private userFragment(): string {
    return ``
  }

}

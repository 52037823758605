import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('paymentMethod', ['paymentMethods'])
export class PaymentMethod extends BaseModel {
  @Column()
  type: string | undefined
  @Column()
  provider: string | undefined
  @Column()
  name: string | undefined
  @Column()
  brands: string[] = []

  logoUrl(brand?: string) {
    if (this.provider === 'Adyen' || this.provider === 'PagBrasil') {
      if (brand) {
        return `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${ brand }.svg`
      } else {
        return `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${ this.type }.svg`
      }
    } else if (this.provider === 'WireTransfer') {
      return `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/bank.svg`
    } else {
      return ''
    }
  }

}

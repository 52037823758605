import { BaseModel, Column, Model } from 'scf-graphql-model'
import { UserInformation } from './user_information.model'

@Model('user', ['doLogin', 'doLogout', 'checkLogin', 'checkEmail', 'myData', 'updateMyData'])
export class User extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  username: string | undefined;
  @Column()
  email: string | undefined;
  @Column()
  password: string | undefined;
  @Column()
  name: string | undefined;
  @Column()
  persistenceToken: string | undefined;
  @Column()
  requireLogin: boolean | undefined;
  @Column()
  prepayment: boolean | undefined
  @Column()
  isAuthor: boolean | undefined
  @Column()
  isBookseller: boolean | undefined
  @Column()
  isWholesaler: boolean | undefined
  @Column()
  isRegisteredEuCompany: boolean | undefined
  @Column()
  isCompany: boolean | undefined
  @Column()
  userInformation: UserInformation | undefined

  public impersonated: boolean = false

  public loggedIn(): boolean {
    return (!!this.persistenceToken && this.persistenceToken !== '')
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('pagination')
export class Pagination<T> extends BaseModel {
  @Column()
  totalPages: number | undefined;
  @Column()
  totalEntries: number | undefined;
  @Column()
  currentPage: number | undefined;
  @Column()
  perPage: number | undefined;
  @Column()
  previousPage: number | undefined;
  @Column()
  nextPage: number | undefined;
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { GraphqlService } from 'scf-graphql-model'
import { environment } from '../../environments/environment'
import { Language } from '../models/language.model'

import availableLanguagesQuery from 'src/app/graphql/queries/available_languages.query.graphql'

@Injectable()
export class LanguageService extends GraphqlService<Language> {

  languages: Language[]

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
    this.initAvailableLanguages()
  }

  getFlag(code3: string | undefined) {
    const language = this.solveLanguage(code3)
    if (language) {
      return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${ language.code2?.toUpperCase() }.svg`
    } else {
      return ''
    }
  }

  getTranslationKey(code3: string | undefined): string {
    const language = this.solveLanguage(code3)
    if (!language) {
      return ''
    }
    return `product.book_languages.${ language?.code3 || 'unknown' }`
  }

  solveLanguage(code3: string | undefined): Language | undefined {
    if (!this.languages) {
      return
    }
    return this.languages.find((lang) => lang.code3 === code3)
  }

  getAvailableLanguages(): Observable<Language[]> {
    return this.findAll(availableLanguagesQuery)
  }

  private initAvailableLanguages() {
    this.getAvailableLanguages().subscribe((languages) => {
      this.languages = languages
    })
  }

}

import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ExtendedSearch } from '../../../models/extended_search.model';
import { SearchObserver } from '../../../observers/search.observer';
import { ProductService } from '../../../services/product.service';
import { ProductsComponent } from '../products/products.component';
import { Product } from '../../../models/product.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BookLanguageObserver } from '../../../observers/book_language.observer';
import { ExtendedSearchComponent } from './extended_search.component';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {

  @ViewChild('searchBar') searchBar: ElementRef<any>
  @ViewChild('productComponent') productComponent: ProductsComponent

  isExtendedSearchActivated: boolean = false
  extendedSearch: ExtendedSearch | undefined

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = undefined;
    }
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      this.performSearch()
    } else if (event.code === 'Escape') {
      this.searchObserver.hideSearchBar()
    } else {
      this.searchTimeout = setTimeout(() => {
        this.performSearch()
      }, 500)
    }
  }

  isVisible = false
  page = 1
  products: Product[] = []
  searchTerm: string
  searchTimeout: Timeout | undefined;

  constructor(
    private bookLanguageObserver: BookLanguageObserver,
    private searchObserver: SearchObserver,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private _bottomSheet: MatBottomSheet
  ) {
    this.bookLanguageObserver.bookLanguageObserver$.subscribe(
      () => {
        this.performSearch()
      }
    )

    this.route.url.subscribe(
      url => {
        this.route.queryParams.subscribe(
          (params) => {
            if (params['page']) {
              this.page = +params['page']
            }

            if (params['q']) {
              this.searchTerm = params['q']
              this.performSearch()
            }
          }
        )
      }
    )
  }

  ngOnInit(): void {
    this.searchObserver.searchVisibilityObserver$.subscribe(
      (visible) => {
        this.isVisible = visible
        if (this.isVisible) {
          setTimeout(
            () => {
              this.searchBar.nativeElement.focus()
            }, 300
          )
        }
      }
    )

    this.searchObserver.extendedSearchTriggerObserver$.subscribe(
      (extendedSearch) => {
        this._bottomSheet.dismiss()
        this.performExtendedSearch(extendedSearch)
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  handlePageChange(page: number) {
    this.page = page
    if (this.extendedSearch) {
      this.performExtendedSearch()
    } else {
      this.performSearch()
    }
  }

  handleProductSelected(product: Product) {
    this.searchObserver.hideSearchBar()
    this.router.navigateByUrl(`/shop/product/${ product.ean }`)
  }

  toggleExtendedSearch() {
    if (this.isExtendedSearchActivated || this.extendedSearch) {
      this.products = []
      this.isExtendedSearchActivated = false
      this.extendedSearch = undefined
    } else {
      this.products = []
      this.reOpenExtendedSearch()
    }
  }

  reOpenExtendedSearch() {
    this.isExtendedSearchActivated = true
    const bottomSheetRef = this._bottomSheet.open(ExtendedSearchComponent, {
      data: {extendedSearch: this.extendedSearch},
      ariaLabel: 'Extended search'
    });

    bottomSheetRef.afterDismissed().subscribe(() => {
      this.isExtendedSearchActivated = false
    });
  }

  resetSearch(normal = true, extended = true) {
    if (normal) {
      this.searchTerm = ''
      this.searchObserver.resetSearchTerm()
    }
    if (extended) {
      this.extendedSearch = undefined
      this.isExtendedSearchActivated = false
    }
  }

  performSearch() {
    this.resetSearch(false, true)
    this.searchTimeout = undefined

    if (this.searchTerm) {
      this.productService.bySearchTerm(this.searchTerm, +this.page, this.bookLanguageObserver.currentLanguageStr).subscribe(
        (result) => {
          this.location.go(`/shop/search?q=${ this.searchTerm }&page=${ this.page }`);
          this.products = result
          this.productComponent.pagination = this.productService.pagination()
          this.productComponent.aggregations = this.productService.aggregations()
        }
      )
    }

    this.searchObserver.searchPerformed(this.searchTerm)

  }

  performExtendedSearch(extendedSearch?: ExtendedSearch) {
    this.resetSearch(true, false)

    if (extendedSearch) {
      this.extendedSearch = extendedSearch
    }

    if (this.extendedSearch) {

      if (!this.extendedSearch.languageCodes) {
        this.extendedSearch.languageCodes = []
      }

      this.productService.byExtendedSearch(this.extendedSearch, this.page).subscribe(
        (result) => {
          this.location.go(`/shop/extended-search`)
          this.products = result
          this.productComponent.pagination = this.productService.pagination()
          this.productComponent.aggregations = this.productService.aggregations()
        }
      )
    }

  }

}

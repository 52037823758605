import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import { ExtendedSearch } from "../models/extended_search.model";

@Injectable()
export class SearchObserver {

  searchVisible = false;
  searchVisibilityObserver$: BehaviorSubject<boolean>;
  searchResetObserver$: Subject<any> = new Subject();
  searchPerformedObserver$: Subject<string> = new Subject<string>();
  extendedSearchTriggerObserver$: Subject<ExtendedSearch> = new Subject<ExtendedSearch>();

  constructor() {
    this.searchVisibilityObserver$ = new BehaviorSubject<boolean>(this.searchVisible)
  }

  toggleSearchBar() {
    this.searchVisible = !this.searchVisible
    this.searchVisibilityObserver$.next(this.searchVisible)
  }

  hideSearchBar() {
    this.searchVisible = false
    this.searchVisibilityObserver$.next(this.searchVisible)
  }

  showSearchBar() {
    this.searchVisible = true
    this.searchVisibilityObserver$.next(this.searchVisible)
  }

  searchPerformed(term: string) {
    this.searchPerformedObserver$.next(term)
  }

  triggerExtendedSearch(object: ExtendedSearch | undefined) {
    this.extendedSearchTriggerObserver$.next(object)
  }

  resetSearchTerm() {
    this.searchResetObserver$.next()
  }

}

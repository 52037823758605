<h1>{{ 'oauth.vdmo_auth' | translate }}</h1>

<div class="flex-horizontal-container">

  <h3 class="seperator" *ngIf="imprint?.login?.required">{{ 'oauth.description' | translate }}</h3>

  <div class="flex-container">

    <img class="logo" [src]="imprint?.logo"/>

    <form class="login" [formGroup]="oAuthLoginForm" (ngSubmit)="onSubmit($event)"
          *ngIf="imprint?.login?.required && !imprint?.code">
      <div>
        <mat-form-field appearance="standard">
          <mat-label>{{ 'checkout.username' | translate }}</mat-label>
          <input type="text" formControlName="login" matInput required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>{{ 'checkout.password' | translate }}</mat-label>
          <input type="password" formControlName="password" matInput required>
        </mat-form-field>
      </div>
      <button mat-raised-button color="primary" type="submit" [disabled]="oAuthLoginForm?.invalid">
        Login
      </button>
      <button mat-raised-button class="dismiss" type="button" (click)="skipOrCancel()">
        {{ 'oauth.im_not_an_author' | translate }}
      </button>
    </form>

    <form class="login" [formGroup]="oAuthPermissionForm" (ngSubmit)="onSubmit($event)"
          *ngIf="imprint?.login?.required === false && !imprint?.code">

      <strong>{{ 'oauth.ask_for_permissions' | translate }}</strong>

      <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let perm of imprint.permissions">
          - {{ 'oauth.scopes.' + perm | translate }}
        </mat-list-item>
      </mat-list>

      <button mat-raised-button color="primary" type="submit" [disabled]="oAuthPermissionForm?.invalid">
        {{ 'oauth.agreed' | translate }}
      </button>
      <button mat-raised-button class="dismiss" type="button" (click)="skipOrCancel()">
        {{ 'oauth.i_do_not_agree' | translate }}
      </button>

    </form>

    <div *ngIf="imprint?.code" style="text-align: center">
      <mat-icon>check</mat-icon>
    </div>

  </div>
</div>

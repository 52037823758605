import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { GraphqlService } from 'scf-graphql-model'
import { environment } from '../../environments/environment'
import { OAuth } from '../models/oauth.model'
import { HttpClient } from '@angular/common/http'

import oAuthQuery from 'src/app/graphql/queries/oauth.query.graphql'

@Injectable()
export class OAuthService extends GraphqlService<OAuth> {

  constructor(http: HttpClient, private httpClient: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  fetchOAuthRequiredImprints(): Observable<OAuth[]> {
    return this.findAll(oAuthQuery)
  }

  authorize(authorize_url: string): Observable<any> {
    return this.httpClient.get(authorize_url + '&format=json', {
      withCredentials: true, responseType: 'json', observe: 'response',
    })
  }

  authorizeWithData(postToUrl: string, postData: any): Observable<any> {
    return this.httpClient.post(postToUrl + '?format=json', postData, {
      withCredentials: true, responseType: 'json', observe: 'response',
    })
  }

  login(imprint: any, login: string, password: string): Observable<any> {
    return this.httpClient.post(imprint.login.post_to, {
      format: 'json', login: login, password: password,
    }, { withCredentials: true, responseType: 'json', observe: 'response' })
  }

  persistCode(imprint: any) {
    return this.httpClient.post(environment.oauth, {
      format: 'json', with_original_response: true, matchcode: imprint.matchcode, code: imprint.code,
    }, { withCredentials: true, responseType: 'json', observe: 'response' })
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('countries', ['country'])
export class Country extends BaseModel {
  @Column()
  id: number | undefined;

  @Column()
  name: string | undefined;

  @Column()
  printableName: string | undefined;

  @Column()
  deliverable: boolean | undefined;

  @Column()
  synonyms: string[] = [];

  @Column()
  iso: string | undefined;

  @Column()
  eu: boolean | undefined;

  @Column()
  americas: boolean | undefined;

  @Column()
  current: boolean | undefined;

  @Column()
  ustidPattern: string | undefined;
}

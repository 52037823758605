<div class="row">
  <div class="col-xxl-2 col-xl-3 col-xs-12 user-actions">

    <mat-nav-list class="actions-list">
      <mat-list-item [routerLink]="['/shop']">
        <mat-icon mat-list-icon>home</mat-icon>
        <strong>{{ 'user_area.continue_shopping' | translate }}</strong>
      </mat-list-item>

      <mat-list-item [routerLink]="['/user_area/my_account']" [routerLinkActive]="'active'">
        <mat-icon mat-list-icon>self_improvement</mat-icon>
        <span>{{ 'user_area.my_account' | translate }}</span>
      </mat-list-item>
      <mat-list-item [routerLink]="['/user_area/my_books']" [routerLinkActive]="'active'" [disabled]="!currentUser?.isAuthor">
        <mat-icon mat-list-icon>auto_stories</mat-icon>
        <span>{{ 'user_area.my_books' | translate }}</span>
      </mat-list-item>
      <mat-list-item [routerLink]="['/user_area/my_orders']" [routerLinkActive]="'active'">
        <mat-icon mat-list-icon>shopping_bag</mat-icon>
        <span>{{ 'user_area.my_orders' | translate }}</span>
      </mat-list-item>
    </mat-nav-list>

  </div>

  <div class="col-xxl-10 col-xl-9 col-xs-12" *ngIf="render">

    <app-my-account *ngIf="render === RENDER_MY_ACCOUNT"></app-my-account>
    <app-my-orders *ngIf="render === RENDER_MY_ORDERS"></app-my-orders>
    <app-my-books *ngIf="render === RENDER_MY_BOOKS"></app-my-books>

  </div>
</div>

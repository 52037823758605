import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { Order } from '../models/order.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'

import orderQuery from 'src/app/graphql/queries/order.query.graphql'
import myOrdersQuery from 'src/app/graphql/queries/my_orders.query.graphql'
import doCheckoutMutation from 'src/app/graphql/mutations/do_checkout.mutation.graphql'
import cancelOrderMutation from 'src/app/graphql/mutations/cancel_order.mutation.graphql'

@Injectable()
export class OrderService extends GraphqlService<Order> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  fetchOrder(builderhash: string): Observable<Order> {
    return this.find(orderQuery, { builderhash })
  }

  fetchMyOrders(page: number): Observable<Order[]> {
    return this.findAll(myOrdersQuery, { page })
  }

  performCheckout(base64Data: string): Observable<Order> {
    return this.push(doCheckoutMutation, { data: base64Data })
  }

  cancelOrder(builderhash: string, iso: string): Observable<Order> {
    return this.push(cancelOrderMutation, { builderhash, iso })
  }

}

<ng-template #productsTemplate>
  <app-products #productComponent [products]="products" [hideFilter]="(isExtendedSearchActivated || !!extendedSearch)"
                (onPageChanged)="handlePageChange($event)"
                (onProductSelected)="handleProductSelected($event)"
  ></app-products>

</ng-template>

<div class="search-wrapper" *ngIf="!isExtendedSearchActivated && !extendedSearch">

  <div class="extended-search">

    <button matSuffix mat-icon-button aria-label="Clear" (click)="toggleExtendedSearch()"
            *ngIf="!isExtendedSearchActivated && !extendedSearch">
      {{ 'extended_search.self' | translate }}
      <mat-icon>toggle_off</mat-icon>
    </button>

    <mat-form-field class="transparent hide" appearance="fill" [hidden]="!isVisible">
      <mat-label>{{ 'search.search' | translate }}</mat-label>
      <input #searchBar matInput type="text" [(ngModel)]="searchTerm">

      <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

  </div>

  <ng-container *ngTemplateOutlet="productsTemplate"></ng-container>

</div>

<div class="search-wrapper" *ngIf="isExtendedSearchActivated || extendedSearch">

  <div class="search">

    <mat-card class="transparent">

      <button *ngIf="extendedSearch" matSuffix mat-icon-button aria-label="Clear" class="reset_search"
              (click)="resetSearch()">
        <mat-icon>close</mat-icon>
      </button>

      <button matSuffix mat-icon-button aria-label="Clear" (click)="toggleExtendedSearch()">
        {{ 'extended_search.self' | translate }}
        <mat-icon *ngIf="isExtendedSearchActivated || extendedSearch">toggle_on</mat-icon>
        <mat-icon *ngIf="!isExtendedSearchActivated && !extendedSearch">toggle_off</mat-icon>
      </button>

      <strong class="search_results_desc" *ngIf="extendedSearch">{{ 'search.results_for' | translate }}:</strong>

      <mat-card-content *ngIf="extendedSearch">
        <mat-chip-list aria-label="Fish selection">
          <mat-chip *ngIf="extendedSearch.isbn" (click)="reOpenExtendedSearch()" [title]="'search.refine' | translate">
            {{ 'extended_search.isbn' | translate }}: {{ extendedSearch.isbn }}
          </mat-chip>
          <mat-chip *ngIf="extendedSearch.title" (click)="reOpenExtendedSearch()" [title]="'search.refine' | translate">
            {{ 'extended_search.title' | translate }}: {{ extendedSearch.title }}
          </mat-chip>
          <mat-chip *ngIf="extendedSearch.subtitle" (click)="reOpenExtendedSearch()"
                    [title]="'search.refine' | translate">
            {{ 'extended_search.subtitle' | translate }}: {{ extendedSearch.subtitle }}
          </mat-chip>
          <mat-chip *ngIf="extendedSearch.blurb" (click)="reOpenExtendedSearch()" [title]="'search.refine' | translate">
            {{ 'extended_search.blurb' | translate }}: {{ extendedSearch.blurb }}
          </mat-chip>
          <mat-chip *ngIf="extendedSearch.author" (click)="reOpenExtendedSearch()"
                    [title]="'search.refine' | translate">
            {{ 'extended_search.author' | translate }}: {{ extendedSearch.author }}
          </mat-chip>
          <mat-chip *ngIf="extendedSearch.categoryIds && extendedSearch.categoryIds.length > 0"
                    (click)="reOpenExtendedSearch()" [title]="'search.refine' | translate">
            {{ 'extended_search.categories' | translate }}
            : {{ 'extended_search.select.categories.other' | translate:{count: extendedSearch?.categoryIds?.length} }}
          </mat-chip>
          <mat-chip *ngIf="extendedSearch.publisherIds && extendedSearch.publisherIds.length > 0"
                    (click)="reOpenExtendedSearch()" [title]="'search.refine' | translate">
            {{ 'extended_search.publisher' | translate }}
            : {{ 'extended_search.select.publishers.other' | translate:{count: extendedSearch?.publisherIds?.length} }}
          </mat-chip>
          <mat-chip *ngIf="extendedSearch.languageCodes && extendedSearch.languageCodes.length > 0"
                    (click)="reOpenExtendedSearch()" [title]="'search.refine' | translate">
            {{ 'extended_search.book_language' | translate }}
            : {{ 'extended_search.select.languages.other' | translate:{count: extendedSearch?.languageCodes?.length} }}
          </mat-chip>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>

  </div>

  <ng-container *ngTemplateOutlet="productsTemplate"></ng-container>

</div>

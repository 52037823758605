<h2 class="container display3 display4-lg">
  <span>{{ 'user_area.my_account' | translate }}</span>
</h2>

<mat-stepper #stepper orientation="vertical" [linear]="true">

  <mat-step [stepControl]="accountInformationFormGroup">
    <ng-template matStepLabel>{{ 'user_area.user.my_data' | translate }}</ng-template>

    <form [formGroup]="accountInformationFormGroup">

      <mat-form-field appearance="standard">
        <mat-label>{{ 'user_area.user.forename' | translate }}</mat-label>
        <input matInput type="text" formControlName="forename" required/>
        <button *ngIf="accountInformationFormGroup.get('forename')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearField(accountInformationFormGroup,'forename')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'user_area.user.surname' | translate }}</mat-label>
        <input matInput type="text" formControlName="surname" required/>
        <button *ngIf="accountInformationFormGroup.get('surname')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearField(accountInformationFormGroup,'surname')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'user_area.user.email' | translate }}</mat-label>
        <input matInput type="text" formControlName="email" required/>
        <button *ngIf="accountInformationFormGroup.get('email')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearField(accountInformationFormGroup,'email')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="standard"
                      *ngIf="accountInformationFormGroup.get('email')?.value && user.email !== accountInformationFormGroup.get('email')?.value">
        <mat-label>{{ 'user_area.user.email_confirmation' | translate }}</mat-label>
        <input matInput type="text" formControlName="emailConfirmation" required/>
        <button *ngIf="accountInformationFormGroup.get('emailConfirmation')?.value" matSuffix mat-icon-button
                aria-label="Clear" (click)="clearField(accountInformationFormGroup,'emailConfirmation')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="accountInformationFormGroup.get('emailConfirmation')?.hasError('confirmationNotEqual')">
          {{ 'user_area.user.validation.email_does_not_match' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'user_area.user.password' | translate }}</mat-label>
        <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
        <input #password matInput [type]="toggle.type" formControlName="password"/>
        <button *ngIf="accountInformationFormGroup.get('password')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearField(accountInformationFormGroup,'password')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error
          *ngIf="accountInformationFormGroup.get('password')?.hasError('minlength')">
          {{ 'user_area.user.validation.password_to_short' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" *ngIf="accountInformationFormGroup.get('password')?.value">
        <mat-label>{{ 'user_area.user.password_confirmation' | translate }}</mat-label>

        <mat-pass-toggle-visibility #confirmationToggle matSuffix></mat-pass-toggle-visibility>
        <input matInput [type]="confirmationToggle.type" formControlName="passwordConfirmation" required/>
        <button *ngIf="accountInformationFormGroup.get('passwordConfirmation')?.value" matSuffix mat-icon-button
                aria-label="Clear" (click)="clearField(accountInformationFormGroup,'passwordConfirmation')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error
          *ngIf="accountInformationFormGroup.get('passwordConfirmation')?.hasError('confirmationNotEqual')">
          {{ 'user_area.user.validation.password_does_not_match' | translate }}
        </mat-error>
      </mat-form-field>

      <div [formGroup]="companyInformationFormGroup">
        <mat-slide-toggle
          formControlName="isCompany">{{ 'user_area.user.company' | translate }} ?
        </mat-slide-toggle>
      </div>

      <div class="btn-container">
        <button mat-raised-button color="primary" type="button" class="pull-right-btn" (click)="save()"
                *ngIf="!companyInformationFormGroup.get('isCompany')?.value">{{ 'save' | translate }}</button>

        <button mat-raised-button matStepperNext type="button"
                *ngIf="companyInformationFormGroup.get('isCompany')?.value">{{ 'next' | translate }}</button>
      </div>

    </form>
  </mat-step>

  <mat-step [stepControl]="companyInformationFormGroup"
            *ngIf="companyInformationFormGroup.get('isCompany')?.value">

    <ng-template matStepLabel>{{ 'user_area.user.company_data' | translate }}</ng-template>
    <form [formGroup]="companyInformationFormGroup">

      <mat-form-field appearance="standard">
        <mat-label>{{ 'checkout.country' | translate }}</mat-label>

        <mat-select formControlName="country" class="filtered" (closed)="resetCountryFilter()"
                    (opened)="countryFilter.select()" (selectionChange)="onCountrySelected()" required>

          <input #countryFilter matInput type="text" class="filter" formControlName="countryFilter"
                 [placeholder]="'checkout.country' | translate">

          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{ country.printableName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'user_area.user.company' | translate }}</mat-label>
        <input matInput type="text" formControlName="company" required/>
        <button *ngIf="companyInformationFormGroup.get('company')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearField(companyInformationFormGroup, 'company')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="standard" *ngIf="companyInformationFormGroup.get('country')?.value?.eu">
        <mat-label>{{ 'user_area.user.ustidnr' | translate }}</mat-label>
        <input matInput type="text" formControlName="ustidnr"/>
        <button *ngIf="companyInformationFormGroup.get('ustidnr')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearField(companyInformationFormGroup,'ustidnr')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="companyInformationFormGroup.get('ustidnr')?.hasError('pattern')">
          {{ 'user_area.user.validation.ustidnr_does_not_match_eu_guidelines' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" *ngIf="companyInformationFormGroup.get('country')?.value?.americas">
        <mat-label>{{ 'user_area.user.tax_id' | translate }}</mat-label>
        <input matInput type="text" formControlName="taxId"/>
        <button *ngIf="companyInformationFormGroup.get('taxId')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearField(companyInformationFormGroup,'taxId')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-hint>
          {{ 'user_area.user.tax_id_hint' | translate }}
        </mat-hint>
      </mat-form-field>

      <div class="btn-container">
        <button mat-raised-button color="primary" type="button" class="pull-right-btn" (click)="save()"
                [disabled]="accountInformationFormGroup.invalid || companyInformationFormGroup.invalid">{{ 'save' | translate }}</button>

        <button mat-raised-button matStepperPrevious
                type="button">{{ 'previous' | translate }}</button>
      </div>

    </form>

  </mat-step>

</mat-stepper>

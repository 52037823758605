import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { PaymentMethod } from '../models/payment_method.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'

import paymentMethodsQuery from 'src/app/graphql/queries/payment_methods.query.graphql'

@Injectable()
export class PaymentMethodService extends GraphqlService<PaymentMethod> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  getPaymentMethods(amount: number, currency: string): Observable<PaymentMethod[]> {
    return this.findAll(paymentMethodsQuery, { amount: amount, currency: currency })
  }

}

import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { AccountBook } from '../models/account_book.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import myBooksQuery from 'src/app/graphql/queries/my_books.query.graphql'

@Injectable()
export class AccountBookService extends GraphqlService<AccountBook> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  myBooks(): Observable<AccountBook[]> {
    return this.findAll(myBooksQuery)
  }

}

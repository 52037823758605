import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-language-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnChanges {

  @Input() code: string | undefined;

  constructor(
    public languageService: LanguageService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

}

import { environment } from '../../environments/environment'
import { BookPriceOffer } from '../models/vouchers/book_price_offer.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import { CurrencyPrice } from '../models/currency_price.model';
import { BookLaunchOffer } from '../models/book_launch_offer.model';

import bookLaunchOfferQuery from 'src/app/graphql/queries/book_launch_offer.query.graphql'

@Injectable()
export class BookLaunchOfferService extends GraphqlService<BookLaunchOffer> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
    /* DO NOT DELETE */
    new Product()
    new BookPriceOffer()
    new CurrencyPrice()
    /* DO NOT DELETE END */
  }

  byCode(token: string) {
    return this.find(bookLaunchOfferQuery, { token: token })
  }

}

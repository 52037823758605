<div class="flex-container-layout">
  <div class="flex-item product-image" *ngIf="bookLaunchOffer">

    <div class="hide-on-small-screen">
      <app-book-cover [imgSrc]="bookLaunchOffer?.product?.imageURL('cover')"></app-book-cover>
    </div>

  </div>
  <div class="flex-item">

    <div class="product-image-container">
      <app-book-cover [imgSrc]="bookLaunchOffer?.product?.imageURL('cover')"
                      class="hide-on-large-screen"></app-book-cover>
      <div class="headlines">
        <h1>{{ bookLaunchOffer?.product?.productBook?.title }}</h1>
        <h2>{{ bookLaunchOffer?.product?.productBook?.subtitle }}</h2>
        <h3>{{ bookLaunchOffer?.product?.productBook?.isbn }}</h3>

        <app-product [product]="bookLaunchOffer?.product" [hideButton]="true" [hideImage]="true"
                     [hideTitle]="true"
                     [hideSubtitle]="true" [hideISBN]="true"></app-product>
      </div>
    </div>

    <div *ngIf="bookLaunchOffer" class="flex-container">

      <app-countdown [timeInSeconds]="bookLaunchOffer.countdown" [oneWeekGoodwill]="true"
                     (expired)="onCountdownDone($event)" class="countdown"></app-countdown>

      <div class="tiers-container">
        <app-book-launch-offer-v2-item [tier]="tier" [disabled]="countdownExpired" (selectTier)="selectTier($event)"
                                    *ngFor="let tier of bookLaunchOffer.tiers"></app-book-launch-offer-v2-item>
      </div>

    </div>

  </div>
</div>

import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('productBookAuthor', ['productBookAuthors'])
export class ProductBookAuthor extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  name: string | undefined;
  @Column()
  vita: string | undefined;
  @Column()
  role: string | undefined;
}

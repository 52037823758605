import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('currencies', ['currency'])
export class Currency extends BaseModel {
  @Column()
  sign: string | undefined;
  @Column()
  currency: string | undefined;
  @Column()
  isActive: boolean | undefined;
}

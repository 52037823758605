import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { Content } from '../models/content.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'

import webpageQuery from 'src/app/graphql/queries/webpage.query.graphql'
import webpagesQuery from 'src/app/graphql/queries/webpages.query.graphql'

@Injectable()
export class ContentService extends GraphqlService<Content> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  pages(iso: string, area: number) {
    // areas: 1: Main nav, 2: Footer nav, 3: right nav
    return this.findAll(webpagesQuery, { iso, area })
  }

  page(id: number, iso: string) {
    return this.find(webpageQuery, { id, iso })
  }

}

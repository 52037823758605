<ng-template #impersonated>
  <div class="impersonated" *ngIf="user?.impersonated">
    <strong>You impersonate:</strong> {{ user?.name }}
    <button mat-button (click)="stopImpersonating()" title="Stop to impersonate the user">
      <mat-icon>dangerous</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #content>
  <router-outlet></router-outlet>
</ng-template>

<div class="loading" *ngIf="layoutObserver.showNav$.value && localeObserver.availableLocales.length === 0">
  <mat-spinner></mat-spinner>
</div>

<div [class.dark-mode]="layoutObserver.darkMode$.value">

  <mat-sidenav-container style="height: 100vh"
                         *ngIf="layoutObserver.showNav$.value && localeObserver.availableLocales.length>0">
    <section class="flex-container flex-container-100vh">
      <nav class="navbar navbar-dark bg-light navbar-expand-sm sticky-top">
        <div class="container-fluid">
          <a [routerLink]="['/']" class="navbar-brand" href="#" *ngIf="!searchObserver?.searchVisible">
            <img class="logo" src="https://www.morebooks.shop/assets/img/logo.png"/>
          </a>
          <a class="navbar-brand" href="#" *ngIf="searchObserver?.searchVisible"
             (click)="$event.preventDefault(); toggleSearchBar();">
            <img class="logo" src="https://www.morebooks.shop/assets/img/logo.png"/>
          </a>
          <div class="collapse navbar-collapse justify-content-end show">
            <ul class="navbar-nav">
              <li class="nav-item dropdown" dropdown *ngIf="layoutObserver.showLanguageSwitchObserver$.value">
                <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                        aria-controls="dropdown-basic">
                  <img class="flag" [src]="getFlag(translate.currentLang||'')"/>
                  <span class="d-none d-sm-inline-block">{{ 'language' | translate }} <span class="caret"></span></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu"
                    role="menu" aria-labelledby="button-basic">
                  <li role="menuitem" *ngFor="let locale of localeObserver.availableLocales">
                    <a class="dropdown-item" (click)="switchLocale(locale.iso)">
                      <img class="flag" [src]="getFlag(locale.iso||'')"/>
                      <span [class.active]="translate.currentLang === locale.iso">{{ locale.languageName }}</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown" dropdown *ngIf="layoutObserver.showCurrencySwitchObserver$.value">
                <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                        aria-controls="dropdown-basic">
                  <span class="badge bg-secondary currency">{{ currencyObserver.currentCurrency }}</span>
                  <span class="d-none d-sm-inline-block">{{ 'cart.currency' | translate }} <span
                      class="caret"></span></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu"
                    role="menu" aria-labelledby="button-basic">
                  <li role="menuitem" *ngFor="let currency of currencyObserver.currencies">
                    <a class="dropdown-item" (click)="currencyObserver.set(currency)">
                    <span [class.active]="currency.isActive">{{ currency.currency }} <span
                        class="badge bg-secondary">{{ currency.sign }}</span></span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <mat-slide-toggle [formControl]="darkModeToggle">
                  <mat-icon *ngIf="layoutObserver.darkMode$.value">dark_mode</mat-icon>
                  <mat-icon *ngIf="!layoutObserver.darkMode$.value">light_mode</mat-icon>
                </mat-slide-toggle>
              </li>
              <li *ngIf="layoutObserver.showSearchObserver$.value">
                <button mat-icon-button aria-label="Cart icon" (click)="toggleSearchBar()"
                        [class.bounce]="searchBounce">
                  <mat-icon *ngIf="!searchObserver.searchVisible">search</mat-icon>
                  <mat-icon *ngIf="searchObserver.searchVisible"
                            #tooltip="matTooltip"
                            matTooltipShowDelay="500"
                            [matTooltip]="'search.click_to_close_search' | translate"
                            matTooltipPosition="above">search_off
                  </mat-icon>
                </button>
              </li>
              <li>
                <button mat-icon-button aria-label="User icon" *ngIf="!user?.loggedIn()" (click)="openLogin()">
                  <mat-icon>person</mat-icon>
                </button>
              </li>
              <li *ngIf="user?.loggedIn()">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="user actions menu">
                  <mat-icon>settings</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [routerLink]="['/user_area/my_account']">
                    <mat-icon>self_improvement</mat-icon>
                    <span>{{ 'user_area.my_account' | translate }}</span>
                  </button>
                  <button mat-menu-item [routerLink]="['/user_area/my_books']" [disabled]="!user?.isAuthor">
                    <mat-icon>auto_stories</mat-icon>
                    <span>{{ 'user_area.my_books' | translate }}</span>
                  </button>
                  <button mat-menu-item [routerLink]="['/user_area/my_orders']">
                    <mat-icon>shopping_bag</mat-icon>
                    <span>{{ 'user_area.my_orders' | translate }}</span>
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <mat-icon *ngIf="user?.loggedIn()">person_off</mat-icon>
                    <span>{{ 'user_area.logout' | translate }}</span>
                  </button>
                </mat-menu>
              </li>
              <li *ngIf="layoutObserver.showCartObserver$.value && cartObserver.cartItemCounterObserver$.getValue()==0">
                <button mat-icon-button aria-label="Cart icon" (click)="cartObserver.toggleCart()"
                        [class.bounce]="cartObserver.cartBounce">
                  <mat-icon>shopping_cart</mat-icon>
                </button>
              </li>
              <li *ngIf="layoutObserver.showCartObserver$.value && cartObserver.cartItemCounterObserver$.getValue()>0">
                <button mat-icon-button aria-label="Cart icon" (click)="cartObserver.toggleCart()"
                        [matBadge]="cartObserver.cartItemCounterObserver$.getValue()"
                        [class.bounce]="cartObserver.cartBounce">
                  <mat-icon>shopping_cart</mat-icon>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="container-fluid flex-container content-container">
        <ngx-loading-bar [color]="'#1a98d5'" [includeSpinner]="true" [height]="'6px'"
                         *ngIf="!cartObserver.cartVisible"></ngx-loading-bar>
        <div class="main-content">
          <ng-container *ngTemplateOutlet="impersonated"></ng-container>
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>

        <app-footer></app-footer>
      </div>
    </section>
    <mat-sidenav mode="over" position="end" [opened]="cartObserver.cartVisible" (closed)="cartObserver.hideCart()">
      <app-cart></app-cart>
    </mat-sidenav>

  </mat-sidenav-container>

  <div style="height: 100vh" *ngIf="!layoutObserver.showNav$.value">
    <ngx-loading-bar [color]="'#1a98d5'" [includeSpinner]="true" [height]="'6px'"
                     *ngIf="!cartObserver.cartVisible"></ngx-loading-bar>
    <ng-container *ngTemplateOutlet="impersonated"></ng-container>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>

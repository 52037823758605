import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-countdown', templateUrl: './countdown.component.html', styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnChanges {
  @Input() timerIcon: string = 'av_timer'
  @Input() timerIconExpired: string = 'av_timer'
  @Input() oneWeekGoodwill: boolean = false
  @Input() timeInSeconds: number | undefined = 0
  @Output() expired: EventEmitter<boolean> = new EventEmitter<boolean>()

  private static ONE_WEEK_IN_SECONDS = 86400 * 7

  public isExpired: boolean = false
  public days: string = '0'
  public hours: string = '0'
  public minutes: string = '0'
  public seconds: string = '0'

  countdownTimer: any

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stopCountdown()
    this.countdown()
  }

  countdown() {
    this.countdownTimer = setTimeout(() => {
      let timeLeft = (this.timeInSeconds || 0) - parseInt((Date.now() / 1000).toFixed(0))

      if (timeLeft <= 0 && this.oneWeekGoodwill) {
        timeLeft += CountdownComponent.ONE_WEEK_IN_SECONDS
      }

      this.isExpired = false
      if (!this.timeInSeconds || timeLeft < 0) {
        this.isExpired = timeLeft <= 0

        this.days = '0'
        this.hours = '0'
        this.minutes = '0'
        this.seconds = '0'

      } else {
        this.days = Math.floor(timeLeft / 86400).toFixed(0)
        const incompleteDays = timeLeft % 86400

        this.hours = Math.floor(incompleteDays / 3600).toFixed(0)
        const incompleteHours = incompleteDays % 3600

        this.minutes = Math.floor(incompleteHours / 60).toFixed(0)
        const incompleteMinutes = incompleteHours % 60

        this.seconds = Math.floor(incompleteMinutes).toFixed(0)
      }

      this.expired.emit(this.isExpired)
      this.countdown()
    }, 1000)
  }

  stopCountdown() {
    clearTimeout(this.countdownTimer)
  }

}

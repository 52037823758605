import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { Locale } from '../models/locale.model'

import localesQuery from 'src/app/graphql/queries/locales.query.graphql'

@Injectable()
export class LocaleService extends GraphqlService<Locale> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  getAvailableLocales(): Observable<Locale[]> {
    return this.findAll(localesQuery)
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Product} from "../../../models/product.model";
import {TranslateService} from "@ngx-translate/core";
import {CurrencyObserver} from "../../../observers/currency.observer";
import {CartService} from "../../../services/cart.service";
import {CartObserver} from "../../../observers/cart.observer";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() product: Product | undefined;
  @Input() hideBlurb = false;
  @Input() hideImage = false;
  @Input() hideButton = false;
  @Input() hideAuthor = false;
  @Input() hidePublisher = false;
  @Input() hidePrice = false;
  @Input() hideTitle = false;
  @Input() hideSubtitle = false;
  @Input() hideISBN = false;
  bookLanguages: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private cartService: CartService,
    private cartObserver: CartObserver,
    public currencyObserver: CurrencyObserver
  ) {
    this.initBookLanguages()
    translateService.onLangChange.subscribe(
      (t) => {
        this.initBookLanguages()
      })
  }

  ngOnInit(): void {
  }

  private initBookLanguages() {
    this.translateService.get(`product.book_languages`).subscribe(
      (values: {}) => {
        this.bookLanguages = values
      }
    )
  }

  addToCart() {
    if (!this.product) {
      return
    }
    this.cartService.addToCart(this.product).subscribe(
      (cart) => {
        this.cartObserver.updateCart(cart)
      }
    )
  }

  bookLanguage(lang: string | undefined): string | undefined {
    if (lang) {
      const key = Object.keys(this.bookLanguages).find((key) => key === lang)
      if (key) {
        return this.bookLanguages[key]
      }
    }
    return
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('measure')
export class Measure extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  width: number | undefined;
  @Column()
  height: number | undefined;
}

import { Injectable } from '@angular/core'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Product } from '../models/product.model'
import { Cart } from '../models/cart.model'
import { Voucher } from '../models/voucher.model'
import { TranslationBundle } from '../models/vouchers/translation_bundle.model'
import { CartItem } from '../models/cart_item.model'
import { BookPriceOffer } from '../models/vouchers/book_price_offer.model'

import cartQuery from 'src/app/graphql/queries/cart.query.graphql'
import addBundleOfferToCartMutation from 'src/app/graphql/mutations/add_bundle_offer_to_cart.mutation.graphql'
import addVoucherToCartMutation from 'src/app/graphql/mutations/add_voucher_to_cart.mutation.graphql'
import addProductToCartMutation from 'src/app/graphql/mutations/add_product_to_cart.mutation.graphql'
import addTranslationBundleToCartMutation
  from 'src/app/graphql/mutations/add_translation_bundle_to_cart.mutation.graphql'

@Injectable()
export class CartService extends GraphqlService<Cart> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  addToCart(product: Product) {
    let id = 0
    if (product && product.id) {
      id = +product.id
    }
    return this.push(addProductToCartMutation, { productId: id })
  }

  addTranslationBundleToCart(bundle: TranslationBundle, cartItems: CartItem[]) {
    const quantities: { id: number; amount: number }[] = []
    cartItems.forEach((item) => {
      if (item.product && item.product.id) {
        quantities.push({ id: item.product.id, amount: item.amount || 1 })
      }
    })
    return this.push(addTranslationBundleToCartMutation, {
      code: bundle.code, quantities: JSON.stringify(quantities),
    })
  }

  addBundleOfferToCart(voucher: Voucher | BookPriceOffer) {
    return this.push(addBundleOfferToCartMutation, { code: voucher.code })
  }

  addVoucherToCart(voucher: Voucher | BookPriceOffer) {
    return this.push(addVoucherToCartMutation, { code: voucher.code })
  }

  cart() {
    return this.find(cartQuery)
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { JSEncrypt } from 'jsencrypt';

@Model('session', ['openSession', 'validateSession'])
export class Session extends BaseModel {
  @Column()
  token: string | undefined;
  @Column()
  data: string;
  @Column()
  unencryptedData: string;
  @Column()
  publicKey: string;

  sessionData() {
    /* it currently not work as JSEncrypt does not support PublicKey decrypt */
    const decrypt = new JSEncrypt();
    const pubKey = this.publicKey
    const base64data = this.data
    decrypt.setPublicKey(pubKey);
    const data = window.atob(base64data)
    return decrypt.decrypt(data)
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { CartItem } from "./cart_item.model";
import { DeliveryOption } from "./delivery_option.model";
import { Discount } from "./discount.model";
import { Voucher } from "./voucher.model";

@Model('carts', ['cart', 'addToCart', 'addTranslationBundleToCart', 'addBundleOfferToCart', 'addVoucherToCart'])
export class Cart extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  accountId: string | undefined;
  @Column()
  checkedOut: boolean | undefined;
  @Column()
  discountId: number | undefined;
  @Column()
  discountAmount: number | undefined;
  @Column()
  cartItems: CartItem[] = [];
  @Column()
  discount: Discount | undefined;
  @Column()
  voucher: Voucher | undefined;
  @Column()
  weight: number | undefined;

  deliveryOption: DeliveryOption

  priceTotalDiscounted(currency: string, voucher = false): number {
    return this.priceTotal(currency, true, voucher)
  }

  priceTotal(currency: string, discounted = false, voucher = false): number {
    let total = 0.0
    this.cartItems.forEach(
      (item) => {
        if (item.product) {
          if (discounted) {
            total += item.totalDiscountedCurrencyPrice(currency)
          } else {
            total += item.totalDefaultPrice(currency)
          }
        } else if (voucher && item.voucher) {
          total += item.voucher.availableAmount(currency, true)
        }
      }
    )
    if (this.deliveryOption) {
      total += this.deliveryOption.currencyPrice(currency)
    }
    return total;
  }

  productItems(): CartItem[] {
    return this.cartItems.filter(
      (item) => item.product?.id
    )
  }

  voucherItems(): CartItem[] {
    return this.cartItems.filter(
      (item) => item.voucher?.code
    )
  }

}

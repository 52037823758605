import { EventEmitter, Injectable } from '@angular/core'
import { Locale } from '../models/locale.model'
import { LocaleService } from '../services/locale.service'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'

@Injectable()
export class LocaleObserver {

  locale: Locale
  availableLocales: Locale[] = []
  currentLocale = 'DE'
  previousLocale = localStorage.getItem('locale')
  localeChangeEvent: EventEmitter<LangChangeEvent>
  availableLocalesObserver$: Subject<Locale[]> = new Subject<Locale[]>()

  constructor(localeService: LocaleService, translate: TranslateService) {
    this.localeChangeEvent = translate.onLangChange
    this.localeChangeEvent.subscribe((value: LangChangeEvent) => {
      localStorage.setItem('locale', value.lang)
      this.currentLocale = value.lang
    })

    localeService.getAvailableLocales().subscribe((locales) => {
      this.availableLocales = locales
      // @ts-ignore
      translate.addLangs(locales.map((l) => l?.iso))
      this.availableLocalesObserver$.next(this.availableLocales)
    })
  }

}

import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { Country } from '../../../models/country.model';
import { DeliveryOption } from '../../../models/delivery_option.model';
import { OAuth } from '../../../models/oauth.model';
import { PaymentMethod } from '../../../models/payment_method.model';
import { User } from '../../../models/user.model';
import { Voucher } from '../../../models/voucher.model';
import { CartObserver } from '../../../observers/cart.observer';
import { CurrencyObserver } from '../../../observers/currency.observer';
import { LocaleObserver } from '../../../observers/locale.observer';
import { UserObserver } from '../../../observers/user.observer';
import { CartService } from '../../../services/cart.service';
import { OAuthService } from '../../../services/oauth.service';
import { OrderService } from '../../../services/order.service';
import { CountryService } from '../../../services/country.service';
import { DeliveryOptionService } from '../../../services/delivery_option.service';
import { LoginService } from '../../../services/login.service';
import { PaymentMethodService } from '../../../services/payment_method.service';
import { SessionStorage } from '../../../storage/session.storage';
import { OAuthLoginFormComponent } from '../oauth/oauth_login_form.component';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnChanges, OnDestroy {

  countries: Country[] = []
  filteredCountries: Observable<Country[]> | undefined
  filteredCountries2: Observable<Country[]> | undefined

  deliveryCountry: Country | undefined;
  deliveryOptions: DeliveryOption[] = []
  deliveryOptionSubscription: Subscription

  paymentMethods: PaymentMethod[] = []
  paymentMethodSubscription: Subscription

  submittingOrder: boolean = false

  currentUser: User | undefined;
  showLoginOrGuest = false

  userTypeForm: FormGroup
  loginForm: FormGroup
  contactForm: FormGroup
  billingAddressForm: FormGroup
  shippingAddressForm: FormGroup
  deliveryForm: FormGroup
  paymentForm: FormGroup
  voucherForm: FormGroup
  finalizeCheckoutForm: FormGroup

  oAuthForm: FormGroup

  imprints: any[] = []

  snackBarSettings: { duration: number } = {
    duration: 3000
  }

  constructor(
    public currencyObserver: CurrencyObserver,
    public cartObserver: CartObserver,
    private localeObserver: LocaleObserver,
    public userObserver: UserObserver,
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private cartService: CartService,
    private checkoutService: OrderService,
    private deliverOptionService: DeliveryOptionService,
    private loginService: LoginService,
    private oAuthService: OAuthService,
    private paymentMethodService: PaymentMethodService,
    private router: Router,
    private httpClient: HttpClient,
    private bottomSheet: MatBottomSheet,
    private sessionStorage: SessionStorage,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    /* DO NOT DELETE */
    new Country()
    new DeliveryOption()
    new OAuth()
    new PaymentMethod()
    /* DO NOT DELETE END */

    this.userObserver.userChangedObserver$.subscribe(
      (user) => {
        this.currentUser = user
      }
    )

    this.userTypeForm = this.formBuilder.group({
      type: sessionStorage.getGuestType()
    })

    this.contactForm = this.formBuilder.group({
      email: '',
      phone: ''
    })

    this.loginForm = this.formBuilder.group({
      username: '',
      password: ''
    })

    this.billingAddressForm = this.formBuilder.group({
      countryFilter: '',
      country: null,
      tax: '',
      company: '',
      ustid: '',
      firstname: '',
      surname: '',
      street: '',
      postalCode: '',
      city: '',
      additional: '',
      hasShippingAddress: false
    });

    this.shippingAddressForm = this.formBuilder.group({
      countryFilter: '',
      country: null,
      tax: '',
      company: '',
      ustid: '',
      firstname: '',
      surname: '',
      street: '',
      postalCode: '',
      city: '',
      additional: '',
      hasShippingAddress: false
    });

    this.deliveryForm = this.formBuilder.group({
      deliveryOption: null
    });

    this.paymentForm = this.formBuilder.group({
      paymentMethod: null
    });

    this.voucherForm = this.formBuilder.group({
      voucherCode: null
    });

    this.finalizeCheckoutForm = this.formBuilder.group({
      termsOfCondition: null,
      dataProtection: null,
      guestPassword: null,
      setPassword: null,
      comment: null
    });

    this.cartObserver.cartObserver$.subscribe(
      (cart) => {

        if (this.cartObserver.cartFetched && cart.cartItems.length === 0) {
          this.router.navigateByUrl(`/`)
        } else if (this.cartObserver.cartFetched && cart.cartItems.length > 0) {

          this.setDeliveryOptionIfSelected()
          this.paymentMethodSubscription = this.paymentMethodService.getPaymentMethods(
            cart.priceTotalDiscounted(this.currencyObserver.currentCurrency),
            this.currencyObserver.currentCurrency
          ).subscribe(
            (paymentMethods) => this.paymentMethods = paymentMethods
          )
        }
      }
    )

    this.countryService.deliverable().subscribe(
      (countries) => {

        this.countries = countries
        const currentCountry = this.countries.find((cntry) => cntry.current)

        if (currentCountry) {
          this._preselectCountry(this.billingAddressForm, currentCountry)
          this._preselectCountry(this.shippingAddressForm, currentCountry)
        }
        this.filteredCountries = this._setupCountryFilterChangeEvent(this.billingAddressForm)
        this.filteredCountries2 = this._setupCountryFilterChangeEvent(this.shippingAddressForm)
      }
    )

    if (sessionStorage.getGuestType() !== 'customer') {
      this.setGuestType()
    }

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
  }

  onCountrySelected() {

    if (this.deliveryOptionSubscription && !this.deliveryOptionSubscription.closed) {
      this.deliveryOptionSubscription.unsubscribe()
    }

    this.deliveryOptions = []

    let country: Country | null = null;

    if (!this.billingAddressForm.get('hasShippingAddress')?.value) {
      country = this.billingAddressForm.get('country')?.value
    } else if (this.billingAddressForm.get('hasShippingAddress')?.value) {
      country = this.shippingAddressForm.get('country')?.value
    }

    if (country) {

      this.deliveryCountry = country

      if (this.deliveryCountry.eu && this.deliveryCountry.ustidPattern) {
        this.billingAddressForm.get('ustid')?.setValidators([Validators.required, Validators.pattern(this.deliveryCountry.ustidPattern)])
      } else {
        this.billingAddressForm.get('ustid')?.clearValidators()
      }

      this.deliveryOptionSubscription = this.deliverOptionService.availableDeliveryOptions(this.deliveryCountry).subscribe(
        (options) => {
          this.deliveryForm.reset()
          this.deliveryForm.setValue({deliveryOption: null})
          this.deliveryOptions = options
          if (options.length === 1) {
            this._preselectDeliveryOption(options[0])
            this.setDeliveryOptionIfSelected()
          }
        }
      )

    } else {
      this.deliveryCountry = undefined
    }
  }

  onDeliveryOptionSelected() {
    this.setDeliveryOptionIfSelected()
  }

  onPasswordRequireToggled() {
    const value = !this.finalizeCheckoutForm.get('setPassword')?.value

    setTimeout(
      () => {
        if (value) {
          this.finalizeCheckoutForm.get('guestPassword')?.setValidators([Validators.required])
        } else {
          this.finalizeCheckoutForm.get('guestPassword')?.clearValidators()
          this.finalizeCheckoutForm.patchValue({setPassword: null, guestPassword: null})
        }
        this.finalizeCheckoutForm.updateValueAndValidity()
      }, 100)
  }

  setGuestType() {
    const guestType = this.userTypeForm.get('type')?.value
    this.sessionStorage.setGuestType(guestType)
    if (guestType === 'author') {
      this.onAuthenticationRequired()
    }
    this.showLoginOrGuest = false
  }

  resetFilter(form: FormGroup) {
    form.controls['countryFilter'].setValue('');
  }

  onAuthenticationRequired() {
    this.oAuthService.fetchOAuthRequiredImprints().subscribe(
      (imprintList) => {
        if (imprintList.length > 0) {
          const waitFor: any = []
          let index = 0
          imprintList.forEach(
            (entry: OAuth) => {
              if (entry.oauthUrl) {
                waitFor.push(this.oAuthService.authorize(entry.oauthUrl))
                index++
              }
            }
          )
          forkJoin(...waitFor).subscribe(
            (responses: any) => {
              console.dir(responses)
              if (responses.length > 0) {
                this.imprints = []
                this.imprints = responses.map(
                  (response: any) => response.body
                )
                for (let i = 0; i < index; i++) {
                  this.imprints[i].matchcode = imprintList[i].mandator
                  this.imprints[i].authorize_url = imprintList[i].oauthUrl
                }
                this.showVDMOAuthLogin()
              }
            }
          )
        }
      }
    )

  }

  onSubmit() {

    this.submittingOrder = true

    const data = {
      step1: this.contactForm.value,
      step2: this.billingAddressForm.value,
      step3: this.shippingAddressForm.value,
      step4: this.deliveryForm.value,
      step5: this.paymentForm.value,
      step6: this.finalizeCheckoutForm.value,
      additional: {
        currency: this.currencyObserver.currentCurrency,
        locale: this.localeObserver.currentLocale
      }
    }

    const base64Data = Base64.encode(JSON.stringify(data));

    this.checkoutService.performCheckout(base64Data).subscribe(
      (order) => {
        if (order.builderhash) {
          this.router.navigateByUrl(`/shop/order/${ order.builderhash }/${ order.paymentProvider }/${ order.paymentModule }`)
          this.submittingOrder = false
        }
      }, (error) => {
        this.submittingOrder = false
      }
    )
  }

  showVDMOAuthLogin() {
    console.dir(this.imprints)
    if (this.imprints.length > 0) {
      this.bottomSheet.open(OAuthLoginFormComponent, {
        data: {imprints: this.imprints},
        closeOnNavigation: true,
        disableClose: true
      });
    }
  }

  deliveryOption(): DeliveryOption | undefined {
    return this.deliveryForm.get('deliveryOption')?.value
  }

  addVoucher() {
    // Process voucher data here
    const voucher = new Voucher()
    const voucherCodeField = this.voucherForm.get('voucherCode')
    voucher.code = voucherCodeField?.value
    this.cartService.addVoucherToCart(voucher).subscribe(
      (cart) => {
        this.cartObserver.updateCart(cart)
        this.voucherForm.reset()
        voucherCodeField?.markAsUntouched()
        voucherCodeField?.markAsPristine()
        voucherCodeField?.setErrors(null)
        this.translate.get('cart.updated').subscribe(
          (val) => this.snackBar.open(val, 'OK', this.snackBarSettings)
        )
      },
      (error) => {
        this.translate.get('cart.product_not_added').subscribe(
          (val) => this.snackBar.open(val, 'OK', this.snackBarSettings)
        )
      }
    )
  }

  setDeliveryOptionIfSelected() {
    const deliveryOption = this.deliveryOption()
    if (deliveryOption) {
      this.cartObserver.cart.deliveryOption = deliveryOption
    }
  }

  login() {
    this.currentUser = new User()
    this.currentUser.username = this.loginForm.get('username')?.value
    this.currentUser.password = this.loginForm.get('password')?.value

    this.loginService.doLogin(this.currentUser, this.localeObserver.currentLocale).subscribe(
      (user) => {
        if (user) {
          this.currentUser = user
          this.userObserver.setUser(user)
          this.showLoginOrGuest = false
          this._preselectContactData()
        } else {
          this.showLoginOrGuest = true
          this.userObserver.setUser(undefined)
        }
      }
    )
  }

  logout() {
    if (this.currentUser && this.currentUser.persistenceToken) {
      this.loginService.doLogout(this.currentUser.persistenceToken).subscribe(
        (user) => {
          this.currentUser = undefined
          this.showLoginOrGuest = true
          this.userObserver.resetUser()
        }
      )
    }
  }

  resetUserAndType() {
    this.showLoginOrGuest = true
  }

  private _preselectCountry(form: FormGroup, cntry: Country) {
    const value = {
      country: cntry
    }
    form.patchValue(value)
  }

  private _preselectContactData() {
    const value = {
      email: this.currentUser?.email
    }
    this.contactForm.patchValue(value)
  }

  private _preselectDeliveryOption(deliveryOption: DeliveryOption) {
    const value = {
      deliveryOption: deliveryOption
    }

    this.deliveryForm.setValue(value)
  }

  private _setupCountryFilterChangeEvent(form: FormGroup): undefined | Observable<Country[]> {
    return form.get('countryFilter')?.valueChanges?.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.printableName)),
      map((country: any) => {
        if (country !== '') {
          return this._filterCountries(country)
        } else {
          return this.countries.slice()
        }
      }),
      catchError(err => {
        console.log('ERROR', err)
        return of([])
      })
    )
  }

  private _filterCountries(value: string): Country[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter(country => {
      const matches = country.synonyms.filter(synonym => {
        return synonym.toLowerCase().includes(filterValue)
      })
      return matches.length > 0
    });
  }

}

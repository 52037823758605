import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentComponent } from './components/document/document.component';
import { OrderComponent } from './components/shop/order/order.component';
import { PaymentComponent } from './components/shop/payment/payment.component';
import { ShopComponent } from './components/shop/shop.component';
import { CheckoutComponent } from './components/shop/checkout/checkout.component';
import { UserAreaComponent } from './components/user_area/user_area.component'
import { WebpageComponent } from './components/webpage/webpage.component';
import { LoggedInGuard } from './setup/logged_in.guard'

const routes: Routes = [
  {path: '', redirectTo: '/shop', pathMatch: 'full'},
  {path: 'shop', component: ShopComponent},
  {path: 'shop/category/:categoryId', component: ShopComponent},
  {path: 'shop/category/:categoryId/:page', component: ShopComponent},
  {path: 'shop/search', component: ShopComponent},
  {path: 'shop/extended-search', component: ShopComponent},
  {path: 'shop/product/:ean', component: ShopComponent},
  {path: 'shop/translation-bundle/:code', component: ShopComponent},
  {path: 'shop/book-launch-offer/:token', component: ShopComponent},
  {path: 'shop/checkout', component: CheckoutComponent},
  {path: 'shop/order/:builderhash/:provider/:type', component: OrderComponent},
  {path: 'shop/payment/:builderhash/:status', component: PaymentComponent},
  {path: 'user_area', component: UserAreaComponent},
  {path: 'user_area/my_account', component: UserAreaComponent, canActivate: [LoggedInGuard]},
  {path: 'user_area/my_books', component: UserAreaComponent, canActivate: [LoggedInGuard]},
  {path: 'user_area/my_orders', component: UserAreaComponent, canActivate: [LoggedInGuard]},
  {path: 'webpage/:slug/:id', component: WebpageComponent},
  {path: 'document/:slug/:id', component: DocumentComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

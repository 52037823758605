import { CurrencyPipe } from '@angular/common'
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AdyenComponent } from './components/payment_providers/adyen.component';
import { PagBrasilComponent } from './components/payment_providers/pag_brasil.component';
import { WireTransferComponent } from './components/payment_providers/wire_transfer.component';
import { BookLaunchOfferV2Component } from './components/shop/book-launch-offer/book-launch-offer-v2.component'
import {
  BookLaunchOfferItemV2Component
} from './components/shop/book-launch-offer/v2/book-launch-offer-item/book-launch-offer-item-v2.component'
import {
  BookLaunchOfferDefaultItemComponent
} from './components/shop/book-launch-offer/v3/book-launch-offer-default-item/book-launch-offer-default-item.component'
import {
  BookLaunchOfferItemDetailsComponent
} from './components/shop/book-launch-offer/v3/book-launch-offer-item-details/book-launch-offer-item-details.component'
import { CheckoutComponent } from './components/shop/checkout/checkout.component';
import { LoginComponent } from './components/shop/login/login.component'
import { OAuthLoginFormComponent } from './components/shop/oauth/oauth_login_form.component';
import { OrderComponent } from './components/shop/order/order.component';
import { PaymentComponent } from './components/shop/payment/payment.component';
import { PromotionComponent } from './components/shop/promotion/promotion.component'
import { ExtendedSearchComponent } from './components/shop/search/extended_search.component';
import { MyAccountComponent } from './components/user_area/my_account/my_account.component'
import { ConfirmationDialogComponent } from './components/user_area/my_books/confirmation_dialog.component'
import { MyBooksComponent } from './components/user_area/my_books/my_books.component'
import { MyOrdersComponent } from './components/user_area/my_orders/my_orders.component'
import { UserAreaComponent } from './components/user_area/user_area.component'
import { PromotionObserver } from './observers/promotion.observer'
import { UserObserver } from './observers/user.observer';
import { AccountBookService } from './services/account_book.service'
import { CategoryService } from './services/category.service';
import { ShopComponent } from './components/shop/shop.component';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { ContentService } from './services/content.service';
import { DocumentService } from './services/document.service';
import { OAuthService } from './services/oauth.service';
import { OrderService } from './services/order.service';
import { CountryService } from './services/country.service';
import { DeliveryOptionService } from './services/delivery_option.service';
import { LoginService } from './services/login.service';
import { PaymentMethodService } from './services/payment_method.service';
import { PaymentPayloadService } from './services/payment_payload.service';
import { ProductService } from './services/product.service';
import { MatButtonModule } from '@angular/material/button';
import { ProductsComponent } from './components/shop/products/products.component';
import { ProductComponent } from './components/shop/product/product.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CategoriesComponent } from './components/shop/categories/categories.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BookCoverComponent } from './components/shop/book-cover/book-cover.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SearchComponent } from './components/shop/search/search.component';
import { CartComponent } from './components/shop/cart/cart.component';
import { environment } from '../environments/environment';
import { SearchObserver } from './observers/search.observer';
import { CartObserver } from './observers/cart.observer';
import { CategoryObserver } from './observers/category.observer';
import { CurrencyObserver } from './observers/currency.observer';
import { BookLaunchOfferV3Component } from './components/shop/book-launch-offer/book-launch-offer-v3.component';
import { TranslationBundleComponent } from './components/shop/translation-bundle/translation-bundle.component';
import { CurrencyService } from './services/currency.service';
import { LocaleService } from './services/locale.service';
import { LocaleObserver } from './observers/locale.observer';
import { ProductsFilterComponent } from './components/shop/products-filter/products-filter.component';
import { BookLanguageObserver } from './observers/book_language.observer';
import { CartService } from './services/cart.service';
import { PublisherService } from './services/publisher.service';
import { UserService } from './services/user.service'
import { LoggedInGuard } from './setup/logged_in.guard'
import { SessionStorage } from './storage/session.storage';
import { RequestInterceptor } from './setup/request.interceptor';
import { CartItemComponent } from './components/shop/cart-item/cart-item.component';
import { CartItemService } from './services/cart_item.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { VoucherService } from './services/voucher.service';
import { TranslationBundleService } from './services/vouchers/translation_bundle.service';
import { CountdownComponent } from './components/countdown/countdown.component';
import {
  TranslationBundleItemComponent
} from './components/shop/translation-bundle-item/translation-bundle-item.component';
import { LanguageService } from './services/language.service';
import { FlagComponent } from './components/language/flag.component';
import { PriceComponent } from './components/price/price.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BookLaunchOfferService } from './services/book_launch_offer.service';
import {
  BookLaunchOfferItemComponent
} from './components/shop/book-launch-offer/v3/book-launch-offer-item/book-launch-offer-item.component';
import { LayoutObserver } from './observers/layout.observer';
import { WebpageComponent } from './components/webpage/webpage.component';
import { FooterComponent } from './components/footer/footer.component';
import { DocumentComponent } from './components/document/document.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

@NgModule({
  declarations: [
    AppComponent,
    AdyenComponent,
    PagBrasilComponent,
    WireTransferComponent,
    BookLaunchOfferV2Component,
    BookLaunchOfferItemV2Component,
    BookLaunchOfferV3Component,
    BookLaunchOfferItemComponent,
    BookLaunchOfferItemDetailsComponent,
    BookLaunchOfferDefaultItemComponent,
    BookCoverComponent,
    CategoriesComponent,
    ConfirmationDialogComponent,
    CartComponent,
    CartItemComponent,
    CheckoutComponent,
    CountdownComponent,
    FlagComponent,
    OAuthLoginFormComponent,
    OrderComponent,
    PaymentComponent,
    PriceComponent,
    ProductComponent,
    ProductsComponent,
    ProductsFilterComponent,
    SearchComponent,
    ExtendedSearchComponent,
    ShopComponent,
    TranslationBundleComponent,
    TranslationBundleItemComponent,
    WebpageComponent,
    FooterComponent,
    DocumentComponent,
    LoginComponent,
    PromotionComponent,
    UserAreaComponent,
    MyAccountComponent,
    MyBooksComponent,
    MyOrdersComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatBottomSheetModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule,
    MatTableModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    PaginationModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatPasswordStrengthModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    CurrencyPipe,
    HttpClient,
    RequestInterceptor,
    SessionStorage,
    LoggedInGuard,
    AccountBookService,
    BookLaunchOfferService,
    CategoryService,
    CartService,
    CartItemService,
    ContentService,
    DocumentService,
    OrderService,
    CountryService,
    CurrencyService,
    DeliveryOptionService,
    LanguageService,
    LocaleService,
    LoginService,
    VoucherService,
    OAuthService,
    PaymentMethodService,
    PaymentPayloadService,
    PublisherService,
    ProductService,
    TranslationBundleService,
    UserService,
    BookLanguageObserver,
    CartObserver,
    CurrencyObserver,
    CategoryObserver,
    LayoutObserver,
    LocaleObserver,
    SearchObserver,
    PromotionObserver,
    UserObserver,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.i18n, '.json');
}

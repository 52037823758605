<div class="categories-list">
  <mat-selection-list #categoriesList [multiple]="false" class="categories-list" *ngIf="!category">
    <mat-list-option *ngFor="let cat of categories" [value]="cat" (click)="selectCategory(cat)">
      {{ cat.getName() }} ({{ cat.productCount }})
    </mat-list-option>
  </mat-selection-list>

  <mat-selection-list #categoriesList [multiple]="false" class="categories-list" *ngIf="category">
    <mat-list-option (click)="goBackToRoot()">
      <div>{{ 'back_to' | translate }} <mat-icon class="home-icon">home</mat-icon></div>
    </mat-list-option>
    <mat-list-option *ngFor="let cat of category.ancestors" [value]="cat" (click)="selectCategory(cat)">
      {{ 'back_to' | translate }} {{ cat.getName() }}
    </mat-list-option>
    <mat-list-option [value]="category" (click)="selectCategory(category)">
      <strong>{{ category.getName() }} ({{ category.productCount }})</strong>
    </mat-list-option>
    <mat-list-option *ngFor="let cat of category.children" [value]="cat" (click)="selectCategory(cat)">
      {{ cat.getName() }} ({{ cat.productCount }})
    </mat-list-option>
  </mat-selection-list>
</div>

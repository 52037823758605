import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Order } from "../../models/order.model";
import { PaymentPayloadService } from "../../services/payment_payload.service";

@Component({
  selector: 'app-pag-brasil',
  templateUrl: './pag_brasil.component.html',
  styleUrls: ['./pag_brasil.component.scss']
})
export class PagBrasilComponent implements OnInit, OnChanges {
  @Input('order') order: Order

  redirectUrl: string
  payload: { redirect_url: string; error?: string }

  constructor(
    private paymentPayloadService: PaymentPayloadService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.order && this.order.builderhash) {
      this.paymentPayloadService.fetchPayload('PagBrasil', this.order.builderhash).subscribe(
        async (payload) => {
          this.payload = payload.unpack()
          this.redirectUrl = this.payload.redirect_url
        }
      )
    }
  }

}

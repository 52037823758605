import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatMenu } from '@angular/material/menu'
import { MatTooltip } from '@angular/material/tooltip'
import { TranslateService } from '@ngx-translate/core'
import { LoginComponent } from './components/shop/login/login.component'
import { PromotionComponent } from './components/shop/promotion/promotion.component'
import { Locale } from './models/locale.model'
import { User } from './models/user.model'
import { PromotionObserver } from './observers/promotion.observer'
import { SearchObserver } from './observers/search.observer'
import { CartObserver } from './observers/cart.observer'
import { Currency } from './models/currency.model'
import { CurrencyObserver } from './observers/currency.observer'
import { LocaleObserver } from './observers/locale.observer'
import { LayoutObserver } from './observers/layout.observer'

import { Router, Event as NavigationEvent, NavigationEnd, Route, ActivatedRoute, UrlSegment } from '@angular/router'
import { UserObserver } from './observers/user.observer'
import { LoginService } from './services/login.service'
import { SessionStorage } from './storage/session.storage'

@Component({
  selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnChanges {

  @ViewChild('tooltip') tooltip: MatTooltip
  @ViewChild('menu') menu: MatMenu

  darkModeToggle: FormControl

  searchBounce = false
  showPromotionModal = false

  user: User | undefined

  bottomSheetOpened = false

  urlBeforeSearch: string

  constructor(
    public translate: TranslateService,
    public searchObserver: SearchObserver,
    public cartObserver: CartObserver,
    public currencyObserver: CurrencyObserver,
    public layoutObserver: LayoutObserver,
    public localeObserver: LocaleObserver,
    public userObserver: UserObserver,
    public promotionObserver: PromotionObserver,
    private loginService: LoginService,
    private sessionStorage: SessionStorage,
    private route: ActivatedRoute,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
  ) {
    /* DO NOT DELETE */
    new Locale()
    new Currency()
    /* DO NOT DELETE END */

    this.router.events
      .subscribe((event: NavigationEvent) => {
        if (event instanceof NavigationEnd) {

          if (!this.searchObserver.searchVisible) {
            this.urlBeforeSearch = event.urlAfterRedirects
          }

          if (!event.urlAfterRedirects.match(/user_area/) && (event.urlAfterRedirects.match(/checkout/) || event.urlAfterRedirects.match(
            /order/) || event.urlAfterRedirects.match(/payment/))) {
            this.layoutObserver.showNav$.next(false)
          } else {
            this.layoutObserver.showNav$.next(true)
          }

          if (event.urlAfterRedirects.match(/checkout/) || event.urlAfterRedirects.match(/order/) || event.urlAfterRedirects.match(
            /payment/) || event.urlAfterRedirects.match(/translation-bundle/) || event.urlAfterRedirects.match(
            /book-launch-offer/)) {
            this.layoutObserver.showPromotionSheet$.next(false)
          } else {
            this.layoutObserver.showPromotionSheet$.next(true)
          }

        }
      })

    this.searchObserver.searchVisibilityObserver$.subscribe((visible) => {
      if (!visible && this.urlBeforeSearch) {
        this.router.navigateByUrl(this.urlBeforeSearch, { replaceUrl: true })
      }
    })

    this.userObserver.userChangedObserver$.subscribe((user) => {
      this.user = user
      if (this.user) {
        if (this.bottomSheetOpened && !this.showPromotionModal) {
          this._bottomSheet.dismiss()
        }
      }
    })

    this.localeObserver.availableLocalesObserver$.subscribe(() => {

      let localeStr: string | undefined | null = this.localeObserver.previousLocale
      if (!localeStr) {
        localeStr = (translate.getBrowserLang() || 'GB').toUpperCase()
      }
      const locale = localeObserver.availableLocales.find((loc) => {
        return loc.iso === localeStr?.toUpperCase()
      })

      if (!locale) {
        localeStr = localeObserver.availableLocales.find((loc) => {
          loc.default
        })?.iso || 'GB'
      }

      translate.setDefaultLang(localeStr)
      translate.use(localeStr)

    })

    this.searchObserver.searchResetObserver$.subscribe(() => {
      this.tooltip.show(1)
      this.searchBounce = true
      setTimeout(() => {
        this.searchBounce = false
      }, 1000)
    })

    this.cartObserver.cartItemCounterObserver$.subscribe(() => {
      if (cartObserver.cartItemCounter > 0) {
        this.cartObserver.bounceCartItem()
      }
    })

    this.promotionObserver.promotionVoucherObserver$.subscribe((res) => {
      this.showPromotionModal = res.length > 0 && this.layoutObserver.showPromotionSheet$.value

      if (this.showPromotionModal) {
        this.bottomSheetOpened = true

        const bottomSheetRef = this._bottomSheet.open(PromotionComponent, {
          ariaLabel: 'Promotion',
        })

        bottomSheetRef.afterDismissed().subscribe(() => {
          console.log('PromotionComponent destroyed')
          this.bottomSheetOpened = false
        })
      } else {
        if (this.bottomSheetOpened && this.showPromotionModal) {
          _bottomSheet.dismiss()
        }
      }
    })

  }

  ngOnInit(): void {
    const darkMode = this.sessionStorage.getDarkModeState()
    this.layoutObserver.darkMode$.next(darkMode)

    this.darkModeToggle = new FormControl(this.layoutObserver.darkMode$.value);
    this.darkModeToggle.valueChanges.subscribe(
      (val) => {
        this.sessionStorage.setDarkModeState(val)
        this.layoutObserver.darkMode$.next(val)
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  switchLocale(lang: string | undefined) {
    if (lang) {
      localStorage.setItem('locale', lang)
      this.translate.use(lang)
    }
  }

  toggleSearchBar() {
    this.searchObserver.toggleSearchBar()
    if (this.searchObserver.searchVisible) {
      this.router.navigate(['/shop/search'])
    }
  }

  openLogin() {
    this.bottomSheetOpened = true

    const bottomSheetRef = this._bottomSheet.open(LoginComponent, {
      data: { login: {} }, ariaLabel: 'Login',
    })

    bottomSheetRef.afterDismissed().subscribe(() => {
      this.bottomSheetOpened = false
    })
  }

  logout() {
    if (this.user && this.user.persistenceToken) {
      this.loginService.doLogout(this.user.persistenceToken).subscribe((user) => {
        this.user = undefined
        this.userObserver.resetUser()
      })
    }
  }

  stopImpersonating() {
    this.userObserver.resetUser()
  }

  getFlag(iso: string) {
    return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${ iso }.svg`
  }

}

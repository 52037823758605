import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CartItem } from "../../../models/cart_item.model";
import { CartItemService } from "../../../services/cart_item.service";
import { CartObserver } from "../../../observers/cart.observer";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";
import { LanguageService } from "../../../services/language.service";
import { CurrencyObserver } from "../../../observers/currency.observer";

@Component({
  selector: 'app-translation-bundle-item',
  templateUrl: './translation-bundle-item.component.html',
  styleUrls: ['./translation-bundle-item.component.scss']
})
export class TranslationBundleItemComponent implements OnInit {

  @ViewChild('amountElement') amountElement: HTMLInputElement

  @Input() item: CartItem | undefined
  @Input() disabled: boolean = false

  @Output() updateAmount: EventEmitter<CartItem> = new EventEmitter<CartItem>();

  amount: FormControl;

  constructor(
    public currencyObserver: CurrencyObserver,
    public languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.amount = new FormControl(this.Amount?.toString());
  }

  get Amount(): number | undefined {
    return this.item?.amount
  }

  set Amount(val: number | undefined) {
    if (this.item && val && val > 0) {
      this.item.amount = val
      this.amount.setValue(val)
      this.updateAmount.emit(this.item)
    }
  }

  selectText() {
    this.amountElement.focus()
    this.amountElement.select()
  }

  updateIt(event: KeyboardEvent) {
    this.update()
  }

  update(val = 0) {
    if (this.disabled) {
      return
    }
    this.Amount = +this.amount.value + val;
  }

}

import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { GraphqlService } from 'scf-graphql-model'
import { environment } from '../../environments/environment'
import { Country } from '../models/country.model'
import { HttpClient } from '@angular/common/http'

import countriesQuery from 'src/app/graphql/queries/countries.query.graphql'

@Injectable()
export class CountryService extends GraphqlService<Country> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  deliverable(): Observable<Country[]> {
    return this.findAll(countriesQuery)
  }

}

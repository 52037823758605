import { Injectable } from '@angular/core'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { CartItem } from '../models/cart_item.model'

import updateCartItemMutation from 'src/app/graphql/mutations/update_cart_item.mutation.graphql'
import removeCartItemMutation from 'src/app/graphql/mutations/remove_cart_item.mutation.graphql'

@Injectable()
export class CartItemService extends GraphqlService<CartItem> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  changeAmount(item: CartItem) {
    return this.push(updateCartItemMutation, { id: item.id, amount: item.amount })
  }

  removeCartItem(item: CartItem) {
    return this.push(removeCartItemMutation, { id: item.id })
  }

}

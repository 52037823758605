import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserObserver } from '../observers/user.observer'

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private userObserver: UserObserver, private router: Router) {

  }

  canActivate() {
    return this.userObserver.userChangedObserver$.pipe(
      map((user) => {
          if (!user) {
            console.log('user is not logged in')
            // this.router.navigate(['/shop']);
          }
          return true
        }
      )
    )
  }
}

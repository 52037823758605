import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { UserObserver } from "../observers/user.observer";
import { LoginService } from "../services/login.service";
import { SessionStorage } from "../storage/session.storage";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Session } from "../models/session.model";

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private sessionStorage: SessionStorage,
    private loginService: LoginService,
    private userObserver: UserObserver
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const sessionID = this.sessionStorage.getSessionID()

    let intercept = false
    let request
    let sessionData = this.sessionStorage.getSessionData()

    const url = new URL(req.url);

    environment.interceptedHosts.forEach(
      (host) => {
        if (url.hostname.indexOf(host) > -1) {
          intercept = true
        }
      }
    )

    if (intercept && typeof sessionID === 'string' && typeof sessionData === 'string') {

      sessionData = sessionData.split("\n").join('')

      const reqClone: HttpRequest<any> = req.clone({
          headers: new HttpHeaders({
            'Morebooks-Session-ID': sessionID,
            'Morebooks-Session-Data': sessionData
          })
        }
      )
      request = reqClone
    } else {
      request = req
    }

    return next.handle(request).pipe(
      map((event) => {

          if (intercept && event instanceof HttpResponse) {
            const session = new Session()
            const sessionID = event.headers.get('Morebooks-Session-ID')
            const sessionData = (event.headers.get('Morebooks-Session-Data') || '').split(', ').join("")
            const unencryptedSessionData = (event.headers.get('Morebooks-Session-Data-Unencrypted') || '').split(', ').join("")
            const publicKey = (event.headers.get('Morebooks-Public-Key') || '').split(', ').join("")

            if (sessionID && sessionData && publicKey && unencryptedSessionData) {
              session.token = sessionID
              session.data = sessionData
              session.unencryptedData = unencryptedSessionData
              session.publicKey = publicKey

              this.sessionStorage.setSession(session)

              const persistenceToken = JSON.parse(unencryptedSessionData)['persistence_token']
              const persistenceTokenFromSession = this.sessionStorage.getPersistenceToken()

              if (persistenceToken !== persistenceTokenFromSession) {
                this.loginService.checkLogin(persistenceToken).subscribe(
                  (user) => {
                    this.userObserver.setUser(user)
                  }
                )
              } else {
                const user = this.sessionStorage.getUser()
                this.userObserver.setUser(user)
              }
            }

          }

          return event

        }
      )
    )

  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'
import { PaymentPayload } from '../models/payment_payload.model';
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import paymentPayloadQuery from 'src/app/graphql/queries/payment_payload.query.graphql'

@Injectable()
export class PaymentPayloadService extends GraphqlService<PaymentPayload> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  fetchPayload(provider: string, builderhash: string): Observable<PaymentPayload> {
    return this.push(paymentPayloadQuery, { provider, builderhash })
  }

}


    
    var doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DeliveryOptions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"iso"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deliveryOptions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"country"},"value":{"kind":"Variable","name":{"kind":"Name","value":"iso"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fromKg"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"toKg"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"deliveryMethod"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showLocalCustomsNote"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dutyFeeMessages"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mobilePhoneNumberRequired"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currencyPrices"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"exchangeRate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"netPrice"},"arguments":[],"directives":[]}]}}]}}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  

    module.exports = doc
  

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { Country } from "./country.model";

@Model('addresses', ['address', 'billingAddress', 'shippingAddress'])
export class Address extends BaseModel {
  @Column()
  id: number | undefined;
  country: Country
  company: string
  firstname: string
  surname: string
  street: string
  postalCode: string
  city: string
  additional: string
}

<ng-template #content>

  <table *ngIf="cartObserver.cart">
    <colgroup>
      <col style="width: 50px"/>
      <col/>
      <col style="width: 90px"/>
      <col style="width: 90px"/>
      <col style="width: 90px"/>
    </colgroup>
    <thead>
    <tr>
      <th class="dynamic"></th>
      <th class="dynamic">{{ 'product.title' | translate }}</th>
      <th class="right-aligned">{{ 'cart.unit_price' | translate }}</th>
      <th class="right-aligned">{{ 'cart.discount' | translate }}</th>
      <th class="right-aligned">{{ 'cart.amount' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of cartObserver?.cart?.productItems()">
      <td>
        <app-book-cover [imgSrc]="item?.product?.imageURL('cover')"></app-book-cover>
      </td>
      <td>
        {{ item?.amount }} x  {{ item?.product?.productBook?.isbn }}<br/>
        {{ item?.product?.productBook?.title }}
      </td>
      <td class="right-aligned">
        {{ item?.defaultPrice(currencyObserver.currentCurrency)  | currency:currencyObserver.currentCurrency }}
      </td>
      <td class="right-aligned" [title]="item.discount?.title">
        {{ item?.totalDiscount(currencyObserver.currentCurrency)  | currency:currencyObserver.currentCurrency }}
      </td>
      <td
        class="right-aligned">{{ item?.totalDiscountedCurrencyPrice(currencyObserver.currentCurrency)  | currency:currencyObserver.currentCurrency }}</td>
    </tr>
    </tbody>
    <tbody *ngIf="cartObserver.cart.deliveryOption">
    <tr>
      <td colspan="2"
          class="right-aligned">{{ 'checkout.shipping_costs' | translate }} {{ cartObserver.cart.deliveryOption.deliveryMethod }}
        :
      </td>
      <td colspan="3" class="right-aligned total">
        {{ cartObserver.cart.deliveryOption?.currencyPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}</td>
    </tr>
    <tr *ngIf="cartObserver.cart.deliveryOption.showLocalCustomsNote">
      <td colspan="5">
        <strong>{{ 'cart.local_customs_note' | translate }}</strong>
      </td>
    </tr>
    </tbody>
    <tbody>
    <tr *ngFor="let item of cartObserver?.cart?.voucherItems()">
      <td></td>
      <td>
        {{ 'voucher.voucher' | translate }}: {{ item.voucher?.code }}
      </td>
      <td class="right-aligned">
        {{ 0 | currency:currencyObserver.currentCurrency }}
      </td>
      <td class="right-aligned">
        {{ item.voucher?.availableAmount(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}
      </td>
      <td class="right-aligned">
        {{ item.voucher?.availableAmount(currencyObserver.currentCurrency, true) | currency:currencyObserver.currentCurrency }}
      </td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="2" class="right-aligned">{{ 'cart.amount' | translate }}:</td>
      <td colspan="3"
          class="right-aligned total">{{ cartObserver.cart.priceTotalDiscounted(currencyObserver.currentCurrency, true)  | currency:currencyObserver.currentCurrency }}</td>
    </tr>
    </tfoot>
  </table>

  <form [formGroup]="voucherForm" (ngSubmit)="addVoucher()">

    <div>
      <mat-form-field appearance="standard">
        <mat-label>{{ 'checkout.voucher_code' | translate }}</mat-label>
        <input id="voucher" type="text" formControlName="voucherCode" matInput required>
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary" [disabled]="!voucherForm.valid"
            type="submit">{{ 'checkout.redeem_voucher' | translate }}</button>

  </form>
</ng-template>

<div class="pre-checkout-container" *ngIf="showLoginOrGuest">

  <h1>{{ 'login_register_or_checkout_as_guest' | translate }}</h1>

  <div class="login-or-register-container">

    <div class="guest" *ngIf="!currentUser?.loggedIn()">

      <h2>{{ 'login_as_guest' | translate }}</h2>

      <form [formGroup]="userTypeForm">
        <mat-button-toggle-group vertical="true" [formControlName]="'type'" required>
          <mat-button-toggle [value]="'dealer'" (click)="setGuestType()">
            <mat-icon>business</mat-icon>
            {{ 'checkout.dealer' | translate }}
          </mat-button-toggle>
          <mat-button-toggle [value]="'author'" (click)="setGuestType()">
            <mat-icon>mode</mat-icon>
            {{ 'checkout.author' | translate }}
          </mat-button-toggle>
          <mat-button-toggle [value]="'customer'" (click)="setGuestType()">
            <mat-icon>person</mat-icon>
            {{ 'checkout.customer' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </form>
    </div>

    <div class="login">
      <h2 *ngIf="!currentUser?.loggedIn()">{{ 'login' | translate }}</h2>
      <h2 *ngIf="currentUser?.loggedIn()">{{ 'registered_as' | translate }}</h2>

      <form [formGroup]="loginForm" *ngIf="!currentUser?.loggedIn()">
        <div>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'checkout.username' | translate }}</mat-label>
            <input type="text" formControlName="username" matInput required>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'checkout.password' | translate }}</mat-label>
            <input type="password" formControlName="password" matInput required>
          </mat-form-field>
        </div>

        <button mat-raised-button color="primary" (click)="login()">
          Login
        </button>

      </form>

      <div *ngIf="currentUser?.loggedIn()">
        <div style="margin-bottom: 20px">{{ currentUser?.name }}</div>

        <button mat-raised-button color="primary" (click)="logout()">
          Logout
        </button>

      </div>

    </div>

  </div>

</div>

<div class="checkout-container"
     *ngIf="!showLoginOrGuest && (userTypeForm?.valid || currentUser?.loggedIn()) && cartObserver?.cart">
  <div class="left">
    <div class="content">

      <a [routerLink]="['/']" class="navbar-brand" href="#">
        <img class="logo" src="https://www.morebooks.shop/assets/img/logo.png"/>
      </a>

      <div *ngIf="currentUser?.loggedIn()">
        <strong>{{ 'registered_as' | translate }}</strong> {{ currentUser?.name }}
        <button mat-button (click)="logout()" type="button">{{ 'change' | translate }}</button>
      </div>

      <div *ngIf="!currentUser?.loggedIn()">
        <strong>{{ 'login_register_or_checkout_as_guest' | translate }}</strong><br/>
        {{ 'account.type' | translate }}:
        <span *ngIf="userTypeForm.get('type')?.value === 'dealer'">
          {{ 'checkout.dealer' | translate }}
        </span>
        <span *ngIf="userTypeForm.get('type')?.value === 'author'">
          {{ 'checkout.author' | translate }}
        </span>
        <span *ngIf="userTypeForm.get('type')?.value === 'customer'">
          {{ 'checkout.customer' | translate }}
        </span>
        <button mat-button (click)="resetUserAndType()" type="button">{{ 'change' | translate }}</button>
        {{ 'or_condition' | translate }}
        <button mat-button (click)="resetUserAndType()" type="button">Login</button>
      </div>

      <div class="mobile">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'cart.list_products' | translate }}
              </mat-panel-title>
              <mat-panel-description>
                {{ cartObserver.cart.priceTotal(currencyObserver.currentCurrency)| currency:currencyObserver.currentCurrency }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngTemplateOutlet="content"></ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <mat-stepper orientation="vertical" [linear]="true" *ngIf="(userTypeForm?.valid || currentUser?.loggedIn())">

        <mat-step [stepControl]="contactForm">
          <ng-template matStepLabel>{{ 'checkout.contact' | translate }}</ng-template>
          <form [formGroup]="contactForm">
            <div *ngIf="!currentUser?.loggedIn()">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.email' | translate }}</mat-label>
                <input id="email" type="email" formControlName="email" matInput email required>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.phone' | translate }}</mat-label>
                <input id="phone" type="phone" formControlName="phone" matInput required>
              </mat-form-field>
            </div>


            <div class="btn-container">
              <button mat-raised-button matStepperNext type="button">{{ 'next' | translate }}</button>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="billingAddressForm">
          <ng-template matStepLabel>{{ 'checkout.billing_address' | translate }}</ng-template>

          <form [formGroup]="billingAddressForm">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.country' | translate }}</mat-label>

                <mat-select formControlName="country" class="filtered" (closed)="resetFilter(billingAddressForm)"
                            (opened)="billingCountryFilter.select()" (selectionChange)="onCountrySelected()" required>

                  <input type="text" class="filter" #billingCountryFilter
                         [placeholder]="'checkout.country' | translate"
                         formControlName="countryFilter" matInput>
                  <mat-option *ngFor="let country of filteredCountries | async"
                              [value]="country">
                    {{ country.printableName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="billingAddressForm.get('country')?.value?.americas">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.tax' | translate }}</mat-label>
                <input type="text" formControlName="tax" matInput required>
                <mat-hint>{{ 'checkout.tax_hint' | translate }}</mat-hint>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.company' | translate }}</mat-label>
                <input type="text" formControlName="company" matInput
                       [required]="userTypeForm.get('type')?.value === 'dealer'">
              </mat-form-field>
            </div>

            <div
              *ngIf="billingAddressForm.get('company')?.value && billingAddressForm.get('country')?.value?.eu && userTypeForm.get('type')?.value === 'dealer'">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.ustid_nr' | translate }}</mat-label>
                <input type="text" formControlName="ustid"
                       [pattern]="billingAddressForm.get('country')?.value?.ustidPattern"
                       required matInput>
              </mat-form-field>
            </div>

            <div class="two-rows">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.firstname' | translate }}</mat-label>
                <input type="text" formControlName="firstname" matInput required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.surname' | translate }}</mat-label>
                <input type="text" formControlName="surname" matInput required>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.street' | translate }}</mat-label>
                <input type="text" formControlName="street" matInput required>
              </mat-form-field>
            </div>

            <div class="two-rows">
              <mat-form-field appearance="standard" class="postal_code">
                <mat-label>{{ 'checkout.postal_code' | translate }}</mat-label>
                <input type="text" formControlName="postalCode" matInput required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.city' | translate }}</mat-label>
                <input type="text" formControlName="city" matInput required>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.additional' | translate }}</mat-label>
                <input type="text" formControlName="additional" matInput>
              </mat-form-field>
            </div>

            <div>
              <mat-slide-toggle
                formControlName="hasShippingAddress">{{ 'checkout.different_shipping_address' | translate }}</mat-slide-toggle>
            </div>

            <div class="btn-container">
              <button mat-raised-button matStepperNext type="button">{{ 'next' | translate }}</button>
              <button mat-raised-button matStepperPrevious
                      type="button">{{ 'previous' | translate }}</button>
            </div>

          </form>
        </mat-step>


        <mat-step [stepControl]="shippingAddressForm" *ngIf="billingAddressForm.get('hasShippingAddress')?.value">
          <ng-template matStepLabel>{{ 'checkout.shipping_address' | translate }}</ng-template>

          <form [formGroup]="shippingAddressForm">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.country' | translate }}</mat-label>

                <mat-select formControlName="country" (closed)="resetFilter(shippingAddressForm)"
                            (opened)="shippingCountryFilter.select()" (selectionChange)="onCountrySelected()" required>
                  <input type="text" class="filter" #shippingCountryFilter
                         [placeholder]="'checkout.country' | translate"
                         formControlName="countryFilter" matInput>
                  <mat-option *ngFor="let country of filteredCountries2 | async"
                              [value]="country">
                    {{ country.printableName }}
                  </mat-option>
                </mat-select>

              </mat-form-field>
            </div>

            <div *ngIf="shippingAddressForm.get('country')?.value?.americas">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.tax' | translate }}</mat-label>
                <input type="text" formControlName="tax" matInput required>
                <mat-hint>{{ 'checkout.tax_hint' | translate }}</mat-hint>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.company' | translate }}</mat-label>
                <input type="text" formControlName="company" matInput>
              </mat-form-field>
            </div>

            <div class="two-rows">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.firstname' | translate }}</mat-label>
                <input type="text" formControlName="firstname" matInput required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.surname' | translate }}</mat-label>
                <input type="text" formControlName="surname" matInput required>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.street' | translate }}</mat-label>
                <input type="text" formControlName="street" matInput required>
              </mat-form-field>
            </div>

            <div class="two-rows">
              <mat-form-field appearance="standard" class="postal_code">
                <mat-label>{{ 'checkout.postal_code' | translate }}</mat-label>
                <input type="text" formControlName="postalCode" matInput required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.city' | translate }}</mat-label>
                <input type="text" formControlName="city" matInput required>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>{{ 'checkout.additional' | translate }}</mat-label>
                <input type="text" formControlName="additional" matInput>
              </mat-form-field>
            </div>

            <div class="btn-container">
              <button mat-raised-button matStepperNext type="button">{{ 'next' | translate }}</button>
              <button mat-raised-button matStepperPrevious
                      type="button">{{ 'previous' | translate }}</button>
            </div>

          </form>
        </mat-step>

        <mat-step [stepControl]="deliveryForm">
          <ng-template matStepLabel>{{ 'checkout.delivery_options' | translate }}</ng-template>
          <form [formGroup]="deliveryForm">

            <div class="delivery-options">

              <strong
                *ngIf="deliveryCountry">
                {{ 'checkout.delivery_to' | translate:{country: deliveryCountry?.printableName} }}

                <span *ngIf="cartObserver.cart"> ({{ cartObserver.cart.weight }}kg)</span>

              </strong>

              <mat-progress-bar mode="query" *ngIf="!deliveryOptionSubscription?.closed"></mat-progress-bar>

              <mat-radio-group
                class="radio-group"
                (change)="onDeliveryOptionSelected()"
                formControlName="deliveryOption"
                required>

                <mat-radio-button class="hidden-radio"></mat-radio-button>

                <mat-radio-button *ngFor="let deliveryOption of deliveryOptions" [value]="deliveryOption">
                  {{ deliveryOption.deliveryMethod }}
                  <strong>{{ deliveryOption.currencyPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}</strong>
                </mat-radio-button>

              </mat-radio-group>

            </div>
            <div class="btn-container">
              <button mat-raised-button matStepperNext type="submit">{{ 'next' | translate }}</button>
              <button mat-raised-button matStepperPrevious
                      type="button">{{ 'previous' | translate }}</button>
            </div>

          </form>
        </mat-step>

        <mat-step [stepControl]="paymentForm">
          <ng-template matStepLabel>{{ 'checkout.payment_method' | translate }}</ng-template>

          <form [formGroup]="paymentForm">

            <div class="payment-methods">

              <mat-progress-bar mode="query" *ngIf="!paymentMethodSubscription?.closed"></mat-progress-bar>

              <mat-radio-group
                class="radio-group"
                formControlName="paymentMethod"
                required>

                <mat-radio-button class="hidden-radio" *ngIf="paymentMethods.length===0"></mat-radio-button>

                <ng-template let-paymentMethod="paymentMethod" #paymentRadio>
                  <mat-radio-button class="radio-button" [value]="paymentMethod.provider+'::'+paymentMethod.type"
                                    *ngIf="!paymentMethod.brands">
                    <img [src]="paymentMethod.logoUrl()" class="payment-logo"/>
                  </mat-radio-button>
                  <mat-radio-button class="radio-button" [value]="paymentMethod.provider+'::'+brand"
                                    *ngFor="let brand of paymentMethod.brands">
                    <img [src]="paymentMethod.logoUrl(brand)" class="payment-logo"/>
                  </mat-radio-button>
                </ng-template>
                <ng-container [ngTemplateOutlet]="paymentRadio"
                              [ngTemplateOutletContext]="{paymentMethod: paymentMethod}"
                              *ngFor="let paymentMethod of paymentMethods"></ng-container>

              </mat-radio-group>

            </div>

            <div class="btn-container">
              <button mat-raised-button matStepperNext type="submit">{{ 'next' | translate }}</button>
              <button mat-raised-button matStepperPrevious
                      type="button">{{ 'previous' | translate }}</button>
            </div>

          </form>
        </mat-step>

        <mat-step [stepControl]="finalizeCheckoutForm">

          <ng-template matStepLabel>{{ 'checkout.purchase' | translate }}</ng-template>

          <form [formGroup]="finalizeCheckoutForm" (ngSubmit)="onSubmit()">

            <div class="finalize-container">

              <mat-slide-toggle class="toggle"
                                formControlName="termsOfCondition" required>
                {{ 'terms_of_condition' | translate }}
              </mat-slide-toggle>

              <a mat-icon-button class="link" href="{{ 'terms_of_condition_url' | translate }}" target="_blank">
                <mat-icon>cloud_download</mat-icon>
              </a>

            </div>

            <div class="finalize-container">

              <mat-slide-toggle class="toggle"
                                formControlName="dataProtection" required>
                {{ 'privacy_policy' | translate }}
              </mat-slide-toggle>

              <a mat-icon-button class="link" href="{{ 'privacy_policy_url' | translate }}" target="_blank">
                <mat-icon>cloud_download</mat-icon>
              </a>

            </div>

            <div *ngIf="userTypeForm.get('type')?.value && !currentUser" id="guest-password-container">

              <hr/>

              <mat-slide-toggle formControlName="setPassword" (toggleChange)="onPasswordRequireToggled()">
                <span
                  *ngIf="finalizeCheckoutForm.get('setPassword')?.value === true">{{ 'checkout.register' | translate }}</span>
                <span
                  *ngIf="!finalizeCheckoutForm.get('setPassword')?.value">{{ 'checkout.do_not_register' | translate }}</span>
              </mat-slide-toggle>

              <mat-form-field appearance="standard" *ngIf="finalizeCheckoutForm.get('setPassword')?.value === true">
                <mat-label>{{ 'checkout.password' | translate }}</mat-label>
                <input type="text" formControlName="guestPassword" matInput>
              </mat-form-field>

            </div>

            <mat-form-field appearance="standard">
              <mat-label>{{ 'checkout.comment' | translate }}</mat-label>
              <textarea matInput
                        formControlName="comment"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>

            <button mat-raised-button color="primary" class="purchase-button"
                    [disabled]="finalizeCheckoutForm.invalid || submittingOrder"
                    type="submit">
              <span *ngIf="!submittingOrder">{{ 'checkout.confirm' | translate }}</span>
              <span *ngIf="submittingOrder">{{ 'order.payment_finished_text' | translate }}</span>
            </button>

          </form>

        </mat-step>
      </mat-stepper>

    </div>
  </div>
  <div class="right desktop">
    <div class="content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</div>

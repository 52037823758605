<div class="flex-container" [class.small]="hideImage">
  <div class="row" *ngIf="product">
    <div class="col-xxl-4 col-xl-4 col-md-5 col-12-xs height-50vh" *ngIf="!hideImage">
      <div class="product-image">
        <app-book-cover [imgSrc]="product.imageURL('cover')"></app-book-cover>
      </div>
    </div>
    <div
      [class.col-xxl-8]="!hideImage"
      [class.col-xl-8]="!hideImage"
      [class.col-md-7]="!hideImage"
      [class.col-12-xs]="!hideImage"
    >
      <h1 *ngIf="!hideTitle">{{ product.productBook?.title }}</h1>
      <h2 *ngIf="!hideSubtitle">{{ product.productBook?.subtitle}}</h2>
      <h3 *ngIf="!hideISBN">{{ product.productBook?.isbn }}</h3>
      <section *ngIf="!hideBlurb">
        {{ product.productBook?.blurb }}
      </section>

      <div class="row author-detail" *ngIf="!hideAuthor && product.productBook?.getAuthors()">
        <div class="col-sm-3"><strong>{{ 'product.authors' | translate }}</strong></div>
        <div class="col-sm-9">
          <div *ngFor="let author of product.productBook?.getAuthors()">
            <span class="author-name">{{ author.name }}</span>
            <span class="author-vita">{{ author.vita }}</span>
          </div>
        </div>
      </div>

      <div class="row editor-detail" *ngIf="product.productBook?.getEditors()">
        <div class="col-sm-3"><strong>{{ 'product.editors' | translate }}</strong></div>
        <div class="col-sm-9">
          <div *ngFor="let editor of product.productBook?.getEditors()">
            <span class="editor-name">{{ editor.name }}</span>
            <span class="editor-vita">{{ editor.vita }}</span>
          </div>
        </div>
      </div>

      <div class="row product-detail">
        <div class="col-sm-3"><strong>{{ 'product.book_language' | translate }}</strong></div>
        <div class="col-sm-9"
        >{{ bookLanguage(product.productBook?.language) }}</div>
      </div>

      <div class="row product-detail" *ngIf="!hidePublisher">
        <div class="col-sm-3"><strong>{{ 'product.published_at' | translate }}</strong></div>
        <div class="col-sm-9"
        >{{ product.productBook?.publishedAt | date:'YYYY-MM-dd' }}</div>
      </div>
      <div class="row product-detail" *ngIf="!hidePublisher">
        <div class="col-sm-3"><strong>{{ 'product.publishing_house' | translate }}</strong></div>
        <div class="col-sm-9">
          <a [href]="product.productBook?.publisher?.url">
            {{ product.productBook?.publisher?.name }}
          </a>
        </div>
      </div>
      <div class="row product-detail">
        <div class="col-sm-3"><strong>{{ 'product.numpages' | translate }}</strong></div>
        <div class="col-sm-9">{{ product.productBook?.numberOfPages }}</div>
      </div>
      <div class="row product-detail" *ngIf="!hidePrice && currencyObserver.currentCurrency !== 'EUR'">
        <div class="col-sm-3"><strong>{{ 'product.price' | translate }} (EUR)</strong></div>
        <div class="col-sm-9">{{ product.priceNetto | currency:'EUR'  }}</div>
      </div>
      <div class="row product-detail" *ngIf="!hidePrice">
        <div class="col-sm-3"><strong>{{ 'product.price' | translate }} ({{ currencyObserver.currentCurrency }}
          )</strong>
        </div>
        <div class="col-sm-9">
          {{ product.currencyPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}
          <div class="button-container-desktop" *ngIf="!hideButton">
            <button mat-raised-button color="primary" (click)="addToCart()">
              <mat-icon>add_shopping_cart</mat-icon>&nbsp;&nbsp;{{ 'cart.add' | translate }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="button-container" *ngIf="!hideButton">
    <button mat-raised-button color="primary" (click)="addToCart()">
      <mat-icon>add_shopping_cart</mat-icon>&nbsp;&nbsp;{{ 'cart.add' | translate }}
    </button>
  </div>
</div>

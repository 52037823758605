import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { CartObserver } from '../../../../../observers/cart.observer'
import { CurrencyObserver } from '../../../../../observers/currency.observer'
import { BookPriceOffer } from '../../../../../models/vouchers/book_price_offer.model'
import { CartService } from '../../../../../services/cart.service'

@Component({
  selector: 'app-book-launch-offer-v2-item',
  templateUrl: './book-launch-offer-item-v2.component.html',
  styleUrls: ['./book-launch-offer-item-v2.component.scss'],
})
export class BookLaunchOfferItemV2Component implements OnInit {

  @Input() tier: BookPriceOffer | undefined
  @Input() disabled: boolean = false
  @Output() selectTier: EventEmitter<BookPriceOffer> = new EventEmitter<BookPriceOffer>()

  constructor(private cartObserver: CartObserver,
              public currencyObserver: CurrencyObserver,
              private cartService: CartService,
  ) {
  }

  ngOnInit(): void {
  }

  addToCart() {
    if (this.disabled) {
      return
    }

    if (this.tier) {
      this.cartService.addBundleOfferToCart(this.tier).subscribe((cart) => this.cartObserver.updateCart(cart))
    }
  }

  chooseTier() {
    this.selectTier.emit(this.tier)
  }

}

import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { AccountBook } from '../../../models/account_book.model'
import { AccountVdmoServer } from '../../../models/account_vdmo_server.model'
import { Product } from '../../../models/product.model'
import { VdmoServer } from '../../../models/vdmo_server.model'
import { CartObserver } from '../../../observers/cart.observer'
import { AccountBookService } from '../../../services/account_book.service'
import { CartService } from '../../../services/cart.service'
import { ConfirmationDialogComponent } from './confirmation_dialog.component'

@Component({
  selector: 'app-my-books',
  templateUrl: './my_books.component.html',
  styleUrls: ['./my_books.component.scss']
})
export class MyBooksComponent implements OnInit, OnChanges, OnDestroy {

  public books: AccountBook[]

  public blacklistedISBNs: string[] = []
  public missingISBNs: string[] = []
  public availablePublishers: string[] = []
  public availableBooks: any

  constructor(
    private accountBookService: AccountBookService,
    private cartService: CartService,
    private cartObserver: CartObserver,
    private router: Router,
    public dialog: MatDialog
  ) {
    /* DO NOT DELETE */
    new AccountBook()
    new VdmoServer()
    new AccountVdmoServer()
    /* DO NOT DELETE END */
  }

  ngOnInit() {
    this.accountBookService.myBooks().subscribe(
      (books) => {
        this.books = books
        this.missingISBNs = []
        this.blacklistedISBNs = []
        this.availablePublishers = []
        this.availableBooks = {}
        this.books.forEach(
          (book) => {
            if (book.product && (book.product.active && !book.product.blacklisted) && book.product.productBook && book.product.productBook.publisher && book.product.productBook.publisher.name) {
              const publisherName = book.product.productBook.publisher.name
              if (!this.availableBooks.hasOwnProperty(<PropertyKey>publisherName)) {
                this.availablePublishers.push(publisherName)
                this.availableBooks[publisherName] = []
              }
              this.availableBooks[publisherName].push(book.product)
            } else if (book.product && book.product.ean && book.product.blacklisted ) {
              this.blacklistedISBNs.push(book.product.ean)
            } else if (!book.product && book.isbn) {
              this.missingISBNs.push(book.isbn)
            }
          }
        )
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
  }

  openDialog(product: Product) {
    const confirm = this.dialog.open(ConfirmationDialogComponent,
      {
        data: {product: product},
      });

    confirm.afterClosed().subscribe(result => {
      if (result === 'cart') {
        this.addProductToCart(product)
      } else if (result === 'open') {
        this.openProduct(product)
      }
    });
  }

  addProductToCart(product: Product) {
    this.cartService.addToCart(product).subscribe(
      (cart) => {
        this.cartObserver.updateCart(cart)
      }
    )
  }

  openProduct(product: Product) {
    this.router.navigateByUrl(`/shop/product/${ product.ean }`)
  }

}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CartItem} from "../../../models/cart_item.model";
import {CartItemService} from "../../../services/cart_item.service";
import {CartObserver} from "../../../observers/cart.observer";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {FormControl} from "@angular/forms";
import {CurrencyObserver} from "../../../observers/currency.observer";

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input() item: CartItem | undefined
  @ViewChild('amountElement') amountElement: HTMLInputElement

  amount: FormControl;

  snackBarSettings: { duration: number } = {
    duration: 3000
  }

  constructor(
    private cartItemService: CartItemService,
    public currencyObserver: CurrencyObserver,
    private cartObserver: CartObserver,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.amount = new FormControl(this.Amount?.toString());
  }

  get Amount(): number | undefined {
    return this.item?.amount
  }

  set Amount(val: number | undefined) {
    if (this.item && val && val > 0) {
      this.item.amount = val
      this.cartItemService.changeAmount(this.item).subscribe(
        (response) => {
          this.amount.setValue(response.amount)
          this.translate.get('cart.updated').subscribe(
            (val) => this.snackBar.open(val, 'OK', this.snackBarSettings)
          )
          this.cartObserver.fetchCart();
        }
      );
    } else {
      this.delete()
    }
  }

  selectText() {
    this.amountElement.focus()
    this.amountElement.select()
  }

  updateWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.update()
    }
  }

  update(val = 0) {
    this.Amount = +this.amount.value + val;
  }

  delete() {
    if (this.item) {
      this.cartItemService.removeCartItem(this.item).subscribe(
        (resp) => {
          this.cartObserver.fetchCart();
          this.translate.get('cart.product_removed').subscribe(
            (val) => this.snackBar.open(val, 'OK', this.snackBarSettings)
          )
        }
      )
    }
  }

}

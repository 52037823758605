<div class="container-fluid">

  <div class="actions_top">

    <ngx-loading-bar [color]="'#1a98d5'" [includeSpinner]="true" [height]="'6px'"></ngx-loading-bar>

    <div class="row">
      <button mat-icon-button aria-label="Cart icon" class="col-auto" (click)="cartObserver.hideCart()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <h1 class="col-auto">{{ 'cart.edit' | translate }}</h1>
    </div>
  </div>

  <div class="content" [class.empty-cart]="cartObserver?.cart?.cartItems?.length===0">

    <h2 *ngIf="cartObserver?.cart?.cartItems?.length===0">
      {{ 'cart.empty' | translate }}
    </h2>
    <h2 *ngIf="(cartObserver?.cart?.cartItems?.length || 0) > 0">
      {{ 'cart.list_products' | translate }}
    </h2>

    <div *ngFor="let item of cartObserver.cart?.cartItems">
      <app-cart-item [item]="item"></app-cart-item>
    </div>

  </div>

  <div class="actions_bottom">

<!--    <div>-->
<!--      <span>{{ 'cart.local_customs_note' | translate:{shop_name: 'shop_name' | translate} }}</span>-->
<!--    </div>-->

    <div class="summary-view">
      <app-price [totalAmount]="cartObserver?.cart?.priceTotal(currencyObserver.currentCurrency, false, false)"
                 [totalAmountDiscounted]="cartObserver?.cart?.priceTotalDiscounted(currencyObserver.currentCurrency, true)"></app-price>

      <button mat-raised-button color="primary"
              (click)="buyNow()" [disabled]="cartObserver?.cart?.cartItems?.length===0">
        <mat-icon>done_all</mat-icon>&nbsp;&nbsp;
        {{ 'cart.checkout' | translate }}
      </button>
    </div>

  </div>

</div>

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { Discount } from '../discount.model';
import { Product } from '../product.model';
import { CurrencyPrice } from '../currency_price.model';

@Model('bookPriceOffers', ['tiers'])
export class BookPriceOffer extends BaseModel {
  selected: boolean = false

  @Column()
  id: number | undefined;
  @Column()
  fixedFreeCopies: number | undefined;
  @Column()
  code: string | undefined;
  @Column()
  discount: Discount | undefined;
  @Column()
  validUntil: number | undefined;
  @Column()
  quantity: number | undefined;
  @Column({column: 'original', model: 'product'})
  product: Product | undefined;
  @Column()
  currencyBookPrices: CurrencyPrice[] = []
  @Column()
  currencyDefaultBookPrices: CurrencyPrice[] = []
  @Column()
  currencyAuthorSavings: CurrencyPrice[] = []
  @Column()
  currencyDiscountedAuthorPrices: CurrencyPrice[] = []

  currencyBookPrice(currency: string): number {
    if (this.currencyBookPrices) {
      const price = this.currencyBookPrices.find(
        (price) => price.currency === currency
      )?.netPrice
      if (price) {
        return price
      }
    }
    return 0
  }

  currencyAuthorSaving(currency: string): number {
    if (this.currencyAuthorSavings) {
      const price = this.currencyAuthorSavings.find(
        (price) => price.currency === currency
      )?.netPrice
      if (price) {
        return price
      }
    }
    return 0
  }

  currencyDiscountedAuthorPrice(currency: string): number {
    if (this.currencyDiscountedAuthorPrices) {
      const price = this.currencyDiscountedAuthorPrices.find(
        (price) => price.currency === currency
      )?.netPrice
      if (price) {
        return price
      }
    }
    return 0
  }

  get percentage(): number {
    return this.discount?.percentage || 0
  }

}

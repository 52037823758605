import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('documents', ['document'])
export class Document extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  slug: string | undefined;
  @Column()
  uname: string | undefined;
  @Column()
  name: string | undefined;
  @Column()
  content: string | undefined;
}

export const environment = {
  production: false,
  interceptedHosts: ['morebooks.shop'],
  backend: 'https://morebooks.shop',
  oauth: '',
  i18n: '',
  graphQL: '',
};

environment.oauth = `${ environment.backend }/oauth`
environment.i18n = `${ environment.backend }/i18n/` // trailing slash is important
environment.graphQL = `${ environment.backend }/graphql`

import AdyenCheckout from "@adyen/adyen-web";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Order } from "../../models/order.model";
import { LocaleObserver } from "../../observers/locale.observer";
import { PaymentPayloadService } from "../../services/payment_payload.service";

@Component({
  selector: 'app-adyen',
  templateUrl: './adyen.component.html',
  styleUrls: ['./adyen.component.scss']
})
export class AdyenComponent implements OnInit, OnChanges {

  @Input('order') order: Order
  @Input('type') type: string
  @Output('loadOrder') loadOrder: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild('adyen', {static: true}) adyen: ElementRef

  component: any
  checkout: any
  clientKey: string
  redirectResult: string
  sessionId: string

  snackBarSettings: { duration: number } = {
    duration: 3000
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localeObserver: LocaleObserver,
    private snackBar: MatSnackBar,
    private paymentPayloadService: PaymentPayloadService
  ) {
  }

  ngOnInit() {
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.type = this.route.snapshot.paramMap.get('type') || '';
    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId') || '';
    this.redirectResult = this.route.snapshot.queryParamMap.get('redirectResult') || '';

    if (this.sessionId !== '' && this.sessionId) {
      this.finalizeCheckout();
    }

    if (this.order && this.order.builderhash && changes['type'] && changes['type'].currentValue) {
      this.paymentPayloadService.fetchPayload(`Adyen::${ changes['type'].currentValue }`, this.order.builderhash).subscribe(
        async (payload) => {
          const data = payload.unpack()

          this.clientKey = data.clientKey
          const returnUrl = data.session.returnUrl.replace(':payment_method', changes['type'].currentValue)
          data.session.returnUrl = returnUrl

          if (this.component && this.type && changes['type'].previousValue !== changes['type'].currentValue && this.checkout) {
            this.checkout.remove(this.component)
          }
          this.checkout = await this.createAdyenCheckout(data.session)
          this.component = await this.checkout.create(changes['type'].currentValue).mount(this.adyen.nativeElement)
        }
      )
    }

  }

  async createAdyenCheckout(session: any) {

    const configuration = {
      clientKey: this.clientKey,
      locale: this.localeObserver.currentLocale,
      environment: this.clientKey.split('_')[0] === 'test' ? 'test' : 'live',
      showPayButton: true,
      session: session,
      paymentMethodsConfiguration: {},
      onPaymentCompleted: (state: any, component: any) => {
        this.handleServerResponse(state, component);
      },
      onError: (error: any, component: any) => {
        console.error(error.name, error.message, error.stack, component);

        if (this.sessionId !== '') {
          this.snackBar.open('session restore failed, initialize a new one', 'OK', this.snackBarSettings)
          this.sessionId = ''
          this.redirectResult = ''
          setTimeout(
            () => {
              this.router.navigate([], {
                queryParams: {sessionId: null, redirectResult: null},
                queryParamsHandling: 'merge'
              })
            }, 1000
          )

        } else {
          this.snackBar.open('session outdated, initialized a new one', 'OK', this.snackBarSettings)
          this.loadOrder.emit()
        }
      }
    };

    return await AdyenCheckout(configuration);
  }

  // Some payment methods use redirects. This is where we finalize the operation
  async finalizeCheckout() {
    try {
      // Create AdyenCheckout re-using existing Session
      console.log('try to restore session', this.sessionId)
      const checkout = await this.createAdyenCheckout({id: this.sessionId});
      console.log('checkout restored from session', checkout)
      console.log('try to submit the redirectResult', this.redirectResult)
      // Submit the extracted redirectResult (to trigger onPaymentCompleted() handler)
      checkout.submitDetails({details: this.redirectResult});
      console.log('redirectResult submitted')
    } catch (error) {
      console.log('an error happened')
      console.error(error);
      alert("Error occurred. Look at console for details");
    }
  }

  handleServerResponse(res: any, component: any) {
    if (res.action != null) {
      component.handleAction(res.action);
    } else {
      switch (res.resultCode) {
        case 'Authorised':
        case 'authorised':
          this.router.navigateByUrl(`/shop/payment/${ this.order.builderhash }/success`)
          break;
        case 'Pending':
        case 'pending':
        case 'Received':
        case 'received':
          this.router.navigateByUrl(`/shop/payment/${ this.order.builderhash }/pending`)
          break;
        case 'Refused':
        case 'refused':
          this.router.navigateByUrl(`/shop/payment/${ this.order.builderhash }/refused`)
          break;
        default:
          this.router.navigateByUrl(`/shop/payment/${ this.order.builderhash }/error`)
          break;
      }
    }
  }

}

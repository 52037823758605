import { BaseModel, Column, Model } from 'scf-graphql-model'
import { Discount } from "./discount.model";
import { CurrencyPrice } from "./currency_price.model";

@Model('vouchers', ['voucher', 'publicOfferAvailable'])
export class Voucher extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  code: string | undefined;
  @Column()
  discount: Discount | undefined;
  @Column()
  promotion: boolean | undefined;
  @Column()
  promotionImageUrl: string | undefined;
  @Column()
  promotionImageUrlUsed: string | undefined;
  @Column()
  quantity: number | undefined;
  @Column()
  validUntil: Date | undefined;
  @Column()
  currencyPrices: CurrencyPrice[] = [];
  @Column()
  currencyBookPrices: CurrencyPrice[] = [];

  availableAmount(currency: string, forceNegative = false): number {
    if (this.currencyPrices) {
      const price = this.currencyPrices.find(
        (price) => price.currency === currency
      )?.netPrice
      if (price) {
        return (forceNegative ? price * -1 : price)
      }
    }
    return 0
  }

  reducedBookPrice(currency: string, forceNegative = false): number {
    if (this.currencyBookPrices) {
      const price = this.currencyBookPrices.find(
        (price) => price.currency === currency
      )?.netPrice
      if (price) {
        return (forceNegative ? price * -1 : price)
      }
    }
    return 0
  }
}

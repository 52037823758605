<div>

  <div *ngIf="payload?.error">
    <strong class="error">{{ payload?.error }}</strong>
  </div>

  <div *ngIf="payload && !payload?.error">

    <h3>{{ 'wire_transfer.pay_with' | translate }}</h3>

    <table>
      <colgroup>
        <col style="width: 150px;"/>
        <col style="width: 150px;"/>
      </colgroup>

      <tr *ngIf="payload.account_holder" class="top-aligned">
        <th>{{ 'wire_transfer.account_holder' | translate }}:</th>
        <td class="account-holder" [innerHTML]="payload.account_holder"></td>
      </tr>
      <tr *ngIf="payload.name && !payload.address">
        <th>{{ 'wire_transfer.bank_name' | translate }}:</th>
        <td [innerHTML]="payload.name"></td>
      </tr>
      <tr *ngIf="payload.address" class="top-aligned">
        <th>{{ 'wire_transfer.bank_address' | translate }}:</th>
        <td [innerHTML]="payload.address"></td>
      </tr>
      <tr *ngIf="payload.ada_number">
        <th>{{ 'wire_transfer.ada_number' | translate }}:</th>
        <td [innerHTML]="payload.ada_number"></td>
      </tr>
      <tr *ngIf="payload.branch_code">
        <th>{{ 'wire_transfer.branch_code' | translate }}:</th>
        <td [innerHTML]="payload.branch_code"></td>
      </tr>
      <tr *ngIf="payload.account_nr">
        <th>{{ 'wire_transfer.account_nr' | translate }}:</th>
        <td [innerHTML]="payload.account_nr"></td>
      </tr>
      <tr *ngIf="payload.bic">
        <th>{{ 'wire_transfer.bic' | translate }}:</th>
        <td [innerHTML]="payload.bic"></td>
      </tr>
      <tr *ngIf="payload.iban">
        <th>{{ 'wire_transfer.iban' | translate }}:</th>
        <td [innerHTML]="payload.iban"></td>
      </tr>
      <tr class="bottom-aligned">
        <th>{{ 'cart.amount' | translate }}:</th>
        <td class="price">{{ order.totalprice  | currency:order?.currency?.currency }}</td>
      </tr>
    </table>

  </div>

</div>

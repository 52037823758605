import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { Publisher } from '../models/publisher.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'

import publishersQuery from 'src/app/graphql/queries/publishers.query.graphql'

@Injectable()
export class PublisherService extends GraphqlService<Publisher> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  fetchPublishers(): Observable<Publisher[]> {
    return this.findAll(publishersQuery)
  }

}

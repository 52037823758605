import { BaseModel, Column, Model } from 'scf-graphql-model'
import { ProductBookAuthor } from "./product_book_author.model";
import { Publisher } from "./publisher.model";
import { Measure } from "./measure.model";

@Model('productBook')
export class ProductBook extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  isbn: string | undefined;
  @Column()
  isbn10: string | undefined;
  @Column()
  title: string | undefined;
  @Column()
  subtitle: string | undefined;
  @Column()
  blurb: string | undefined;
  @Column()
  language: string | undefined;
  @Column()
  description: string | undefined;
  @Column({column: 'numpagescolor'})
  colorPages: string | undefined;
  @Column({column: 'numpages'})
  numberOfPages: number | undefined;
  @Column()
  colorPagePosition: string | undefined;
  @Column()
  hardcover: boolean | undefined;
  @Column()
  publishedAt: Date | undefined;
  @Column()
  publisher: Publisher | undefined = new Publisher();
  @Column()
  measure: Measure | undefined = new Measure();
  @Column()
  productBookAuthors: ProductBookAuthor[] = [];

  getAuthors(): null | ProductBookAuthor[] {
    const authors = this.productBookAuthors?.filter(
      (author) => author.role === 'A01'
    )

    if (!authors || authors.length === 0) {
      return null
    } else {
      return authors
    }
  }

  getEditors(): null | ProductBookAuthor[] {
    const editors = this.productBookAuthors?.filter(
      (author) => author.role === 'B01'
    )

    if (!editors || editors.length === 0) {
      return null
    } else {
      return editors
    }
  }

}

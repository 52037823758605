import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { Content } from "../../models/content.model";
import { Document } from "../../models/document.model";
import { LocaleObserver } from "../../observers/locale.observer";
import { SearchObserver } from '../../observers/search.observer'
import { ContentService } from "../../services/content.service";
import { DocumentService } from "../../services/document.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public webpages: Content[] = []
  public documents: Document[] = []

  constructor(
    private contentService: ContentService,
    private documentService: DocumentService,
    private localeObserver: LocaleObserver,
    private searchObserver: SearchObserver,
    private router: Router
  ) {
    /* DO NOT DELETE */
    new Content();
    new Document();
    /* DO NOT DELETE END */

    this.localeObserver.localeChangeEvent.subscribe(
      (locale) => {
        this.loadWebpages()
        this.loadDocuments()
      }
    )

  }

  ngOnInit(): void {

  }

  openLink(route: any){
    this.searchObserver.hideSearchBar()
    this.router.navigate(route)
  }

  loadWebpages() {
    this.contentService.pages(this.localeObserver.currentLocale, Content.FOOTER_NAV).subscribe(
      (pages) => this.webpages = pages
    )
  }

  loadDocuments() {
    this.documentService.documents(this.localeObserver.currentLocale, ['AGB', 'DISCLAIMER', 'DATENSCHUTZ']).subscribe(
      (documents) => this.documents = documents
    )
  }

}

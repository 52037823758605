import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookLaunchOffer } from '../../../../../models/book_launch_offer.model'
import { CartObserver } from "../../../../../observers/cart.observer";
import { CurrencyObserver } from "../../../../../observers/currency.observer";
import { BookPriceOffer } from "../../../../../models/vouchers/book_price_offer.model";
import { CartService } from "../../../../../services/cart.service";

@Component({
  selector:    'app-book-launch-offer-v3-default-item',
  templateUrl: './book-launch-offer-default-item.component.html',
  styleUrls:   ['./book-launch-offer-default-item.component.scss']
})
export class BookLaunchOfferDefaultItemComponent implements OnInit {

  @Input() bookLaunchOffer: BookLaunchOffer | undefined
  @Input() tier: BookPriceOffer | undefined
  @Input() disabled: boolean = false
  @Output() selectTier: EventEmitter<BookPriceOffer> = new EventEmitter<BookPriceOffer>()

  constructor(
    private cartObserver: CartObserver,
    public currencyObserver: CurrencyObserver,
    private cartService: CartService
  ) {
  }

  ngOnInit(): void {
  }

  addToCart() {
    if (this.disabled) {
      return
    }

    if (this.tier) {
      this.cartService.addBundleOfferToCart(this.tier).subscribe(
        (cart) => this.cartObserver.updateCart(cart)
      )
    }
  }

}

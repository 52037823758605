import { environment } from '../../environments/environment'
import { User } from '../models/user.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import myDataQuery from 'src/app/graphql/queries/my_data.query.graphql'
import updateDataQuery from 'src/app/graphql/mutations/update_my_data.mutation.graphql'

@Injectable()
export class UserService extends GraphqlService<User> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  getMyData(): Observable<User> {
    return this.find(myDataQuery)
  }

  updateMyData(accountInformation: {}, companyInformation: {}): Observable<User> {
    return this.push(updateDataQuery, { ...accountInformation, ...companyInformation })
  }

}

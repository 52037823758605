import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CartObserver } from '../../../../../observers/cart.observer'
import { CurrencyObserver } from '../../../../../observers/currency.observer'
import { BookPriceOffer } from '../../../../../models/vouchers/book_price_offer.model'
import { CartService } from '../../../../../services/cart.service'

@Component({
  selector: 'app-book-launch-offer-v3-item',
  templateUrl: './book-launch-offer-item.component.html',
  styleUrls: ['./book-launch-offer-item.component.scss'],
})
export class BookLaunchOfferItemComponent implements OnInit {

  @Input() tier: BookPriceOffer | undefined
  @Input() disabled: boolean = false
  @Output() selectTier: EventEmitter<BookPriceOffer> = new EventEmitter<BookPriceOffer>()

  constructor(private cartObserver: CartObserver,
              public currencyObserver: CurrencyObserver,
              private cartService: CartService,
  ) {
  }

  ngOnInit(): void {
  }

  chooseTier(e: Event) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()
    this.selectTier.emit(this.tier)
  }

}

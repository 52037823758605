<section>
  <mat-nav-list>
    <a mat-list-item routerLink="/">
      <mat-icon class="home-icon">home</mat-icon>
    </a>
    <a mat-list-item *ngFor="let webpage of webpages"
       (click)="openLink(['webpage', webpage.slug, webpage.id])"> {{ webpage.title }} </a>
    <a mat-list-item *ngFor="let document of documents"
       (click)="openLink(['document', document.slug, document.id])"> {{ document.name }} </a>
  </mat-nav-list>
</section>

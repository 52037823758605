import { BaseModel, Column, Model } from 'scf-graphql-model'
import { VdmoServer } from './vdmo_server.model'

@Model('accountVdmoServer')
export class AccountVdmoServer extends BaseModel {
  @Column()
  email: string | undefined

  @Column()
  vdmoServer: VdmoServer | undefined;
}

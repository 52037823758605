import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Category } from "../../models/category.model";
import { SearchObserver } from "../../observers/search.observer";
import { Product } from "../../models/product.model";
import { CategoryObserver } from "../../observers/category.observer";
import { CurrencyObserver } from "../../observers/currency.observer";
import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import { ProductService } from "../../services/product.service";
import { ProductsComponent } from "./products/products.component";
import { Location } from "@angular/common";
import { LocaleObserver } from "../../observers/locale.observer";
import { BookLanguageObserver } from "../../observers/book_language.observer";
import { Subscription } from "rxjs";
import { CategoryService } from "../../services/category.service";
import { TranslateService } from "@ngx-translate/core";
import {
  TranslationBundleService,
} from "../../services/vouchers/translation_bundle.service";
import { TranslationBundle } from "../../models/vouchers/translation_bundle.model";
import { BookLaunchOffer } from "../../models/book_launch_offer.model";
import { BookLaunchOfferService } from "../../services/book_launch_offer.service";
import { BookPriceOffer } from "../../models/vouchers/book_price_offer.model";
import { LayoutObserver } from "../../observers/layout.observer";

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('productComponent') productComponent: ProductsComponent;

  urlBeforeSearch: UrlSegment[]

  RENDER_START = 'start'
  RENDER_SEARCH = 'search'
  RENDER_CATEGORY = 'category'
  RENDER_PRODUCT = 'product'
  RENDER_CHECKOUT = 'checkout'
  RENDER_BOOK_LAUNCH_OFFER = 'book-launch-offer'
  RENDER_TRANSLATION_BUNDLE = 'translation-bundle'

  categoriesNavCollapsed = false
  activeSearch = false
  searchWasActive = false

  category: Category | undefined;
  categoryId: string | null | undefined;
  code: string | null | undefined;
  ean: string | null | undefined;
  page: string | null | undefined;
  product: Product | undefined;
  products: Product[] = [];
  render: string;
  token: string | null | undefined;
  translationBundle: TranslationBundle | undefined;
  bookLaunchOffer: BookLaunchOffer | undefined;

  fetchingData: Subscription | undefined;

  constructor(private searchObserver: SearchObserver,
              private bookLanguageObserver: BookLanguageObserver,
              private categoryObserver: CategoryObserver,
              private currencyObserver: CurrencyObserver,
              private layoutObserver: LayoutObserver,
              private localeObserver: LocaleObserver,
              private bookLaunchOfferService: BookLaunchOfferService,
              private categoryService: CategoryService,
              private productService: ProductService,
              private translationBundleService: TranslationBundleService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private translate: TranslateService) {

    /* DO NOT DELETE */
    new TranslationBundle();
    new BookPriceOffer();
    new BookLaunchOffer();
    /* DO NOT DELETE END */

    this.bookLanguageObserver.bookLanguageObserver$.subscribe(
      (languageStr) => {
        document.getElementsByTagName('mat-sidenav-content')[0].scrollTop = 0
        this.page = '1'
        this.handleRoutes(true)
      }
    )

    searchObserver.searchVisibilityObserver$.subscribe(
      (visible) => {
        this.activeSearch = visible
        if (visible) {
          this.searchWasActive = true
        }
      }
    )

    this.route.url.subscribe(
      url => {
        this.handleRoutes()
      }
    )

    this.localeObserver.localeChangeEvent.subscribe(
      (lang) => {
        if (!this.categoryId && !this.activeSearch && !this.ean && !this.code && !this.token) {
          this.reload()
        }
      }
    )
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
  }

  handlePageChangeEvent(page: number) {
    const element = document.getElementsByTagName('mat-sidenav-content')[0]
    if (element) {
      element.scrollTop = 0
    }
    this.page = page.toString()
    this.router.navigateByUrl(`/shop/category/${ this.categoryId }/${ page }`)
  }

  handleProductSelected(product: Product) {
    this.router.navigateByUrl(`/shop/product/${ product.ean }`)
  }

  handleRoutes(ignorePageParam = false) {

    this.layoutObserver.showSearchObserver$.next(true)
    this.layoutObserver.showCartObserver$.next(true)
    this.layoutObserver.showLanguageSwitchObserver$.next(true)
    this.layoutObserver.showCurrencySwitchObserver$.next(true)

    if (this.route.snapshot.url.length === 1) {
      this.render = this.RENDER_START
    } else if (this.route.snapshot.url[1].path === this.RENDER_BOOK_LAUNCH_OFFER) {
      this.render = this.RENDER_BOOK_LAUNCH_OFFER
      this.token = this.route.snapshot.paramMap.get('token')
    } else if (this.route.snapshot.url[1].path === this.RENDER_SEARCH) {
      this.render = this.RENDER_START
      this.searchObserver.showSearchBar()
    } else if (this.route.snapshot.url[1].path === this.RENDER_TRANSLATION_BUNDLE) {
      this.render = this.RENDER_TRANSLATION_BUNDLE
      this.code = this.route.snapshot.paramMap.get('code')
    } else if (this.route.snapshot.url[1].path === this.RENDER_PRODUCT) {
      this.render = this.RENDER_PRODUCT
      this.ean = this.route.snapshot.paramMap.get('ean')
    } else if (this.route.snapshot.url[1].path === this.RENDER_CATEGORY) {
      this.render = this.RENDER_CATEGORY
      this.categoryId = this.route.snapshot.paramMap.get('categoryId')
      if (!ignorePageParam) {
        this.page = this.route.snapshot.paramMap.get('page')
      }
    }

    this.reload()
  }

  private reload() {
    if (this.activeSearch) {
    } else if (this.renderProduct()) {
      this.loadProduct()
    } else if (this.renderBookLaunchOffer()) {
      this.loadBookLaunchOffer()
    } else if (this.renderTranslationBundle()) {
      this.loadTranslationBundle()
    } else {
      this.loadProducts();
    }
  }

  renderSearch(): boolean {
    return this.render === this.RENDER_SEARCH
  }

  renderStart(): boolean {
    return this.render === this.RENDER_START
  }

  renderCategory(): boolean {
    return this.render === this.RENDER_CATEGORY
  }

  renderProduct(): boolean {
    return this.render === this.RENDER_PRODUCT
  }

  renderBookLaunchOffer(): boolean {
    return this.render === this.RENDER_BOOK_LAUNCH_OFFER
  }

  renderTranslationBundle(): boolean {
    return this.render === this.RENDER_TRANSLATION_BUNDLE
  }

  renderCheckout(): boolean {
    return this.render === this.RENDER_CHECKOUT
  }

  private loadProducts() {

    this.page ||= '1'

    if (this.fetchingData) {
      this.fetchingData.unsubscribe()
      this.fetchingData = undefined;
    }

    if (this.categoryId) {

      this.categoryService.fetchCategory(+this.categoryId, this.translate.currentLang).subscribe(
        (cat) => this.category = cat
      )

      this.fetchingData = this.productService.byCategory(this.categoryId, +this.page, this.bookLanguageObserver.currentLanguageStr).subscribe(
        (result) => {
          this.products = result
          this.productComponent.pagination = this.productService.pagination()
          this.productComponent.aggregations = this.productService.aggregations()

          if (this.products && this.products.length == 0) {
            this.bookLanguageObserver.openDropdown()
          } else {
            this.bookLanguageObserver.closeDropdown()
          }
        }
      )
    } else {

      this.category = undefined;

      if (this.productComponent) {
        this.productComponent.pagination = undefined
        this.productComponent.aggregations = undefined
      }

      this.fetchingData = this.productService.latestProducts().subscribe(
        (result) => {
          this.products = result
        }
      )
    }
  }

  private loadProduct() {
    if (!this.ean) {
      return
    }

    this.productService.byEAN(this.ean).subscribe(
      (result) => {
        this.product = result
        if (this.product && this.product.category) {


          if (this.categoryId !== this.product.category.id) {
            this.categoryObserver.setActiveCategory(this.product.category)
          }
        }
      }
    )
  }

  private loadBookLaunchOffer() {
    if (!this.token) {
      return
    }

    this.bookLaunchOfferService.byCode(this.token).subscribe(
      (result) => {
        this.bookLaunchOffer = result
        if (this.bookLaunchOffer && this.bookLaunchOffer.product && this.bookLaunchOffer.product.category) {
          if (this.categoryId !== this.bookLaunchOffer.product.category.id) {
            this.categoryObserver.setActiveCategory(this.bookLaunchOffer.product.category)
          }
        }
      }
    )
  }

  private loadTranslationBundle() {
    if (!this.code) {
      return
    }

    this.translationBundleService.byCode(this.code).subscribe(
      (result) => {
        this.translationBundle = result
      }
    )
  }

}

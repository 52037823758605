import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { Language } from '../models/language.model'

@Injectable()
export class BookLanguageObserver {

  interestedBookLanguages: { [key: string]: boolean } = {}
  bookLanguageObserver$: Subject<string> = new Subject()
  bookLanguageDropdownObserver$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  currentLanguageStr: string | undefined
  availableLanguageOptions: string[] | undefined

  constructor(private translate: TranslateService) {
    this.currentLanguageStr = 'eng'
    this.translate.get('product.book_languages').subscribe((res) => {
      this.availableLanguageOptions = Object.keys(res)
      this.init(this.availableLanguageOptions)
    })
  }

  init(availableLanguages: string[]) {
    if (!availableLanguages) {
      return
    }

    this.interestedBookLanguages ||= {}
    const values = localStorage.getItem('interestedBookLanguages')

    if (values) {
      this.interestedBookLanguages = JSON.parse(values)
      Object.keys(this.interestedBookLanguages).forEach((langs) => {
        langs.split(',').forEach((lang) => {
          const index = availableLanguages.findIndex((_lang) => _lang === lang)
          if (index === -1) {
            delete this.interestedBookLanguages[lang]
          }
        })
      })
    }

    availableLanguages.forEach((key) => {
      if (key) {
        this.interestedBookLanguages[key] ||= this.isInterestedBookLanguageActive(key)
      }
    })
    this.setLanguageString()
  }

  openDropdown() {
    this.bookLanguageDropdownObserver$.next(true)
  }

  closeDropdown() {
    this.bookLanguageDropdownObserver$.next(false)
  }

  refresh() {
    this.setLanguageString()
  }

  selectAll(aggregations: Language[]) {
    aggregations.forEach((filter) => {
      if (!this.isInterestedBookLanguageActive(filter.code3)) {
        this.toggle(filter.code3)
      }
    })
    this.setLanguageString()
  }

  toggle(value: string | undefined) {
    if (value) {
      if (this.interestedBookLanguages[value]) {
        delete this.interestedBookLanguages[value]
      } else {
        this.interestedBookLanguages[value] = true
      }
    }
  }

  toggleInterestedBookLanguage(value: string | undefined, event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    this.toggle(value)
  }

  isInterestedBookLanguageActive(value: string | undefined): boolean {
    if (value) {
      return this.interestedBookLanguages[value]
    }
    return false
  }

  private setLanguageString() {
    this.bookLanguageDropdownObserver$.next(false)

    localStorage.setItem('interestedBookLanguages', JSON.stringify(this.interestedBookLanguages))
    const languages: string[] = []

    Object.keys(this.interestedBookLanguages).forEach((key) => {
      if (this.isInterestedBookLanguageActive(key)) {
        languages.push(key)
      }
    })

    this.currentLanguageStr = languages.join(',')

    this.shareNewSelection()
  }

  private shareNewSelection() {
    this.bookLanguageObserver$.next(this.currentLanguageStr)
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { Category } from './category.model';
import { ProductBook } from './product_book.model';
import { CurrencyPrice } from './currency_price.model';
import { Voucher } from './voucher.model';

@Model('product', ['products', 'latestProducts', 'productsByCategory', 'productSearch', 'bookLaunchOffer', 'translations', 'extendedProductSearch'])
export class Product extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  active: boolean | undefined;
  @Column()
  blacklisted: boolean | undefined;
  @Column()
  priceNetto: number | undefined;
  @Column()
  title: string | undefined;
  @Column({column: 'internalId'})
  ean: string | undefined;
  @Column()
  weight: number | undefined;
  @Column()
  category: Category | undefined = new Category();
  @Column()
  productBook: ProductBook | undefined = new ProductBook();
  @Column()
  currencyPrices: CurrencyPrice[] = [];
  @Column()
  activeBookPriceOffers: Voucher[] = []

  imageURL(type: 'cover', dimension = 'x450'): string {
    return `https://images.our-assets.com/${ type }/${ dimension }/${ this.ean }.jpg`
  }

  currencyPrice(currency: string): number {
    if (this.currencyPrices) {
      const price = this.currencyPrices.find(
        (price) => price.currency === currency
      )?.netPrice
      if (price) {
        return price
      }
    }
    return 0
  }

  totalCurrencyPrice(currency: string, quantity: number): number {
    return this.currencyPrice(currency) * quantity
  }
}

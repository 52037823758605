import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Cart } from '../models/cart.model'
import { CartService } from '../services/cart.service'

@Injectable()
export class CartObserver {

  cart: Cart
  cartItemCounter = 0
  cartVisible = false
  cartFetched = false
  cartBounce = false
  cartObserver$: BehaviorSubject<Cart>
  cartBounceObserver$: BehaviorSubject<boolean>
  cartVisibilityObserver$: BehaviorSubject<boolean>
  cartItemCounterObserver$: BehaviorSubject<number>

  constructor(private cartService: CartService) {
    this.cartObserver$ = new BehaviorSubject<Cart>(new Cart())
    this.fetchCart()
    this.cartBounceObserver$ = new BehaviorSubject<boolean>(this.cartBounce)
    this.cartVisibilityObserver$ = new BehaviorSubject<boolean>(this.cartVisible)
    this.cartItemCounterObserver$ = new BehaviorSubject<number>(this.cartItemCounter)
  }


  fetchCart() {
    this.cartService.cart().subscribe((cart) => {
      this.updateCart(cart)
    })
  }

  toggleCart() {
    this.cartVisible = !this.cartVisible
    this.cartVisibilityObserver$.next(this.cartVisible)
  }

  hideCart() {
    this.cartVisible = false
    this.cartVisibilityObserver$.next(this.cartVisible)
  }

  bounceCartItem() {
    this.cartBounce = true
    this.cartBounceObserver$.next(this.cartBounce)
    setTimeout(() => {
      this.cartBounce = false
      this.cartBounceObserver$.next(this.cartBounce)
    }, 1000)
  }

  showCart() {
    this.cartVisible = true
    this.cartVisibilityObserver$.next(this.cartVisible)
  }

  updateCart(cart = this.cart) {
    this.cart = cart
    this.cartFetched = true
    this.cartObserver$.next(this.cart)
    let totalAmount = 0
    cart.cartItems.forEach((item) => totalAmount += item.amount || 0)
    this.cartItemCounter = totalAmount
    this.cartItemCounterObserver$.next(this.cartItemCounter)
  }

}

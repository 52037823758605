import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Content } from "../../models/content.model";
import { LocaleObserver } from "../../observers/locale.observer";
import { ContentService } from "../../services/content.service";

@Component({
  selector: 'app-webpage',
  templateUrl: './webpage.component.html',
  styleUrls: ['./webpage.component.scss']
})
export class WebpageComponent implements OnInit {

  private id: number | null;
  private slug: string | null;

  public webpage: Content

  constructor(
    private route: ActivatedRoute,
    private contentService: ContentService,
    private localeObserver: LocaleObserver,
  ) {
    /* DO NOT DELETE */
    new Content();
    /* DO NOT DELETE END */

    this.route.paramMap.subscribe(
      (params) => {
        if (params.get('id')) {
          this.id = parseInt(params.get('id') || '0')
        }
        if (params.get('slug')) {
          this.slug = params.get('slug')
        }
        this.load()
      }
    )

    this.localeObserver.localeChangeEvent.subscribe(
      (locale) => {
        this.load()
      }
    )

    this.load()
  }

  ngOnInit(): void {
  }

  private load() {
    if (this.id && this.slug) {
      this.contentService.page(this.id, this.localeObserver.currentLocale).subscribe(
        (page) => this.webpage = page
      )
    }
  }

}

import { environment } from '../../environments/environment'
import { BaseModel, Column, Model } from 'scf-graphql-model'
import { Address } from './address.model';
import { Currency } from './currency.model';

@Model('order', ['doCheckout', 'order', 'myOrders', 'cancelOrder'])
export class Order extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  currency: Currency | undefined;
  @Column()
  billingAddress: Address | undefined;
  @Column()
  shippingAddress: Address | undefined;
  @Column()
  builderhash: string | undefined;
  @Column()
  createdAt: Date | undefined;
  @Column()
  discountAmount: number | undefined;
  @Column()
  shippingCost: number | undefined;
  @Column()
  totalprice: number | undefined;
  @Column()
  paymentProvider: string | undefined;
  @Column()
  paymentModule: string | undefined;
  @Column()
  status: string | undefined;

  hasOrderedStatus(): boolean {
    return this.status === 'ordered'
  }

  hasPaymentTermsStatus(): boolean {
    return this.status === 'payment_terms'
  }

  invoiceUrl(locale: string) {
    return `${environment.backend}/account/${ locale }/order/${ this.builderhash }/download`
  }


}


    
    var doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MyData"},"variableDefinitions":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myData"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isAuthor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isBookseller"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isWholesaler"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isRegisteredEuCompany"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isCompany"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userInformation"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ustidnr"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"taxId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"checkedCompany"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"forename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"surname"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mobilePhoneNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"country"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]}]}}]}}]}}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  

    module.exports = doc
  

import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('webpages', ['webpage'])
export class Content extends BaseModel {

  public static MAIN_NAV = 1
  public static FOOTER_NAV = 2
  public static RIGHT_NAV = 3

  @Column()
  id: number | undefined;
  @Column()
  slug: string | undefined;
  @Column()
  title: string | undefined;
  @Column()
  body: string | undefined;
}

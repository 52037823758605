import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatStepper } from '@angular/material/stepper'
import { TranslateService } from '@ngx-translate/core'
import { countries } from 'country-flag-icons'
import { Observable, of } from 'rxjs'
import { catchError, map, startWith } from 'rxjs/operators'
import { Country } from '../../../models/country.model'
import { User } from '../../../models/user.model'
import { UserInformation } from '../../../models/user_information.model'
import { CountryService } from '../../../services/country.service'
import { UserService } from '../../../services/user.service'

@Component({
  selector: 'app-my-account',
  templateUrl: './my_account.component.html',
  styleUrls: ['./my_account.component.scss']
})
export class MyAccountComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('countryFilter') countryFilter: HTMLInputElement
  @ViewChild('stepper') stepper: MatStepper

  accountInformationFormGroup: FormGroup
  companyInformationFormGroup: FormGroup

  countries: Country[] = []
  filteredCountries: Observable<Country[]> | undefined
  user: User

  showDetails: boolean
  snackBarSettings: { duration: number } = {
    duration: 3000
  }

  constructor(
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private userService: UserService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {

    /* DO NOT DELETE */
    new User()
    new UserInformation()
    /* DO NOT DELETE END */

    this.accountInformationFormGroup = this.formBuilder.group(
      {
        forename: '',
        surname: '',
        mobilePhoneNumber: '',
        email: '',
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
      }
    )

    this.companyInformationFormGroup = this.formBuilder.group(
      {
        countryFilter: '',
        country: '',
        isCompany: false,
        company: '',
        ustidnr: '',
        taxId: '',
      }
    )

    this._setupPassword()
    this._setupEmail()

    this.countryService.deliverable().subscribe(
      (countries) => {
        this.countries = countries

        this.filteredCountries = this._setupCountryFilterChangeEvent()
      }
    )

  }

  ngOnInit() {
    this.userService.getMyData().subscribe(
      (user) => {
        this.setUser(user)
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
  }

  onCountrySelected() {

    let country: Country | undefined = this.companyInformationFormGroup.get('country')?.value

    if (country) {

      if (country.eu && country.ustidPattern) {
        this.companyInformationFormGroup.get('ustidnr')?.setValidators([Validators.required, Validators.pattern(country.ustidPattern)])
      } else {
        this.companyInformationFormGroup.get('ustidnr')?.clearValidators()
      }

    } else {
      this.companyInformationFormGroup.get('ustidnr')?.clearValidators()
    }
  }

  resetCountryFilter() {
    this.companyInformationFormGroup.controls['countryFilter'].setValue('');
  }

  clearField(formGroup: FormGroup, fieldName: string) {
    let value: any = {}
    value[fieldName] = ''

    formGroup.patchValue(value)

    if (fieldName === 'email' || fieldName === 'password') {
      formGroup.get(fieldName)?.clearValidators()
    }

    formGroup.updateValueAndValidity()
  }

  save() {
    this.userService.updateMyData(this.accountInformationFormGroup.value, this.companyInformationFormGroup.value).subscribe(
      (user) => {
        this.stepper.selectedIndex = 0
        this.translateService.get('user_area.user.successfully_updated').subscribe(
          (text) => this.snackBar.open(text, 'OK', this.snackBarSettings)
        )
        this.setUser(user)
      }
    )
  }

  private setUser(user: User) {

    if (!user) {
      return
    }

    this.user = user
    const country = this.countries.find((cntry) => user?.userInformation?.country?.id === cntry.id)

    this.accountInformationFormGroup.patchValue(
      {
        forename: user?.userInformation?.forename,
        surname: user?.userInformation?.surname,
        email: user?.email,
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
        mobilePhoneNumber: user?.userInformation?.mobilePhoneNumber,
      }
    )

    this.companyInformationFormGroup.patchValue(
      {
        country: country,
        company: user?.userInformation?.company,
        isCompany: user?.isCompany || user?.isRegisteredEuCompany || user?.isBookseller || user?.isWholesaler,
        ustidnr: user?.userInformation?.ustidnr,
        taxId: user?.userInformation?.taxId,
      }
    )

    if (country) {
      this.onCountrySelected()
    }

  }

  private _setupPassword() {
    this.accountInformationFormGroup.get('password')?.valueChanges?.subscribe(
      (password: string) => {
        if (password === '' || !password) {
          this.accountInformationFormGroup.get('password')?.clearValidators()
          this.accountInformationFormGroup.get('passwordConfirmation')?.clearValidators()
          this.accountInformationFormGroup.patchValue({passwordConfirmation: ''})
        } else {
          this.accountInformationFormGroup.get('password')?.setValidators(Validators.compose([Validators.required, this._minLength(8)]))
          this.accountInformationFormGroup.get('passwordConfirmation')?.setValidators([this._confirmationValidator('password')])
        }

        this.accountInformationFormGroup.get('password')?.updateValueAndValidity({emitEvent: false})
        this.accountInformationFormGroup.get('passwordConfirmation')?.updateValueAndValidity({emitEvent: false})
      }
    )
  }

  private _setupEmail() {
    this.accountInformationFormGroup.get('email')?.valueChanges?.subscribe(
      (email: string) => {
        if (email === this.user.email) {
          this.accountInformationFormGroup.get('email')?.setValidators([Validators.required, Validators.email])
          this.accountInformationFormGroup.get('emailConfirmation')?.clearValidators()
          this.accountInformationFormGroup.patchValue({emailConfirmation: ''})
        } else {
          this.accountInformationFormGroup.get('email')?.clearValidators()
          this.accountInformationFormGroup.get('emailConfirmation')?.setValidators([Validators.required, Validators.email, this._confirmationValidator('email')])
        }

        this.accountInformationFormGroup.get('email')?.updateValueAndValidity({emitEvent: false})
        this.accountInformationFormGroup.get('emailConfirmation')?.updateValueAndValidity({emitEvent: false})
      }
    )
  }

  private _setupCountryFilterChangeEvent(): undefined | Observable<Country[]> {
    return this.companyInformationFormGroup.get('countryFilter')?.valueChanges?.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.printableName)),
      map((country: any) => {
        if (country !== '') {
          return this._filterCountries(country)
        } else {
          return this.countries.slice()
        }
      }),
      catchError(err => {
        return of([])
      })
    )
  }

  private _confirmationValidator(comparisonFieldName: string): any {
    return (control: FormControl): { [s: string]: boolean } => {
      if (!control.value) {
        return {}
      } else if (control.value !== this.accountInformationFormGroup.get(comparisonFieldName)?.value) {
        return {confirmationNotEqual: true, error: true}
      }
      return {}
    }
  }

  private _minLength(min: number): any {
    return (control: FormControl): { [s: string]: boolean } => {
      if (!control.value) {
        return {}
      } else if (control.value.length < min) {
        return {minlength: true, error: true}
      }
      return {}
    }
  }

  private _filterCountries(value: string): Country[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter(country => {
      const matches = country.synonyms.filter(synonym => {
        return synonym.toLowerCase().includes(filterValue)
      })
      return matches.length > 0
    });
  }

}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Document as TheDocument } from '../../models/document.model';
import { LocaleObserver } from '../../observers/locale.observer';
import { DocumentService } from '../../services/document.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  @ViewChild('content') content: ElementRef

  public onlyColumnOne = true
  public onlyColumnTwo = false

  private id: number | null;
  private slug: string | null;

  public document: TheDocument

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private localeObserver: LocaleObserver,
    @Inject(DOCUMENT) private documentBody: Document
  ) {
    /* DO NOT DELETE */
    new Document();
    /* DO NOT DELETE END */

    this.route.paramMap.subscribe(
      (params) => {
        if (params.get('id')) {
          this.id = parseInt(params.get('id') || '0')
        }
        if (params.get('slug')) {
          this.slug = params.get('slug')
        }

        this.load()
      }
    )

    this.localeObserver.localeChangeEvent.subscribe(
      (locale) => {
        this.load()
      }
    )
  }

  ngOnInit(): void {
  }

  toggleColumns() {
    this.onlyColumnOne = !this.onlyColumnOne
    this.onlyColumnTwo = !this.onlyColumnTwo
  }

  showOnlyColumnOne() {
    this.onlyColumnOne = true
    this.onlyColumnTwo = false
  }

  showOnlyColumnTwo() {
    this.onlyColumnOne = false
    this.onlyColumnTwo = true
  }

  private load() {
    if (this.id && this.slug) {
      if (this.localeObserver.currentLocale !== 'DE') {
        this.showOnlyColumnTwo()
      } else {
        this.showOnlyColumnOne()
      }

      this.documentService.document(this.id, this.localeObserver.currentLocale).subscribe(
        (doc) => {
          this.document = doc

          setTimeout(
            () => {
              const mainContent = this.documentBody.getElementsByClassName('main-content')
              if (mainContent) {
                const trs = mainContent[0].querySelectorAll('tr')
                if (trs && trs.length) {
                  const tds = trs[0].querySelectorAll('td')
                  if (tds) {

                    if (tds.length === 1) {
                      this.showOnlyColumnOne()
                    }
                  }
                }
              }
            }, 400
          )
        }
      )
    }
  }

}

<div class="payment-container">

  <img class="payment-logo selected"
       *ngIf="selectedPaymentMethod"
       [src]="selectedPaymentMethod.logoUrl(this.type)"/>

  <div *ngIf="selectedPaymentMethod && provider === 'Adyen' && order?.builderhash">
    <app-adyen [order]="order" [type]="type" (loadOrder)="loadOrder"></app-adyen>
  </div>

  <div *ngIf="selectedPaymentMethod && provider === 'PagBrasil' && order?.builderhash">
    <app-pag-brasil [order]="order"></app-pag-brasil>
  </div>

  <div *ngIf="selectedPaymentMethod && provider === 'WireTransfer' && order?.builderhash">
    <app-wire-transfer [order]="order"></app-wire-transfer>
  </div>

  <div *ngIf="selectedPaymentMethod && paymentMethods.length>0" class="or-condition">
    {{ 'or_condition' | translate }}
  </div>

  <div class="payment-options">
    <div class="payment-logos" *ngFor="let paymentMethod of paymentMethods">
      <img class="payment-logo"
           [title]="paymentMethod.name"
           *ngIf="!paymentMethod.brands"
           [src]="paymentMethod.logoUrl()" (click)="setOption(paymentMethod?.provider, paymentMethod?.type)"/>
      <img class="payment-logo"
           [title]="paymentMethod.name"
           *ngFor="let brand of paymentMethod.brands"
           [src]="paymentMethod.logoUrl(brand)" (click)="setOption(paymentMethod?.provider, brand)"/>
    </div>
  </div>
</div>

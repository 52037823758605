import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {CurrencyObserver} from "../../observers/currency.observer";

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnChanges {

  @Input() totalAmount: number | undefined;
  @Input() totalAmountDiscounted: number | undefined;
  @Input() position: string;

  constructor(
    public currencyObserver: CurrencyObserver
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

}

import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { Document } from '../models/document.model'
import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'

import documentQuery from 'src/app/graphql/queries/document.query.graphql'
import documentsQuery from 'src/app/graphql/queries/documents.query.graphql'

@Injectable()
export class DocumentService extends GraphqlService<Document> {

  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  documents(iso: string, unames: string[]) {
    return this.findAll(documentsQuery, { iso, unames: unames.join(',') })
  }

  document(id: number, iso: string) {
    return this.find(documentQuery, { id, iso })
  }

}

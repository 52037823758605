import { BaseModel, Column, Model } from 'scf-graphql-model'
import { Product } from "./product.model";
import { Voucher } from "./voucher.model";
import { Discount } from "./discount.model";
import { CurrencyPrice } from "./currency_price.model";

@Model('cartItems', ['cartItem', 'updateCartItem', 'removeCartItem'])
export class CartItem extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  cartId: number | undefined;
  @Column()
  product: Product | undefined = new Product();
  @Column()
  voucher: Voucher | undefined = new Voucher();
  @Column()
  amount: number | undefined;
  @Column()
  discount: Discount | undefined = new Discount();
  @Column()
  editable: boolean | undefined
  @Column()
  attachedCartItem: CartItem | undefined;
  @Column()
  discountAmount: number | undefined;
  @Column()
  currencyPrices: CurrencyPrice[] = [];

  currencyPrice(currency: string): number {
    if (this.currencyPrices) {
      const price = this.currencyPrices.find(
        (price) => price.currency === currency
      )?.netPrice
      if (price) {
        return price
      }
    }
    return 0
  }

  totalDiscount(currency: string): number {
    let price = this.defaultPrice(currency) - this.discountedCurrencyPrice(currency)
    return price
  }

  discountedCurrencyPrice(currency: string): number {
    let price = this.currencyPrice(currency)
    if (this.discount?.percentage) {
      price = price - (price / 100 * this.discount?.percentage)
    }
    return price
  }

  totalDiscountedCurrencyPrice(currency: string): number {
    let price = this.discountedCurrencyPrice(currency)
    if (this.amount) {
      price = price * this.amount
    }
    return price
  }

  defaultPrice(currency: string): number {
    return this.currencyPrice(currency)
  }

  totalDefaultPrice(currency: string): number {
    let price = this.defaultPrice(currency)
    if (this.amount) {
      price = price * this.amount
    }
    return price
  }

}

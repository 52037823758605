<div class="flex-vertical-container">

  <a [routerLink]="['/']" class="navbar-brand" href="#">
    <img class="logo" src="https://www.morebooks.shop/assets/img/logo.png"/>
  </a>

  <div class="flex-horizontal-container">

    <div class="icon" *ngIf="status">
      <mat-icon *ngIf="status === 'success'">done_all</mat-icon>
      <mat-icon *ngIf="status === 'pending'">hourglass_empty</mat-icon>
      <mat-icon *ngIf="status === 'refused'">cached</mat-icon>
      <mat-icon *ngIf="status === 'error'">bug_report</mat-icon>
    </div>

    <div class="text" *ngIf="status === 'success'">
      <h1>{{ 'order.payment_finished_text' | translate }}</h1>
      <h2>{{ 'order.payment_finished' | translate }}</h2>
      <button mat-raised-button color="primary" class="purchase-button"
              (click)="routeToRoot()">
        {{ 'back' | translate }}
      </button>
    </div>

    <div class="text" *ngIf="status === 'pending'">
      <h1>{{ 'order.payment_finished_text' | translate }}</h1>
      <h2>{{ 'payment.waiting_for_payment' | translate }}</h2>
      <button mat-raised-button color="primary" class="purchase-button"
              (click)="routeToRoot()">
        {{ 'back' | translate }}
      </button>
    </div>

    <div class="text" *ngIf="status === 'refused' || status === 'error'">
      <h1>{{ 'payment.payment_failed' | translate }}</h1>
      <button mat-raised-button color="primary" class="purchase-button"
              (click)="routeToPaymentPage()">
        {{ 'payment.payment_start' | translate }}
      </button>
    </div>
  </div>

</div>

<div>

  <div *ngIf="payload?.error">
    <strong class="error">{{ payload?.error }}</strong>
  </div>

  <a [href]="redirectUrl" class="button" *ngIf="!payload?.error">
    Continue to Boleto
  </a>

</div>

import { BaseModel, Column, Model } from 'scf-graphql-model'
import { Country } from './country.model'

@Model('userInformation')
export class UserInformation extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  checkedCompany: boolean | undefined;
  @Column()
  company: string | undefined;
  @Column()
  ustidnr: string | undefined;
  @Column()
  taxId: string | undefined;
  @Column()
  forename: string | undefined;
  @Column()
  surname: string | undefined;
  @Column()
  mobilePhoneNumber: string | undefined;
  @Column()
  country: Country | undefined;
}

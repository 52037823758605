import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { Voucher } from '../../../models/voucher.model'
import { CartObserver } from '../../../observers/cart.observer'
import { PromotionObserver } from '../../../observers/promotion.observer'
import { CartService } from '../../../services/cart.service'

@Component({
  selector: 'app-promotion', templateUrl: './promotion.component.html', styleUrls: ['./promotion.component.scss'],
})
export class PromotionComponent implements OnInit, OnChanges {

  public vouchers: Voucher[] = []
  public usedVouchers: Voucher[] = []

  constructor(
    private cartObserver: CartObserver,
    private promotionObserver: PromotionObserver,
    private cartService: CartService,
    private bottomSheetRef: MatBottomSheetRef<PromotionComponent>,
    // @Inject(MAT_BOTTOM_SHEET_DATA) public data: {  }
  ) {
    console.log('PromotionComponent')
    promotionObserver.promotionVoucherObserver$.subscribe((vouchers) => {
      console.log('set vouchers: ', vouchers)
      this.vouchers = vouchers
    })
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
  }

  dismiss() {
    this.bottomSheetRef.dismiss()
  }

  addToCart(voucher: Voucher) {
    this.cartService.addVoucherToCart(voucher).subscribe((cart) => {
      this.cartObserver.updateCart(cart)
      this.cartObserver.bounceCartItem()
      this.usedVouchers.push(voucher)
      this.promotionObserver.ignorePromotion(voucher.code || '')
      setTimeout(() => {
        this.dismiss()
      }, 3000)
    })
  }

}

import { GraphqlService } from 'scf-graphql-model'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { Currency } from '../models/currency.model'

import currenciesQuery from 'src/app/graphql/queries/currencies.query.graphql'

@Injectable()
export class CurrencyService extends GraphqlService<Currency> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  getAvailableCurrencies(): Observable<Currency[]> {
    return this.findAll(currenciesQuery)
  }

}

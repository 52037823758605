<app-search *ngIf="activeSearch"></app-search>

<div class="row" *ngIf="!activeSearch">

  <div *ngIf="renderStart()">
    <img alt="hero image" src="/shop-ui/assets/images/hero.png" class="hero-image" />
  </div>

  <div class="col-xxl-2 col-xl-3 col-xs-12 sticky-categories">
    <nav class="navbar navbar-expand-xl navbar-light bg-light sticky-categories-desktop">
      <div class="container-fluid">
        <div></div>
        <button class="navbar-toggler float-end" type="button"
                (click)="categoriesNavCollapsed = !categoriesNavCollapsed"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon" [hidden]="categoriesNavCollapsed"></span>
          <mat-icon [hidden]="!categoriesNavCollapsed">close</mat-icon>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" [class.show]="categoriesNavCollapsed">
          <app-categories (onCategoryChange)="categoriesNavCollapsed=false"></app-categories>
        </div>
      </div>
    </nav>
  </div>
  <div class="col-xxl-10 col-xl-9 col-xs-12">

    <div *ngIf="renderCategory() || renderStart() || renderSearch()">
      <h2 class="container display3 display4-lg">
        <span *ngIf="!category">{{ 'start.our_sortiment' | translate }}</span>
        <span *ngIf="category">{{ category.getName() }}</span>
      </h2>

      <app-products #productComponent [products]="products"
                    (onPageChanged)="handlePageChangeEvent($event)"
                    (onProductSelected)="handleProductSelected($event)">

      </app-products>
    </div>

    <div class="text-justify" *ngIf="renderProduct()">
      <mat-icon *ngIf="!product" class="error">article</mat-icon>
      <app-product [product]="product" *ngIf="product"></app-product>
    </div>

    <div class="text-justify" *ngIf="renderBookLaunchOffer()">
      <mat-icon *ngIf="!bookLaunchOffer" class="error">announcement</mat-icon>
      <app-book-launch-offer-v2 [bookLaunchOffer]="bookLaunchOffer" *ngIf="bookLaunchOffer && bookLaunchOffer.version !== 'v3'"></app-book-launch-offer-v2>
      <app-book-launch-offer-v3 [bookLaunchOffer]="bookLaunchOffer" *ngIf="bookLaunchOffer && bookLaunchOffer.version === 'v3'"></app-book-launch-offer-v3>
    </div>

    <div class="text-justify" *ngIf="renderTranslationBundle()">
      <mat-icon *ngIf="!translationBundle" class="error">announcement</mat-icon>
      <app-translation-bundle [voucher]="translationBundle" *ngIf="translationBundle"></app-translation-bundle>
    </div>

  </div>
</div>

<div class="flex-container-layout">
  <div class="flex-item">
    <div *ngIf="bookLaunchOffer" class="flex-container">

      <div class="flex-container-headline">

        <h1>{{ 'book_price_offer.save_big' | translate }}</h1>

        <div class="countdown-container">
          <h2>{{ 'book_price_offer.limited_time_offer' | translate }}</h2>
          <app-countdown [timeInSeconds]="bookLaunchOffer.countdown" (expired)="onCountdownDone($event)"
                         [timerIcon]="'hourglass_top'" [timerIconExpired]="'hourglass_bottom'" [oneWeekGoodwill]="true"
                         class="countdown"></app-countdown>
        </div>

      </div>

      <app-product [product]="bookLaunchOffer?.product" [hideButton]="true" [hideBlurb]="true" [hideAuthor]="true"
                   [hidePublisher]="true" [hidePrice]="true"></app-product>

      <app-book-launch-offer-v3-default-item [bookLaunchOffer]="bookLaunchOffer" [tier]="bookLaunchOffer.defaultTier"
                                             [disabled]="countdownExpired">

      </app-book-launch-offer-v3-default-item>

      <mat-card class="our-tiers">

        <h2>{{ 'book_price_offer.other_available_options' | translate }}</h2>

        <div class="tiers-container">

          <ng-template #bookLaunchOfferDetails>
            <app-book-launch-offer-v3-item-details [bookLaunchOffer]="bookLaunchOffer"
                                                   [disabled]=" countdownExpired"
                                                   [tier]="activeTier"></app-book-launch-offer-v3-item-details>
          </ng-template>

          <div class="tiers">

            <ng-container *ngFor="let tier of bookLaunchOffer.tiersWithoutDefault">

              <app-book-launch-offer-v3-item [tier]="tier"
                                             (selectTier)="selectTier($event)"></app-book-launch-offer-v3-item>

              <div class="tier-details tier-details-mobile" [class.show]="!hideTier && tier===activeTier">
                <ng-container *ngTemplateOutlet="bookLaunchOfferDetails"></ng-container>
              </div>

            </ng-container>

          </div>

          <div class="tier-details tier-details-desktop" [class.show]="!hideTier">
            <ng-container *ngTemplateOutlet="bookLaunchOfferDetails"></ng-container>
          </div>

        </div>
      </mat-card>

    </div>

  </div>
</div>

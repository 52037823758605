import {Component, OnInit} from '@angular/core';
import {CartObserver} from "../../../observers/cart.observer";
import {CartService} from "../../../services/cart.service";
import {Cart} from "../../../models/cart.model";
import {CartItem} from "../../../models/cart_item.model";
import {CurrencyObserver} from "../../../observers/currency.observer";
import {Router} from "@angular/router";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  constructor(
    public cartObserver: CartObserver,
    public currencyObserver: CurrencyObserver,
    private router: Router
  ) {
    /* DO NOT DELETE */
    new Cart()
    new CartItem()
    /* DO NOT DELETE END */
  }

  ngOnInit(): void {
    this.cartObserver.cartVisibilityObserver$.subscribe(
      (visible) => {
        if (visible) {
          this.loadCart()
        }
      }
    )
  }

  loadCart() {
    this.cartObserver.fetchCart()
  }

  buyNow() {
    this.router.navigateByUrl(`/shop/checkout`)
    this.cartObserver.hideCart();
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('currencyPrices', ['currencyBookPrices', 'currencyAuthorSavings', 'currencyDiscountedAuthorPrices'])
export class CurrencyPrice extends BaseModel {
  @Column()
  currency: string | undefined;
  @Column()
  netPrice: number | undefined;
  @Column()
  exchangeRate: number | undefined;
}

<h1>{{ 'translation_bundle.headline' | translate }}</h1>

<h2
  [innerHTML]="'translation_bundle.congrats' | translate:{book_title: voucher?.product?.productBook?.title, language_count: cartItems?.length, voucher_discount: voucher?.discount?.percentage }"></h2>

<div class="flex-container-layout">

  <div class="flex-container content">
    <div class="flex-item countdown">
      <app-countdown [timeInSeconds]="voucher?.validUntil" (expired)="onCountdownExpired($event)" *ngIf="voucher?.validUntil"></app-countdown>
    </div>

    <div class="flex-item flex-container-items">
      <app-translation-bundle-item *ngFor="let item of cartItems" [item]="item" [disabled]="countdownExpired"
                                   (updateAmount)="calculateOffer($event)"></app-translation-bundle-item>
    </div>

  </div>


  <div class="button-container">
    <app-price [totalAmount]="totalAmount" [totalAmountDiscounted]="totalAmountDiscounted"></app-price>

    <button mat-raised-button color="primary" (click)="addToCart()" [disabled]="countdownExpired">
      <mat-icon>add_shopping_cart</mat-icon>&nbsp;&nbsp;{{ 'cart.add' | translate:{title: 'TEST'} }}
    </button>
  </div>

</div>

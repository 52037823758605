<div class="flex-container">
  <div class="flex-item">
    <mat-icon class="clock-icon" *ngIf="!isExpired">{{ timerIcon }}</mat-icon>
    <mat-icon class="clock-icon" *ngIf="isExpired">{{ timerIconExpired }}</mat-icon>
  </div>
  <div class="flex-item">
    <span class="counter">{{ days }}</span>
    <span class="description">{{ 'countdown.days' | translate }}</span>
  </div>
  <div class="flex-item">
    <span class="counter">{{ hours }}</span>
    <span class="description">{{ 'countdown.hours' | translate }}</span>
  </div>
  <div class="flex-item">
    <span class="counter">{{ minutes }}</span>
    <span class="description">{{ 'countdown.minutes' | translate }}</span>
  </div>
  <div class="flex-item">
    <span class="counter">{{ seconds }}</span>
    <span class="description">{{ 'countdown.seconds' | translate }}</span>
  </div>
</div>

<div>
  <div class="flex-container">
    <div class="flex-item" *ngFor="let voucher of vouchers">
      <img *ngIf="!usedVouchers.includes(voucher)"
           [src]="voucher.promotionImageUrl"
           [alt]="'Promotion: '+voucher.code"
           [title]="'Apply the code '+voucher.code+' now'"
           (click)="addToCart(voucher)"/>

      <img *ngIf="usedVouchers.includes(voucher)"
           [src]="voucher.promotionImageUrlUsed"
           [alt]="'Congratulation, you have been successfully added the voucher: '+voucher.code"
           [title]="'Click to close'"
           (click)="dismiss()"/>
    </div>
  </div>
</div>

import { EventEmitter, Injectable } from "@angular/core";
import { Locale } from "../models/locale.model";
import { User } from "../models/user.model";
import { LocaleService } from "../services/locale.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { SessionStorage } from "../storage/session.storage";

@Injectable()
export class UserObserver {

  user: User;
  userChangedObserver$: BehaviorSubject<User|undefined> = new BehaviorSubject<User|undefined>(undefined);

  constructor(
    private sessionStorage: SessionStorage
  ) {
  }

  setUser(user: User | undefined) {
    if (user) {
      this.sessionStorage.setUser(user)
      this.userChangedObserver$.next(user)
    }
  }

  resetUser() {
    this.sessionStorage.resetUser()
    this.userChangedObserver$.next(undefined)
  }

}

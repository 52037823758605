import {CategoryToken} from "./category_token.model";
import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('categories', ['category', 'children', 'ancestors'])
export class Category extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  name: string | undefined;
  @Column()
  hashCode: string | undefined;
  @Column()
  countrycode: string | undefined;
  @Column()
  productCount: string | undefined;
  @Column()
  parent: Category | undefined;
  @Column()
  categoryTokens: CategoryToken[] = [];
  @Column()
  children: Category[] = [];
  @Column()
  ancestors: Category[] = [];

  public getName(): string {
    if (this.categoryTokens && this.categoryTokens[0]) {
      return this.categoryTokens[0].value || ''
    } else {
      return ''
    }
  }

}

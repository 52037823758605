import { BaseModel, Column, Model } from 'scf-graphql-model'

@Model('locales')
export class Locale extends BaseModel {
  @Column()
  iso: string | undefined;
  @Column()
  iso3: string | undefined;
  @Column()
  default: boolean | undefined;
  @Column()
  languageName: string | undefined;

  getFlag(){
    return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${this.iso}.svg`
  }

}

import { BaseModel, Column, Model } from 'scf-graphql-model'
import {Discount} from "../discount.model";
import {Product} from "../product.model";

@Model('translationBundle')
export class TranslationBundle extends BaseModel {
  @Column()
  id: number | undefined;
  @Column()
  code: string | undefined;
  @Column()
  discount: Discount | undefined;
  @Column()
  validUntil: number | undefined;
  @Column()
  translations: Product[] | undefined;
  @Column({column: 'original', model: 'product'})
  product: Product | undefined;
}

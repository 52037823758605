import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { GraphqlService } from 'scf-graphql-model'
import { environment } from '../../environments/environment'
import { Voucher } from '../models/voucher.model'

import voucherQuery from 'src/app/graphql/queries/voucher.query.graphql'
import promotionOffersQuery from 'src/app/graphql/queries/promotion_offers.query.graphql'

@Injectable()
export class VoucherService extends GraphqlService<Voucher> {
  constructor(http: HttpClient) {
    super(http)
    this.baseUrl = environment.graphQL
  }

  byCode(code: string) {
    return this.find(voucherQuery, { code })
  }

  promotionalOffers(): Observable<Voucher[]> {
    return this.findAll(promotionOffersQuery)
  }

}

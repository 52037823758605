import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Event as NavigationEvent, NavigationEnd, Router } from '@angular/router'
import { Address } from '../../models/address.model'
import { Currency } from '../../models/currency.model'
import { Order } from '../../models/order.model'
import { Pagination } from '../../models/pagination.model'
import { User } from '../../models/user.model'
import { UserObserver } from '../../observers/user.observer'
import { LoginService } from '../../services/login.service'

@Component({
  selector: 'app-user-area',
  templateUrl: './user_area.component.html',
  styleUrls: ['./user_area.component.scss']
})
export class UserAreaComponent implements OnInit, OnChanges, OnDestroy {

  public currentUser: User | undefined

  RENDER_MY_ACCOUNT: string = 'my_account'
  RENDER_MY_BOOKS: string = 'my_books'
  RENDER_MY_ORDERS: string = 'my_orders'

  public render: string

  constructor(
    private userObserver: UserObserver,
    private loginService: LoginService,
    private router: Router
  ) {

    /* DO NOT DELETE */
    new Currency()
    new Address()
    new Order()
    new Pagination()
    /* DO NOT DELETE END */

    this.setRenderByUrl(this.router.url)

    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationEnd) {
            this.setRenderByUrl(event.urlAfterRedirects)
          }
        });

    this.userObserver.userChangedObserver$.subscribe(
      (user) => {

        this.currentUser = user

        if (!user) {
          this.currentUser = undefined
          this.router.navigate(['/shop']);
        }
      }
    )
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
  }

  setRenderByUrl(url: string) {
    if (url.match(/my_orders/)) {
      this.render = this.RENDER_MY_ORDERS
    } else if (url.match(/my_account/)) {
      this.render = this.RENDER_MY_ACCOUNT
    } else if (url.match(/my_books/)) {
      this.render = this.RENDER_MY_BOOKS
    }
  }

}

import AdyenCheckout from "@adyen/adyen-web";
import { Location } from "@angular/common";
import { Component, ElementRef, OnChanges, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { timeout } from "rxjs/operators";
import { Address } from "../../../models/address.model";
import { Currency } from "../../../models/currency.model";
import { Order } from "../../../models/order.model";
import { PaymentMethod } from "../../../models/payment_method.model";
import { PaymentPayload } from "../../../models/payment_payload.model";
import { CurrencyObserver } from "../../../observers/currency.observer";
import { LayoutObserver } from "../../../observers/layout.observer";
import { LocaleObserver } from "../../../observers/locale.observer";
import { OrderService } from "../../../services/order.service";
import { PaymentMethodService } from "../../../services/payment_method.service";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  provider: string
  type: string

  paymentMethods: PaymentMethod[] = []
  paymentMethodSubscription: Subscription

  selectedPaymentMethod: PaymentMethod | undefined

  order: Order

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private paymentMethodService: PaymentMethodService,
    private layoutObserver: LayoutObserver,
    private localeObserver: LocaleObserver,
    private currencyObserver: CurrencyObserver,
    private location: Location
  ) {
    /* DO NOT DELETE */
    new Currency()
    new PaymentPayload()
    new Order()
    new Address()
    /* DO NOT DELETE END */

    route.params.subscribe(
      (param) => {
        this.loadOrder()
      }
    )
  }

  ngOnInit() {
    this.provider = this.route.snapshot.paramMap.get('provider') || '';
    this.type = this.route.snapshot.paramMap.get('type') || '';
  }

  loadOrder() {
    const builderhash = this.route.snapshot.paramMap.get('builderhash')
    if (builderhash) {
      this.orderService.fetchOrder(builderhash).subscribe(
        (order) => {
          this.order = order

          if (this.order.hasOrderedStatus() || this.order.hasPaymentTermsStatus()) {
            if (this.order.totalprice && this.order.currency?.currency) {
              this.paymentMethodSubscription = this.paymentMethodService.getPaymentMethods(
                this.order.totalprice,
                this.order.currency?.currency
              ).subscribe(
                (paymentMethods) => {
                  this.paymentMethods = paymentMethods
                  this.setOption(this.provider, this.type)
                }
              )
            }
          } else {
            this.router.navigateByUrl('/')
          }
        }
      )
    }
  }

  setOption(provider: string | undefined, type: string | undefined) {
    const previousProvider = this.provider
    const previousType = this.type
    if (type && provider) {
      try {
        this.provider = provider
        this.type = type
        this.selectPaymentOption()
        this.location.replaceState(`/shop/order/${ this.order.builderhash }/${ this.provider }/${ this.type }`);
      } catch (ex) {
        console.log('------------------- ERROR ---------------------')
        console.log(ex)
        if (previousType && previousType !== type) {
          this.setOption(previousProvider, previousType)
        } else if (this.paymentMethods.length > 0) {
          if (this.paymentMethods[0].brands.length > 0) {
            this.setOption(this.paymentMethods[0].provider, this.paymentMethods[0].brands[0])
          } else {
            this.setOption(this.paymentMethods[0].provider, this.paymentMethods[0].type)
          }
        }
        console.log('------------------- ERROR ---------------------')
      }
    }
  }

  selectPaymentOption() {
    this.selectedPaymentMethod = this.paymentMethods.find((pm) => pm.provider === this.provider && (pm.type === this.type || (pm.brands && pm.brands.indexOf(this.type) >= 0)))
  }

}

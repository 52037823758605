<mat-card (click)="chooseTier($event)" [class.selected]="tier?.selected">
  <mat-card-content *ngIf="tier">

    <div class="checkbox">
      <mat-checkbox (click)="chooseTier($event)" [(ngModel)]="tier.selected"></mat-checkbox>
    </div>

    <div class="tier-info">
      <span class="quantity">{{ tier?.quantity }} </span>
      <span class="copies">{{ 'book_price_offer.copies' | translate }}</span>
    </div>

    <div class="tier-info">
      <span
          class="badge">{{ 'book_price_offer.save_pct' | translate:{ pct: (tier?.percentage | number:'1.0-2')?.toString() + '%' } }}</span>
    </div>

    <span class="your-price">
      {{ tier?.currencyDiscountedAuthorPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency }}
    </span>

  </mat-card-content>
</mat-card>

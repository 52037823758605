import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Category } from '../../../models/category.model';
import { ExtendedSearch } from '../../../models/extended_search.model';
import { Language } from '../../../models/language.model';
import { Publisher } from '../../../models/publisher.model';
import { SearchObserver } from '../../../observers/search.observer';
import { CategoryService } from '../../../services/category.service';
import { LanguageService } from '../../../services/language.service';
import { ProductService } from '../../../services/product.service';
import { PublisherService } from '../../../services/publisher.service';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-extended-search',
  templateUrl: './extended_search.component.html',
  styleUrls: ['./extended_search.component.scss']
})
export class ExtendedSearchComponent implements OnInit, OnChanges {

  categories: Category[] = []
  publishers: Publisher[] = []
  languages: Language[] = []

  extendedSearchForm: FormGroup

  constructor(
    private categoryService: CategoryService,
    private publisherService: PublisherService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private productService: ProductService,
    private searchObserver: SearchObserver,
    private formBuilder: FormBuilder,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { extendedSearch: ExtendedSearch }
  ) {
    const config = {
      isbn: data.extendedSearch?.isbn || '',
      title: data.extendedSearch?.title || '',
      subtitle: data.extendedSearch?.subtitle || '',
      blurb: data.extendedSearch?.blurb || '',
      author: data.extendedSearch?.author || '',
      categoryIds: '',
      publisherIds: '',
      languageCodes: '',
    }

    this.extendedSearchForm = this.formBuilder.group(config)

    if (data.extendedSearch?.categoryIds && data.extendedSearch?.categoryIds.length > 0) {
      this.extendedSearchForm.patchValue(
        {
          categoryIds: data.extendedSearch?.categoryIds
        }
      )
    }

    if (data.extendedSearch?.publisherIds && data.extendedSearch?.publisherIds.length > 0) {
      this.extendedSearchForm.patchValue(
        {
          publisherIds: data.extendedSearch?.publisherIds
        }
      )
    }

    if (data.extendedSearch?.languageCodes && data.extendedSearch?.languageCodes.length > 0) {
      this.extendedSearchForm.patchValue(
        {
          languageCodes: data.extendedSearch?.languageCodes
        }
      )
    }
  }

  ngOnInit() {
    this.categoryService.fetchCategories(0, this.translateService.currentLang).subscribe(
      (response) => this.categories = response
    )

    this.publisherService.fetchPublishers().subscribe(
      (response) => this.publishers = response
    )

    this.languageService.getAvailableLanguages().subscribe(
      (response) => this.languages = response
    )
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  clearField(fieldName: string) {
    let value: any = {}
    value[fieldName] = ''

    this.extendedSearchForm.patchValue(value)
  }

  dismiss() {
    this.resetSearch()
    this.searchObserver.triggerExtendedSearch(undefined)
  }

  resetSearch() {
    this.extendedSearchForm = this.formBuilder.group({
      isbn: '',
      title: '',
      subtitle: '',
      blurb: '',
      author: '',
      categoryIds: '',
      publisherIds: '',
      languageCodes: '',
    })
  }

  triggerExtendedSearch() {
    const extendedSearch = new ExtendedSearch()
    extendedSearch.isbn = this.extendedSearchForm.get('isbn')?.value
    extendedSearch.title = this.extendedSearchForm.get('title')?.value
    extendedSearch.subtitle = this.extendedSearchForm.get('subtitle')?.value
    extendedSearch.blurb = this.extendedSearchForm.get('blurb')?.value
    extendedSearch.author = this.extendedSearchForm.get('author')?.value
    extendedSearch.categoryIds = this.extendedSearchForm.get('categoryIds')?.value || []
    extendedSearch.publisherIds = this.extendedSearchForm.get('publisherIds')?.value || []
    extendedSearch.languageCodes = this.extendedSearchForm.get('languageCodes')?.value || []

    this.searchObserver.triggerExtendedSearch(extendedSearch)
  }

}

<mat-card>
  <mat-card-title class="text-truncate">{{ item?.product?.productBook?.title }}</mat-card-title>
  <mat-card-subtitle>{{ item?.product?.productBook?.isbn }}</mat-card-subtitle>
  <mat-card-content>

    <mat-tab-group headerPosition="below">
      <mat-tab [label]="'info' | translate">
        <app-book-cover [imgSrc]="item?.product?.imageURL('cover')"></app-book-cover>
        <app-language-flag [code]="item?.product?.productBook?.language"></app-language-flag>
        <app-price [totalAmount]="item?.totalDefaultPrice(currencyObserver.currentCurrency)"
                   [totalAmountDiscounted]="item?.totalDiscountedCurrencyPrice(currencyObserver.currentCurrency)"
                   [position]="'top-right'"></app-price>
      </mat-tab>
      <mat-tab [label]="'details' | translate">
        <app-product [product]="item?.product" [hideImage]="true" [hideButton]="true" [hideTitle]="true"
                     [hideSubtitle]="true" [hideISBN]="true"></app-product>
      </mat-tab>
    </mat-tab-group>

  </mat-card-content>
  <mat-card-actions class="text-pull-right">
    <div class="amount-setter">
      <button mat-button (click)="this.update(-1)" [disabled]="disabled">
        <mat-icon>remove</mat-icon>
      </button>
      <input #amountElement matInput
             [placeholder]="'0-1000'" type="number" [formControl]="amount" [disabled]="disabled"
             (click)="selectText()" (focus)="selectText()"
             (keyup)="updateIt($event)" min="0" required/>
      <button mat-button (click)="this.update(1)" [disabled]="disabled">
        <mat-icon>add</mat-icon>
      </button>
    </div>

  </mat-card-actions>
</mat-card>

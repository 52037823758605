import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Language} from "../../../models/language.model";
import {BookLanguageObserver} from "../../../observers/book_language.observer";
import {BsDropdownDirective} from "ngx-bootstrap/dropdown";

@Component({
  selector: 'app-products-filter',
  templateUrl: './products-filter.component.html',
  styleUrls: ['./products-filter.component.scss']
})
export class ProductsFilterComponent implements OnInit {

  @ViewChild('dropdown') dropdown: BsDropdownDirective
  @Input('aggregations') aggregations: Language[] | undefined = []

  constructor(
    public translate: TranslateService,
    public bookLanguageObserver: BookLanguageObserver
  ) {
  }

  ngOnInit(): void {
    this.bookLanguageObserver.bookLanguageDropdownObserver$.subscribe(
      (toggle) => {
        setTimeout(
          () => {
            if (this.dropdown) {
              this.dropdown.toggle(toggle)
            }
          }, 800
        )
      }
    )
  }

  get Aggregations(): Language[] {
    if (this.aggregations) {
      return this.aggregations
    }
    return []
  }

  getFlag(iso: string | undefined) {
    return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${iso?.toUpperCase()}.svg`
  }

}

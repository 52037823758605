import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Product} from "../../../models/product.model";
import {Pagination} from "../../../models/pagination.model";
import {CurrencyObserver} from "../../../observers/currency.observer";
import {Language} from "../../../models/language.model";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() products: Product[] = [];
  @Input() hideFilter: boolean = false;
  @Input() hideActions: boolean = false;

  @Output() onProductSelected: EventEmitter<Product> = new EventEmitter<Product>()
  @Output() onPageChanged: EventEmitter<number> = new EventEmitter<number>()

  pagination: Pagination<Product> | undefined = new Pagination();
  aggregations: Language[] | undefined = [];
  page: number | undefined

  constructor(
    public currencyObserver: CurrencyObserver,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  selectProduct(product: Product) {
    this.onProductSelected.emit(product)
  }

  set CurrentPage(page: number) {
    this.page = page
    this.onPageChanged.emit(this.page)
  }

  get CurrentPage(): number {

    if (this.pagination && this.pagination.currentPage) {
      if (this.page) {
        this.pagination.currentPage = +this.page
      }
      return this.pagination.currentPage
    }
    return 0
  }


}

import { Injectable } from '@angular/core'
import { Session } from '../models/session.model'
import { JSEncrypt } from 'jsencrypt'
import { User } from '../models/user.model'

@Injectable()
export class SessionStorage {

  getDarkModeState(): boolean {
    return localStorage.getItem('darkMode') === 't'
  }

  getSessionID(): string | null {
    return localStorage.getItem('sessionID')
  }

  getSessionData(): string | null {
    return localStorage.getItem('sessionData')
  }

  getUnencryptedSessionData(): string | null {
    return localStorage.getItem('unencryptedData')
  }

  getPersistenceToken(): string | null {
    const unencryptedData = this.getUnencryptedSessionData()
    if (unencryptedData) {
      const data = JSON.parse(unencryptedData)
      return data['persistence_token']
    } else {
      return null
    }
  }

  getBool(key: string): boolean {
    return localStorage.getItem(key) === 'yes'
  }

  setDarkModeState(darkMode: boolean): void {
    localStorage.setItem('darkMode', darkMode ? 't' : 'f')
  }

  setSession(session: Session) {
    if (typeof session.token === 'string') {
      localStorage.setItem('sessionID', session.token)
      localStorage.setItem('sessionData', session.data)
      localStorage.setItem('unencryptedData', session.unencryptedData)
      localStorage.setItem('publicKey', session.publicKey)
    }
  }

  resetSession() {
    localStorage.removeItem('sessionID')
    localStorage.removeItem('sessionData')
    localStorage.removeItem('publicKey')
    localStorage.removeItem('unencryptedData')
    localStorage.removeItem('decrypted')
  }

  getGuestType() {
    return localStorage.getItem('guest.type') || 'customer'
  }

  setGuestType(type: string) {
    localStorage.setItem('guest.type', type)
  }

  setUser(user: User) {
    localStorage.setItem('user.name', user.name || '')
    localStorage.setItem('user.email', user.email || '')
    localStorage.setItem('user.username', user.username || '')
    localStorage.setItem('user.impersonated', user.impersonated ? 'yes' : 'no')
    localStorage.setItem('user.author', user.isAuthor ? 'yes' : 'no')
  }

  getUser(): User | undefined {
    if (localStorage.getItem('user.name') !== null && this.getPersistenceToken() !== null) {
      const user = new User()
      user.name = localStorage.getItem('user.name') || ''
      user.username = localStorage.getItem('user.username') || ''
      user.email = localStorage.getItem('user.email') || ''
      user.persistenceToken = this.getPersistenceToken() || ''
      user.impersonated = this.getBool('user.impersonated')
      user.isAuthor = this.getBool('user.author')
      user.prepayment = this.getBool('user.prepayment')
      return user
    } else {
      return undefined
    }
  }

  resetUser() {
    localStorage.removeItem('user.name')
    localStorage.removeItem('user.email')
    localStorage.removeItem('user.username')
    localStorage.removeItem('user.impersonated')
    localStorage.removeItem('user.author')
  }

  ignorePromotion(code: string) {
    this.initializePromotionCodes()

    const codes = JSON.parse(localStorage.getItem('promotion.codes') || '[]')
    codes.push(code)

    localStorage.setItem('promotion.codes', JSON.stringify(codes))
  }

  getIgnoredPromotionCodes(): string[] {
    this.initializePromotionCodes()

    return JSON.parse(localStorage.getItem('promotion.codes') || '[]')
  }

  private initializePromotionCodes() {
    if (!localStorage.getItem('promotion.codes')) {
      localStorage.setItem('promotion.codes', '[]')
    }
  }

}

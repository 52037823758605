<ng-template #bestValue>
  <div class="best-tier-details">

    <div class="great-value-badge">
      {{ 'book_price_offer.great_value' | translate }}
    </div>

    <app-book-launch-offer-v3-item-details [bookLaunchOffer]="bookLaunchOffer" [tier]="tier"
                                        [renderCartItem]="false"
                                        [renderNotIncludedTierInfo]="false"></app-book-launch-offer-v3-item-details>

  </div>
</ng-template>

<mat-card [class.selected]="tier?.selected" class="most-popular-tier">
  <mat-card-title class="text-truncate">
    <h1>{{ 'book_price_offer.our_most_popular_offer' | translate }}</h1>
  </mat-card-title>
  <mat-card-content *ngIf="tier">

    <div class="basic-info">

      <div class="tier-info">
        <span class="quantity">{{ tier?.quantity }} </span>
        <span class="copies">{{ 'book_price_offer.copies' | translate }}</span>
      </div>

      <div class="tier-info-small" *ngIf="tier.quantity">
        <span
            *ngIf="bookLaunchOffer?.product?.totalCurrencyPrice(currencyObserver.currentCurrency, tier.quantity || 1) !== tier?.currencyDiscountedAuthorPrice(currencyObserver.currentCurrency)">

          <span [innerHTML]="
            'book_price_offer.pay_only' | translate: {
              old_price: bookLaunchOffer?.product?.totalCurrencyPrice(currencyObserver.currentCurrency, tier.quantity || 1) | currency:currencyObserver.currentCurrency,
              new_price: tier?.currencyDiscountedAuthorPrice(currencyObserver.currentCurrency) | currency:currencyObserver.currentCurrency
            }"></span>

        </span>
      </div>

      <div class="mobile-only">
        <ng-container *ngTemplateOutlet="bestValue"></ng-container>
      </div>

      <button mat-raised-button class="add-to-cart-btn" color="primary" (click)="addToCart()" *ngIf="tier"
              [disabled]="disabled">
        <mat-icon>add_shopping_cart</mat-icon>&nbsp;&nbsp;{{ 'cart.add' | translate }}
      </button>

    </div>

    <div class="desktop-only">
      <ng-container *ngTemplateOutlet="bestValue"></ng-container>
    </div>

  </mat-card-content>
</mat-card>

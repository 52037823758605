import { BookLaunchOffer } from '../models/book_launch_offer.model'
import { BookPriceOffer } from '../models/vouchers/book_price_offer.model'

import { CurrencyPipe, DecimalPipe } from '@angular/common'

export class TierInfo {

  currencyPipe: CurrencyPipe
  i18n: string = ''
  currency: string = ''
  tier: BookPriceOffer | undefined
  bookLaunchOffer: BookLaunchOffer | undefined

  constructor(
    currencyPipe: CurrencyPipe,
    i18n: string,
    currency: string,
    tier: BookPriceOffer | undefined = undefined,
    bookLaunchOffer: BookLaunchOffer | undefined = undefined,
  ) {
    this.currencyPipe = currencyPipe
    this.i18n = i18n
    this.currency = currency
    this.tier = tier
    this.bookLaunchOffer = bookLaunchOffer
  }

  get freeCopies(): boolean {
    const freeCopies = this.tier?.fixedFreeCopies || 0
    return freeCopies > 0
  }

  get freeFlipBook(): boolean {
    const quantity = this.tier?.quantity || 0
    return quantity >= 10 && this.freeCopies
  }

  get freeSocialMediaPost(): boolean {
    const quantity = this.tier?.quantity || 0
    return quantity > 20 && this.freeCopies
  }

  get freeBiographyPage(): boolean {
    const quantity = this.tier?.quantity || 0
    return quantity > 50 && this.freeCopies
  }

  get freePromotionalVideo(): boolean {
    const quantity = this.tier?.quantity || 0
    return quantity > 100 && this.freeCopies
  }

  get discount(): number {
    return this.tier?.discount?.percentage || 0
  }

  get renderIncluded(): boolean {
    if (this.i18n === 'book_price_offer.tier_info.discount_info') {
      return true
    } else if (this.i18n === 'book_price_offer.tier_info.price_reduction_info') {
      return this.old_price_value > this.new_price_value
    } else if (this.i18n === 'book_price_offer.tier_info.free_flip_book') {
      return this.freeFlipBook
    } else if (this.i18n === 'book_price_offer.tier_info.free_additional_copies') {
      return this.quantity > 0
    } else if (this.i18n === 'book_price_offer.tier_info.free_additional_copies') {
      return this.freeCopies
    } else if (this.i18n === 'book_price_offer.tier_info.free_social_media_post') {
      return this.freeSocialMediaPost
    } else if (this.i18n === 'book_price_offer.tier_info.free_biography_page') {
      return this.freeBiographyPage
    } else if (this.i18n === 'book_price_offer.tier_info.free_promotional_video') {
      return this.freePromotionalVideo
    } else {
      return false
    }
  }

  get renderNotIncluded(): boolean {
    return !this.renderIncluded
  }

  get old_price_value(): number {
    return this.bookLaunchOffer?.product?.currencyPrice(this.currency) || 0
  }

  get new_price_value(): number{
    return this.tier?.currencyBookPrice(this.currency) || 0
  }

  // required for i18n
  get old_price(): string | null {
    return this.currencyPipe.transform(this.old_price_value, this.currency)
  }

  get new_price(): string | null {
    return this.currencyPipe.transform(this.new_price_value, this.currency)
  }

  // freeCopies
  get quantity(): number {
    const quantity = this.tier?.fixedFreeCopies || 0
    return quantity
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-book-cover',
  templateUrl: './book-cover.component.html',
  styleUrls: ['./book-cover.component.scss']
})
export class BookCoverComponent implements OnInit {

  @Input('imgSrc') imgSrc: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}

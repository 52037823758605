<div mat-dialog-title>
  {{ 'user_area.my_books' | translate }}
</div>
<div mat-dialog-content>
  <app-product [product]="data.product" [hideButton]="true"></app-product>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button mat-button color="primary" [mat-dialog-close]="'cart'">{{ 'cart.add' | translate }}</button>
</div>
